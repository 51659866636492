import { useContext } from 'react';
import DeliveryPassStatusContext from './DeliveryPassStatusContext';

const useDeliveryPassStatus = () => {
  const deliveryPassStatusContext = useContext(DeliveryPassStatusContext);

  if (deliveryPassStatusContext === undefined) {
    throw new Error('useDeliveryPassStatus must be used within a DeliveryPassStatusProvider');
  }

  return deliveryPassStatusContext;
};

export default useDeliveryPassStatus;
