import React, { memo, MutableRefObject } from 'react';

import locator from 'utils/locator';
import { getSearchValueFromSearchQuery } from 'utils/searchQuery';

import autoSuggestClear from 'redux/modules/auto-suggest/actions/auto-suggest-clear';
import autoSuggestSearch from 'redux/modules/auto-suggest/actions/auto-suggest-search';
import { updateSearchTerm } from 'redux/modules/search/actions/update-search-term';
import { useWtrSelector } from 'redux/hooks';

import { getAutoSuggestActive } from 'redux/modules/auto-suggest/selectors';
import { getFunctionalCookieState } from 'redux/modules/cookies/selectors';

import AutoComplete from 'components/Search/AutoComplete';
import SearchHistory from 'components/Search/SearchHistory';
import MultiSearchModal from 'components/MultiSearch/Modal';
import { SearchBox } from '@johnlewispartnership/wtr-ingredients/ingredients/SearchBox';

import { useSearchForm } from './use-search-form';

type Props = {
  homepage: boolean;
  id?: string;
  onSearch: (() => WtrAction) | null;
  placeholder: string;
};

const SearchForm = memo(
  ({ homepage = false, id = undefined, onSearch = null, placeholder = 'Search...' }: Props) => {
    const autoSuggestActive = useWtrSelector(getAutoSuggestActive);

    const functionalCookieConsent = useWtrSelector(getFunctionalCookieState);
    const initialValue =
      (!locator.search.includes('searchType') && getSearchValueFromSearchQuery(locator.search)) ||
      '';
    const {
      handleChange,
      handleClear,
      handleFocus,
      handleSelectedAutoCompleteItem,
      handleSelectedSearchHistoryItem,
      handleSubmit,
      hasFocus,
      inputRef,
      insideRef,
      showSearchHistory,
      value,
    } = useSearchForm({
      autoSuggestActive,
      eligibleForAutoSuggest: true,
      functionalCookieConsent,
      initialValue,
      onChange: autoSuggestSearch,
      onClear: autoSuggestClear,
      onSubmit: updateSearchTerm,
      onSearch,
    });

    const inputProps = {
      onFocus: handleFocus,
      ref: inputRef,
    };

    return (
      <SearchBox
        ariaActivedescendant={showSearchHistory ? 'search-history-item' : 'autocomplete-suggestion'}
        ariaAutocomplete="list"
        ariaOwns={showSearchHistory ? 'search-history' : 'autocomplete-listbox'}
        focus={hasFocus}
        formRef={insideRef}
        id={id}
        initialValue={initialValue}
        inputProps={inputProps}
        onChange={handleChange}
        onClearClick={handleClear}
        onSubmit={handleSubmit}
        placeholder={placeholder}
        value={value}
      >
        {hasFocus && !showSearchHistory ? (
          <AutoComplete
            homepage={homepage}
            inputRef={inputRef as MutableRefObject<HTMLInputElement>}
            onClear={autoSuggestClear}
            onSelectItem={handleSelectedAutoCompleteItem}
          />
        ) : (
          <></>
        )}

        {showSearchHistory ? (
          <SearchHistory
            inputRef={inputRef as MutableRefObject<HTMLInputElement>}
            homepage={homepage}
            onSelectTerm={handleSelectedSearchHistoryItem}
          />
        ) : (
          <></>
        )}
        <MultiSearchModal hidden={homepage || value.length > 0} />
      </SearchBox>
    );
  },
);

SearchForm.displayName = 'SearchForm';

export default SearchForm;
