import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { History } from 'history';

import env from 'env/env';
import { dataLayer } from 'analytics/data-layer';
import actionsToTrack from 'redux/middleware/analytics/actions-to-track';
import analyticsMiddleware from 'redux/middleware/analytics';
import apiPromise from 'redux/middleware/api-promise';
import customRouterMiddleware from 'redux/middleware/router';
import ignoreInitialLocationEvent from 'redux/middleware/router/ignore-initial-location-event';
import customDimensionsMiddleware from 'redux/middleware/custom-dimensions';
import impactMiddleware from 'redux/middleware/impact';
import { actionHandlers } from 'redux/middleware/impact/action-handlers';
import monetateMiddleware from 'redux/middleware/monetate';
import processResult from 'redux/middleware/process-result';
import trolleyQueue from 'redux/middleware/trolley-queue';
import dimensions from 'redux/middleware/custom-dimensions/dimensions';
import delayedActionMiddleware from 'redux/middleware/delayed-action';
import reducer from './reducer';

export type WtrRootState = ReturnType<ReturnType<typeof reducer>>;
export type WtrPreloadedState = Partial<WtrRootState>;

export const createStoreAndHistory = (
  clientHistory: History,
  preloadedState?: WtrPreloadedState,
) => {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: clientHistory,
    savePreviousLocations: 50,
  });

  const middlewares = [
    trolleyQueue(),
    monetateMiddleware,
    apiPromise,
    processResult,
    customRouterMiddleware,
    analyticsMiddleware(dataLayer, actionsToTrack),
    ignoreInitialLocationEvent,
    customDimensionsMiddleware(dataLayer, dimensions),
    delayedActionMiddleware,
    impactMiddleware(actionHandlers),
    routerMiddleware,
  ].filter(Boolean);

  const store = configureStore({
    reducer: reducer(routerReducer),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        // TODO: allow enabling this via environment variable + a package.json
        // script command. When enabled it has a major impact of performance of
        // the site. Currently disabled to ensure local development and
        // functional tests are unimpacted.
        immutableCheck: false,
        serializableCheck: {
          // These actions contain unserialisable data which Redux throws against
          // We can ignore these as we don't actually put any of the unserialisable data into the store
          // Perhaps worth investigating in future if we can improve and remove these
          warnAfter: 96,
          ignoredActionPaths: ['apiCall', 'callback.failure', 'callback.success', 'queueFunction'],
        },
      }).concat(...middlewares),
    preloadedState,
    devTools: !!env.development,
  });

  const history = createReduxHistory(store);

  if (env.development && module.hot) {
    module.hot.accept('./reducer', () => store.replaceReducer(reducer(routerReducer)));
  }

  return { store, history };
};

export type WtrStore = ReturnType<typeof createStoreAndHistory>['store'];
export type WtrGetState = WtrStore['getState'];
export type WtrInferredDispatch = WtrStore['dispatch'];

declare global {
  // TODO: Do we want this as a global? (interface as a type throws circular reference...)
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface WtrState extends WtrPreloadedState {}

  type WtrGetState = () => WtrState;
}
