import {
  Category,
  getMenuCategoryById,
} from 'redux/modules/taxonomy/selectors/get-menu-category-by-id';
import {
  getMegaMenuActiveLevel,
  getMegaMenuMaxLength,
  getMegaMenuMenus,
  getMegaMenuRoot,
} from 'redux/modules/page/selectors/mega-menu';

import { setMegaMenu } from 'redux/modules/page/actions/set-mega-menu';
import { navigateFromMegaMenu } from 'redux/modules/page/actions/navigate-from-mega-menu';

import React, {
  useCallback,
  MouseEvent,
  SetStateAction,
  Dispatch,
  MutableRefObject,
  useMemo,
} from 'react';
import { useClientOnlyLayoutEffect } from 'hooks/use-client-only-layout-effect';
import classNames from 'classnames';
import { pushMenuClickWithLevels } from 'utils/gtm';
import { useNavigate } from 'react-router-dom';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';

import { FetcherArgs } from 'route-data/fetcher-types';
import SubCategory from 'components/MegaMenu/MenuLink/SubCategory';
import MenuLink from 'components/MegaMenu/MenuLink';
import NavigationProvider from 'components/MegaMenu/NavigationProvider';
import { NavigationContextApiRef } from 'components/MegaMenu/NavigationProvider/NavigationProvider';
import cmsExperienceFragment from 'redux/fetchers/experience-fragment';
import { yieldToMain } from 'utils/yield-to-main';

import buildOffersQueryParam from './build-offers-query-param-from-id';
import { buildOffersPathFromMenus } from './build-offers-path-from-menus';
import styles from './MenuLinksList.scss';
import { IURLSearch } from '../utils/urlParser';

type MenuLinksListProps = {
  handleClickToClose: (event: MouseEvent) => void;
  keyboardLevel: number | null;
  level: number;
  navigationRef: MutableRefObject<NavigationContextApiRef>;
  parentId: string;
  setKeyboardLevel: Dispatch<SetStateAction<number | null>>;
};

const MenuLinksList = ({
  keyboardLevel,
  handleClickToClose,
  level,
  navigationRef,
  parentId,
  setKeyboardLevel,
}: MenuLinksListProps) => {
  const dispatch = useWtrDispatch();
  const navigate = useNavigate();

  const activeLevel = useWtrSelector(getMegaMenuActiveLevel);
  const menus = useWtrSelector(getMegaMenuMenus);
  const menu = menus[level];

  const category: Category = useWtrSelector(state => getMenuCategoryById(state, menu.id));

  const menuMaxLength = useWtrSelector(getMegaMenuMaxLength);
  const menuRoot = useWtrSelector(getMegaMenuRoot);

  const { path } = menu;
  const title = category.name || null;

  const activeChildId = useMemo(
    () => (menus[level + 1] ? menus[level + 1].id : null),
    [menus, level],
  );

  const relationship = useMemo(() => {
    const isAncestor = activeLevel > level;
    const isDescendant = activeLevel < level;

    if (isAncestor || isDescendant) {
      return isAncestor ? 'ancestor' : 'descendant';
    }
    return 'self';
  }, [activeLevel, level]);

  const offersPath: IURLSearch = {
    pathname: buildOffersPathFromMenus(menus),
    search: buildOffersQueryParam(menu.id),
  };

  const getItemInfo = useCallback(
    (id?: string) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { hasDescendants, name, banner } = category.categories?.[id!] ?? {};
      const isParent = hasDescendants && level + 1 < menuMaxLength;

      return { isParent, name, banner };
    },
    [category, level, menuMaxLength],
  );

  const handleKeyboardLeft = useCallback(() => {
    if (level) {
      const previousLevel = level - 1;
      // close and fall back to previous level
      dispatch(setMegaMenu(previousLevel, parentId));

      // ensure keyboard focus side-effects occur
      setKeyboardLevel(previousLevel);
    }
  }, [level, dispatch, parentId, setKeyboardLevel]);

  const handleKeyboardRight = useCallback(
    (id?: string) => {
      const { isParent, name, banner } = getItemInfo(id);
      const nextLevel = level + 1;

      if (!isParent) return;

      // prevent further navigation during transitions to avoid circular effects
      navigationRef.current.unfocus();

      // open next level
      // @ts-expect-error `setMegaMenu` is not typed
      dispatch(setMegaMenu(nextLevel, id, name));

      if (banner) {
        dispatch(cmsExperienceFragment(banner)({} as FetcherArgs));
      }

      // ensure keyboard focus side-effects occur
      setKeyboardLevel(nextLevel);
    },
    [getItemInfo, level, navigationRef, setKeyboardLevel, dispatch],
  );

  const handleClickMenuItem = useCallback(
    async (event: MouseEvent, id: string | null, url: string, viewAllName?: string) => {
      // @ts-expect-error `getItemInfo` is not typed
      const { isParent, name, banner } = getItemInfo(id);

      // Prevent focus side-effects intended for keyboard navigation
      setKeyboardLevel(null);

      event.preventDefault();
      if (isParent) {
        // prevent link navigation and open next menu level
        // @ts-expect-error `setMegaMenu` is not typed
        dispatch(setMegaMenu(level + 1, id, name));
        if (banner) {
          dispatch(cmsExperienceFragment(banner)({} as FetcherArgs));
        }
      } else {
        const vAllName = name || viewAllName;
        // @ts-expect-error `navigateFromMegaMenu` is not typed
        dispatch(navigateFromMegaMenu(level + 1, vAllName, id));
        handleClickToClose(event);

        await yieldToMain();

        navigate(url);
        pushMenuClickWithLevels({ level, menus, name: (event.target as HTMLAnchorElement).title });
      }
    },
    [getItemInfo, setKeyboardLevel, level, dispatch, handleClickToClose, navigate, menus],
  );

  useClientOnlyLayoutEffect(() => {
    // refocus the currently active level, for left/right keyboard navigation
    if (keyboardLevel === level) {
      navigationRef.current.refocus(0);
    }
  }, [keyboardLevel, level, navigationRef]);

  if (!title) return null;

  const componentClass = classNames(styles[relationship], styles[`level${level}`], {
    [styles.active]: relationship === 'ancestor' || level > 0,
  });
  const menuLinks = category?.categoryIds ?? [];
  const offerLevels = 2;
  const offerStartLevel = menuRoot === 'Shop' ? 1 : 0;
  const showOffers = level >= offerStartLevel && level < offerStartLevel + offerLevels;
  const offersLink = (
    <li className={styles.offers} role="menuitem">
      <MenuLink
        label={`${title} <strong>OFFERS</strong>`}
        shortName="offers"
        title={`${title} Offers`}
        url={offersPath}
        onClick={(event: MouseEvent, id: string | null, url: string) =>
          handleClickMenuItem(event, id, url, `${title} Offers`)
        }
      />
    </li>
  );

  return (
    <NavigationProvider
      onEnterKey={handleKeyboardRight}
      onLeftKey={handleKeyboardLeft}
      onRightKey={handleKeyboardRight}
      ref={navigationRef}
    >
      <div
        aria-expanded={relationship !== 'descendant'}
        aria-hidden={relationship === 'descendant'}
        className={componentClass}
        data-testid="menu-links-list"
      >
        <ul
          data-test={`mega-menu-${level}-list`}
          className={styles.menuLinks}
          aria-label={`Use up, down, left and right arrows to browse ${title}.`}
          role="menu"
        >
          {/* View all ... link */}
          <li className={styles.all} role="menuitem">
            <MenuLink
              title={`View all ${title}`}
              shortName="all"
              url={path}
              onClick={(event: MouseEvent, id: string | null, url: string) =>
                handleClickMenuItem(event, id, url, `View all ${title}`)
              }
            />
          </li>
          {showOffers && offersLink}
          {/* Taxonomy menu items */}
          {menuLinks.map((id: string) => (
            <li key={`${id}`} role="menuitem">
              <SubCategory
                categoryId={id}
                isActive={activeChildId === id}
                level={level}
                onClick={handleClickMenuItem}
                path={path}
              />
            </li>
          ))}
        </ul>
      </div>
    </NavigationProvider>
  );
};

MenuLinksList.displayName = 'MenuLinksList';

export default MenuLinksList;
