import { FAVOURITES_MORE_FROM_THIS_BRAND, EOS_GLP_NAV } from 'constants/monetateExperiments';
import { createSelector } from 'reselect';

export const getExperiments = ({ experiments }) => experiments;
export const getDecisionId = (state, id) => id;

export const getHasDecisions = createSelector(
  getExperiments,
  ({ hasDecisions = false }) => hasDecisions,
);

// WARNING!!! Do not use this selector directly. Use the useExperiments() custom hook instead
// If you must use this selector, make sure you also take care of tracking with trackExperimentView()
export const getAllDecisions = createSelector([getExperiments], ({ decisions }) => decisions || {});
export const getAllPayloads = createSelector([getExperiments], ({ payloads }) => payloads || null);

export const getAllImpressions = createSelector(
  [getExperiments],
  ({ impressions }) => impressions || null,
);

export const getViewedExperiments = createSelector(
  [getExperiments],
  ({ viewedOnPage }) => viewedOnPage || [],
);

export const getExperimentValueById = createSelector(
  [getAllDecisions, (state, experimentId) => experimentId],
  (experiments, experimentId) => experiments[experimentId],
);

export const isExperimentLive = (state, id) => {
  const experiments = getAllDecisions(state) || {};
  return Object.keys(experiments).includes(id);
};

export const getPodSlidedownAdvertPayloadByProductId = createSelector(
  [getAllPayloads, (_state, productId) => productId],
  (payloads, productId) => payloads?.podSlidedownAdvert?.[productId],
);

export const getMoreFromThisBrandPayloadByLineNumber = createSelector(
  [getAllPayloads, (_state, lineNumber) => lineNumber],
  (payloads, lineNumber) => payloads?.[FAVOURITES_MORE_FROM_THIS_BRAND.experiment]?.[lineNumber],
);

export const getIsMonetateBypassed = createSelector(
  [getExperiments],
  ({ bypassMonetate }) => bypassMonetate,
);

export const getMonetatePreview = createSelector([getExperiments], ({ preview }) => preview);

export const getMonetateIpAddress = createSelector([getExperiments], ({ ipAddress }) => ipAddress);

export const getGLPNav = createSelector([getAllPayloads, (state, id) => id], payloads => {
  const navPayload = payloads?.[EOS_GLP_NAV.experiment] || {};

  const menus = Object.keys(navPayload).map(key => navPayload[key]);

  return menus;
});
