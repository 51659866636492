export const AUTHENTICATED_ACCOUNT = 'AUTHENTICATED_ACCOUNT';
export const CHRISTMAS = 'CHRISTMAS';
export const CORONATION = 'CORONATION';
export const CUSTOMER_SERVICE = 'CUSTOMER_SERVICE';
export const DELIVERY_PASS_ACTIVE = 'DELIVERY_PASS_ACTIVE';
export const DELIVERY_PASS_AVAILABLE = 'DELIVERY_PASS_AVAILABLE';
export const EASTER = 'EASTER';
export const ENTERTAINING = '10052';
export const ENTERTAINING_NEW = '1';
export const FAVOURITES = 'FAVOURITES';
export const FIND_STORE = 'FIND_STORE';
export const GROCERIES = '10051';
export const GROCERIES_OFFERS = '10051_OFFERS';
export const HOME = 'HOME';
export const INSPIRATION = 'INSPIRATION';
export const LISTS = 'LISTS';
export const LOYALTY = 'LOYALTY';
export const LOYALTY_VOUCHERS = 'LOYALTY_VOUCHERS';
export const MY_ORDERS = 'MY_ORDERS';
export const MY_DETAILS = 'MY_DETAILS';
export const MEGA_MENU = 'MEGA_MENU';
export const MOTHERSDAY = 'MOTHERSDAY';
export const NEW = 'NEW';
export const OFFERS = 'OFFERS';
export const RECIPES = 'RECIPES';
export const REGISTER = 'REGISTER';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SITE_LINKS = 'SITE_LINKS';
export const SLOT_BUTTON = 'SLOT_BUTTON';
export const SEASONAL = 'SEASONAL';
export const UNAUTHENTICATED_ACCOUNT = 'UNAUTHENTICATED_ACCOUNT';
export const VALENTINES = 'VALENTINES';
export const OTHERS = '-1';

export default {
  ENTERTAINING,
  ENTERTAINING_LINK: '1',
  FAVOURITES_LINK: '5',
  GROCERIES,
  INSPIRATION_LINK: '4',
  LISTS_LINK: '6',
  MEGA_MENU,
  MY_ACCOUNT_LINK: '7',
  OFFERS_LINK: '2',
  RECIPES_LINK: '3',
  SHOP: '0',
};
