import { usingStubs } from 'stubs';
import { FORMAT, dayjs, isBefore, isValid } from 'utils/date';
import { getFeatureFlags } from 'utils/feature-flags';
import locator from 'utils/locator';

export const getFromDateFromUrl = () => {
  const fromDate = new URLSearchParams(locator.search).get('fromDate') ?? undefined;
  const isValidFromDate = isValid(dayjs(fromDate, FORMAT.ISO8601_DATE, true));
  return isValidFromDate ? fromDate : undefined;
};

export const fromDateUrlParam = () => {
  const urlFromDate = getFromDateFromUrl();
  if (urlFromDate) {
    return `?fromDate=${urlFromDate}`;
  }
  return '';
};

const getCustomCampaignDate = () => {
  if (!getFeatureFlags().slots_useCustomCampaign) {
    return undefined;
  }

  // The explicit type allows us to index any field without TS errors or having to check if the prop exists
  const hardCodedDates: Record<string, string> = {
    easter: '2025-04-15',
  };
  const givenCustomCampaign: string = locator.pathname.split('/').at(-1);

  return hardCodedDates[givenCustomCampaign];
};

export const getStartDateFromUrl = () => {
  const customCampaign = getCustomCampaignDate();
  const startDate =
    customCampaign ?? new URLSearchParams(locator.search).get('startDate') ?? undefined;
  const isValidStartDate = isValid(dayjs(startDate, FORMAT.ISO8601_DATE, true));

  // If date is invalid, or earlier than tomorrow (min date for grid), then ignore the startDate
  // This check is ignored when using stubs
  if (!usingStubs()) {
    const tomorrow = dayjs.tz().add(1, 'day').startOf('day');
    if (!isValidStartDate || isBefore(startDate, tomorrow)) {
      return undefined;
    }
  }

  return startDate;
};
