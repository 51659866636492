import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LazyIcon } from 'components/LazyIcon/LazyIcon';

import styles from './SocialLinks.scss';

const getSocialIconSelector = type => {
  switch (type) {
    case 'facebook':
      return 'Facebook';
    case 'instagram':
      return 'Instagram';
    case 'pinterest':
      return 'Pinterest';
    case 'x':
      return 'X';
    case 'youtube':
      return 'Youtube';
    default:
      return null;
  }
};

const SocialLinks = ({ className, data }) => {
  const { links, title } = data;

  return (
    <div className={classNames(className, styles.socialLinks)}>
      <h3 className={styles.socialSectionTitle}>{title}</h3>
      {links.map((link, index) => {
        const { href, popup, title: linkTitle, type } = link;
        const dataAttributes = {
          'data-actiontype': 'redirect',
          'data-origincomponent': 'footer link',
          'data-shortdescription': `footer link: ${title}: ${linkTitle}`,
        };
        const linkProps = {
          ...dataAttributes,
          'aria-label': `Follow us on ${linkTitle}`,
          className: styles.socialLink,
          href,
          key: `footer-${linkTitle.toLowerCase()}-soc-${index}`,
          rel: popup ? 'noopener noreferrer' : null,
          target: popup ? '_blank' : '_self',
          title: linkTitle,
        };

        const name = getSocialIconSelector(type);

        return (
          <a {...linkProps}>
            {name && <LazyIcon name={name} size="small" />}
            <span className={styles.socialLinkText}>{linkTitle}</span>
          </a>
        );
      })}
    </div>
  );
};

SocialLinks.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        popup: PropTypes.bool,
        title: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }).isRequired,
};

SocialLinks.defaultProps = {
  className: '',
};

SocialLinks.displayName = 'SocialLinks';

export default SocialLinks;
