import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet, Route, Routes } from 'react-router-dom';
import { config } from 'content/components/config';
import { ContentComponentLibraryProvider } from '@johnlewispartnership/wtr-content-component-library';
import classnames from 'classnames';
import Redirect from 'components/Redirect';
import AppBanner from 'components/AppBanner';
import CookieAlert from 'components/CookieAlert';
import DataProvider from 'components/DataProvider';
import SiteFooter from 'components/Footer/SiteFooter';
import SiteFooterMinimal from 'components/Footer/SiteFooterMinimal';
// This imports should be lazy loaded but we are having problems with the CLS score because of the SSR
import HomePage from 'components/HomePage';
import CommonModal from 'components/Modal/CommonModal';
import OptimisticTrolleyHandler from 'components/OptimisticTrolleyHandler';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';
import SiteHeader from 'components/SiteHeader';
import SiteHeaderMinimal from 'components/SiteHeader/SiteHeaderMinimal';
import CommonSiteBanner from 'components/CommonSiteBanner'; // must be in main bundle for cwv, do not lazy/loadable!
import { loadable } from 'components/Skeleton/LoadableSkeleton'; // This HAS to be called `loadable` to work with SSR
import WebVitalsReporter from 'components/WebVitalsReporter';
import ExperimentsReporter from 'components/Experiment/ExperimentsReporter';
import TrolleyAnalyticsReporter from 'components/Trolley/TrolleyAnalyticsReporter';
import ErrorBoundary from 'components/ErrorBoundary';
import RefreshMessaging from 'components/RefreshMessaging';
import { Head } from 'components/App/Head';
import { WebviewApp } from 'components/App/WebviewApp';
import {
  pagesWithCheckoutScaffolding,
  pagesWithMinimalScaffolding,
  pagesWithNoHeader,
  pagesWithoutAmendBar,
} from 'constants/pageScaffolding';
import urls, { rawCmsUrls, rawUrls } from 'constants/urls';
import { log } from 'utils/error-logging';
import { getFeatureFlags } from 'utils/feature-flags';
import { rootPath } from 'utils/root-path';
import { getAppType } from 'utils/webview';
import { CheckoutWrapper } from 'components/Checkout/CheckoutWrapper';
import { MealPlanner as MealPlannerComponent } from 'components/Recipes/MealPlanner';
import { withLegacyBlockingApis } from 'components/withLegacyBlockingApis';
import {
  LoadableFavouritesPage,
  LoadableListsPage,
  LoadableMyDetailsPage,
  LoadableMyOrdersPage,
  LoadableMyWaitroseHubHomepage,
  LoadableServiceSelection,
  LoadableTrolleyPage,
} from 'components/App/loadableComponents';

import 'components/Checkout/CheckoutHeader/styles.scss'; // must be in the main bundle.css to fix the checkout header SSR
import styles from './App.scss';

const MealPlanner = withLegacyBlockingApis(MealPlannerComponent);

/*
  Loadable Components

  Switch loadable to import
  find:    const (.*) = loadable\(\(\) => import\((.*)\)\);
  replace: import $1 from $2;

  Switch import to loadable
  find:    import (.*) from (.*);
  replace: const $1 = loadable(() => import($2));

  Place loadable components here
  Please ensure all routes have:
  1. Skeleton loader (there is one by default which you can override for more control)
  2. A custom error boundary around the component if required, otherwise it will fall back to the App-wide error boundary"
*/

const noFallback = { fallback: null };

const UrgencyBanner = loadable(
  () =>
    import(
      /* webpackChunkName: "UrgencyBanner" */
      'components/UrgencyBanner'
    ),
  noFallback,
);
const SitePinBar = loadable(
  () =>
    import(
      /* webpackChunkName: "SitePinBar" */
      'components/SitePinBar'
    ),
  noFallback,
);
const InstantCheckoutModal = loadable(
  () =>
    import(
      /* webpackChunkName: "InstantCheckoutModal" */
      'components/GoToCheckout/InstantCheckoutModal'
    ),
  noFallback,
);
const CommonSnackbar = loadable(
  () =>
    import(
      /* webpackChunkName: "CommonSnackbar" */
      'components/CommonSnackbar'
    ),
  noFallback,
);

const AdminAddressReduxForm = loadable(() => import('components/Address/Admin'));
const Browse = withLegacyBlockingApis(loadable(() => import('components/Browse')));
const CmsPage = loadable(() => import('components/CmsPage'));
const MarketingPreferences = withLegacyBlockingApis(
  loadable(() => import('components/MyAccount/MarketingPreferences')),
);

const ReturnToAdmin = loadable(() => import('components/ReturnToAdmin'), noFallback);
const ReviewLogin = loadable(() => import('components/ProductDetails/Reviews/ReviewLogin'));

const SkipLink = loadable(() => import('components/SkipLink'), noFallback);

const Featured = withLegacyBlockingApis(loadable(() => import('components/Featured')));
const ForgotPassword = withLegacyBlockingApis(loadable(() => import('components/ForgotPassword')));
const ForYouPage = loadable(() => import('components/ForYouPage'));
const Interstitials = withLegacyBlockingApis(loadable(() => import('components/Interstitials')));
const MyAccount = withLegacyBlockingApis(loadable(() => import('components/MyAccount')));

const MyPaymentCards = withLegacyBlockingApis(loadable(() => import('components/MyPaymentCards')));
const Login = withLegacyBlockingApis(loadable(() => import('components/Login')));
const LoginInitiator = withLegacyBlockingApis(loadable(() => import('components/LoginInitiator')));
const Logout = withLegacyBlockingApis(loadable(() => import('components/Logout')));
const LogoutError = withLegacyBlockingApis(
  loadable(() => import('components/LogoutError/LogoutError')),
);

const MultiSearchResults = withLegacyBlockingApis(
  loadable(() => import('components/MultiSearch/Page')),
);
const NotFound = withLegacyBlockingApis(loadable(() => import('components/NotFound/NotFound')));
const OfferDetails = withLegacyBlockingApis(
  loadable(() => import('components/Offers/OfferDetails')),
);
const OffersPage = withLegacyBlockingApis(loadable(() => import('components/Offers/OffersPage')));
const OffersTypePage = withLegacyBlockingApis(
  loadable(() => import('components/Offers/OffersTypePage')),
);
const OrderConfirmation = withLegacyBlockingApis(
  loadable(() => import('components/OrderConfirmation')),
);

const PaymentConfirmationPage = withLegacyBlockingApis(
  loadable(() => import('components/PaymentConfirmation/PaymentConfirmationPage')),
);

const RecipesPage = withLegacyBlockingApis(loadable(() => import('components/Recipes/Page')));
const RecipesLanding = withLegacyBlockingApis(loadable(() => import('components/RecipesLanding')));
const Registration = withLegacyBlockingApis(loadable(() => import('components/Registration')));
const ResetPassword = withLegacyBlockingApis(loadable(() => import('components/ResetPassword')));
const SearchResults = withLegacyBlockingApis(
  loadable(() => import('components/Search/SearchResults')),
);

const ShopFromPrevious = loadable(() => import('components/ShopFromPrevious/Page'));
const ShopFromPreviousInterstitial = loadable(
  () => import('components/ShopFromPreviousInterstitial'),
);
const ShoppingListPage = loadable(() => import('components/ShoppingList/Page'));

const ViewOrder = loadable(() => import('components/ViewOrder'));

const MyDetails = withLegacyBlockingApis(LoadableMyDetailsPage);
const MyPartnershipCardAndDetails = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyPartnershipCardAndDetails')),
);
const BecomeAMember = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/BecomeAMember')),
);
const JoinMyWaitrose = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/JoinMyWaitrose')),
);
const WelcomeToMyWaitrose = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/WelcomeToMyWaitrose')),
);
const LinkMyWaitroseCard = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/LinkMyWaitroseCard')),
);
const LinkMyWaitroseCardSuccess = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/LinkMyWaitroseCardSuccess')),
);
const MyWaitroseHubHomepage = withLegacyBlockingApis(LoadableMyWaitroseHubHomepage);
const MyWaitroseBenefits = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefits')),
);
const MyWaitroseBenefitsFreeHotDrink = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsFreeHotDrink')),
);
const MyWaitroseBenefitsCaffeNero = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsCaffeNero')),
);
const MyWaitroseBenefitsDryCleaning = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsDryCleaning')),
);
const MyWaitroseBenefitsVitality = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsVitality')),
);
const MyWaitroseBenefitsWaitroseMagazines = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseBenefitsWaitroseMagazines')),
);
const MyWaitroseCardAndDetails = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseCardAndDetails')),
);
const MyWaitroseCompetitions = withLegacyBlockingApis(
  loadable(() => import('components/MyWaitrose/MyWaitroseCompetitions')),
);
const OrderReplacementCard = withLegacyBlockingApis(
  loadable(() => import('components/OrderReplacementCard')),
);
const OrderReplacementCardAddress = withLegacyBlockingApis(
  loadable(() => import('components/OrderReplacementCardAddress')),
);
const OrderReplacementCardSuccess = withLegacyBlockingApis(
  loadable(() => import('components/OrderReplacementCardSuccess')),
);
const LeaveMyWaitrose = withLegacyBlockingApis(
  loadable(() => import('components/LeaveMyWaitrose')),
);

const AddAddressRouted = loadable(() => import('components/Address'), {
  resolveComponent: components => components.AddAddressRouted,
});

const SlotGrid = loadable(() => import('components/BookSlot/SlotGrid'));

const ServiceSelectionCollection = loadable(
  () => import('components/BookSlot/ServiceSelectionCollection'),
);

const Checkout = loadable(
  () =>
    import(
      /* webpackChunkName: "checkout" */
      'components/Checkout/CheckoutLoader'
    ),
  { fallback: <CheckoutWrapper /> },
);
const ResolveOrderPayment = loadable(() => import('components/ResolveOrderPayment'), {
  fallback: <CheckoutWrapper title="Payment" />,
});

const CampaignModals = loadable(() => import('components/BookSlot/CampaignModals'), noFallback);

const ProhibitedPage = loadable(() => import('components/BookSlot/ProhibitedPage'));

const ProductPage = withLegacyBlockingApis(loadable(() => import('components/ProductPage')));

const TraCheckBlock = withLegacyBlockingApis(loadable(() => import('components/TraCheckBlock')));

const MealDealPage = withLegacyBlockingApis(
  loadable(() => import('components/MealDealPage/StrategicMealDealPage')),
);
const BundleBuilderPage = withLegacyBlockingApis(
  loadable(() => import('components/BundleBuilderPage')),
);

const CitrusAdsBuylistPage = withLegacyBlockingApis(
  loadable(() => import('components/CitrusAds/CitrusAdsBuylistPage')),
);
const CitrusAdsPreview = loadable(() => import('components/CitrusAds/CitrusAdsPreview'));
// End loadable components

class App extends PureComponent {
  componentDidMount() {
    const { readMonetateCookie } = this.props;
    readMonetateCookie();
  }

  componentDidCatch(error, errorInfo) {
    log(error, 'app', 'general', errorInfo);
  }

  render() {
    const features = getFeatureFlags();
    const { hasSitePinBar, location, helmetContext } = this.props;
    const urlIncludesPage = page => (location.pathname + location.search).includes(page.url);
    const minimalPage = pagesWithMinimalScaffolding.find(urlIncludesPage);
    const pageWithoutAmendBar = pagesWithoutAmendBar.find(urlIncludesPage);
    const checkoutPage = pagesWithCheckoutScaffolding.find(urlIncludesPage);
    const noHeaderPage = pagesWithNoHeader.find(urlIncludesPage);
    const orderConfirmationPage = (location.pathname + location.search).includes(
      urls.orderConfirmation,
    );
    const classes = classnames({
      [styles.appWrapperMinimal]: minimalPage,
      hasSitePinBar,
    });
    const shouldAddLoyaltyCompetitionsRoute = features.loyalty_competitions;

    if (location.pathname.includes('/ecom/tra-check-block')) {
      return <TraCheckBlock />;
    }

    if (getAppType()) {
      return (
        <HelmetProvider context={helmetContext}>
          <ContentComponentLibraryProvider config={config}>
            <WebviewApp location={location} minimalPage={!!minimalPage} />
          </ContentComponentLibraryProvider>
        </HelmetProvider>
      );
    }

    const appScaffold = (
      <div className={classes}>
        <div>
          <SkipLink destination="#main">Skip to main content</SkipLink>
        </div>
        <ReturnToAdmin />
        {!noHeaderPage && (minimalPage ? <SiteHeaderMinimal /> : <SiteHeader />)}
        <CommonSiteBanner
          show={!!features.siteBanner && !checkoutPage && !minimalPage && !orderConfirmationPage}
        />
        <main
          className={minimalPage ? styles.appMainMinimal : styles.appMain}
          role="main"
          id="main"
        >
          <SimpleErrorBoundary
            logger="App"
            section="main-section-error-boundary"
            errorComponent={<RefreshMessaging />}
          >
            <Outlet />
          </SimpleErrorBoundary>
        </main>
        {!pageWithoutAmendBar && <SitePinBar />}
        {minimalPage ? <SiteFooterMinimal /> : <SiteFooter />}
      </div>
    );

    // Any CRITICAL Providers MUST be added here and to the Webview above!
    return (
      <HelmetProvider context={helmetContext}>
        <WebVitalsReporter />
        <ExperimentsReporter />
        <TrolleyAnalyticsReporter />
        <DataProvider />
        <CookieAlert />
        <OptimisticTrolleyHandler />
        <ContentComponentLibraryProvider config={config}>
          <Head />

          <AppBanner />

          <UrgencyBanner />

          <SimpleErrorBoundary
            logger="App"
            section="top-level-error-boundary"
            errorComponent={<RefreshMessaging />}
          >
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path={urls.reviewLogin} element={<ReviewLogin />} />
              <Route
                path={urls.checkout}
                element={
                  <ErrorBoundary logger="checkout-component" section="checkout">
                    <Checkout />
                  </ErrorBoundary>
                }
              />
              <Route path={urls.resolveOrderPayment} element={<ResolveOrderPayment />} />
              <Route path={rootPath('/customer/address')} element={<AdminAddressReduxForm />} />
              {
                // ! The following route originally had the path rootPath('/*/addresses/add')
              }
              <Route
                path={rootPath('/:addressPath/addresses/add')}
                element={<AddAddressRouted />}
              />
              <Route path={urls.citrusAdsPreview} element={<CitrusAdsPreview />} />
              <Route path="/ecom/*" element={appScaffold}>
                <Route path="" element={<Redirect to={urls.groceriesHome} replace />} />
                <Route path="shop" element={<Redirect to={urls.groceriesHome} replace />} />
                <Route path={rawUrls.favourites} element={<LoadableFavouritesPage />} />
                <Route path={rawUrls.forYou} element={<ForYouPage />} />
                <Route path={rawUrls.lists} element={<LoadableListsPage />} />
                <Route
                  path={`${rawUrls.orderConfirmation}/:customerOrderId`}
                  element={<OrderConfirmation />}
                />
                <Route
                  path={`${rawUrls.paymentConfirmationPage}/:customerOrderId`}
                  element={<PaymentConfirmationPage />}
                />
                <Route path={`${rawUrls.interstitials}/*`} element={<Interstitials />} />
                {
                  // Sub routes of the SearchResults are not being testes in the App.routes.spec.js file
                }
                <Route path={`${rawUrls.search}/*`} element={<SearchResults />} />
                <Route path={rawUrls.multiSearch} element={<MultiSearchResults />} />
                <Route
                  path="shop/browse/offers/highlights/:offerIdentifier/*"
                  element={<OffersTypePage />}
                />
                <Route path={`${rawUrls.offers}/*`} element={<OffersPage />} />
                <Route path={`${rawUrls.browse}/*`} element={<Browse />} />
                <Route path="shop/offers/:offerId" element={<OfferDetails />} />
                <Route path={`${rawUrls.bundles}/:bundleName`} element={<BundleBuilderPage />} />
                <Route
                  path={`${rawUrls.mealDealPromoPath}/:mealDealId`}
                  element={<MealDealPage />}
                />
                <Route path="shop/featured/*" element={<Featured />} />
                <Route path="products/:productName/:productId" element={<ProductPage />} />
                <Route path={rawUrls.trolleyPage} element={<LoadableTrolleyPage />} />{' '}
                <Route
                  path="shopping-lists/:shoppingListName?/:shoppingListId"
                  element={<ShoppingListPage />}
                />
                <Route
                  path={`${rawUrls.serviceSelection}/:campaign?`}
                  element={<LoadableServiceSelection />}
                />
                <Route
                  path={`${rawUrls.bookCollectionSlot}/:campaign?`}
                  element={<ServiceSelectionCollection />}
                />
                <Route
                  path={rawUrls.reselectCollectionService}
                  element={<ServiceSelectionCollection />}
                />
                <Route path={`${rawUrls.bookslot}/*`} element={<SlotGrid />} />
                <Route path={rawUrls.login} element={<LoginInitiator />} />
                <Route path={rawUrls.signIn} element={<Login />} />
                <Route path={`${rawUrls.resetPassword}/*`} element={<ResetPassword />} />
                <Route path={`${rawUrls.forgotPassword}/*`} element={<ForgotPassword />} />
                <Route path={rawUrls.marketingPreferences} element={<MarketingPreferences />} />
                <Route path={rawUrls.paymentCards} element={<MyPaymentCards />} />
                <Route path={rawUrls.myOrdersPage} element={<LoadableMyOrdersPage />} />
                <Route path={rawUrls.shopFromPrevious} element={<ShopFromPrevious />} />
                <Route
                  path={rawUrls.shopFromPreviousInterstitial}
                  element={<ShopFromPreviousInterstitial />}
                />
                <Route path={rawUrls.viewOrder} element={<ViewOrder />} />
                <Route path={rawUrls.myAccount} element={<MyAccount />} />
                <Route path={rawUrls.myDetailsPage} element={<MyDetails />} />
                <Route
                  path={rawUrls.myPartnershipCardAndDetails}
                  element={<MyPartnershipCardAndDetails />}
                />
                <Route path={rawUrls.becomeMyWaitroseMember} element={<BecomeAMember />} />
                <Route path={rawUrls.joinMyWaitrose} element={<JoinMyWaitrose />} />
                <Route path={rawUrls.joinMyWaitroseSuccess} element={<WelcomeToMyWaitrose />} />
                <Route path={rawUrls.linkMyWaitrose} element={<LinkMyWaitroseCard />} />
                <Route
                  path={rawUrls.linkMyWaitroseSuccess}
                  element={<LinkMyWaitroseCardSuccess />}
                />
                <Route path={rawUrls.myWaitrosePage} element={<MyWaitroseHubHomepage />} />
                <Route path={rawUrls.myWaitroseBenefits} element={<MyWaitroseBenefits />} />
                <Route
                  path={rawUrls.myWaitroseBenefitsDryCleaning}
                  element={<MyWaitroseBenefitsDryCleaning />}
                />
                <Route
                  path={rawUrls.myWaitroseBenefitsFreeHotDrink}
                  element={<MyWaitroseBenefitsFreeHotDrink />}
                />
                <Route
                  path={rawUrls.myWaitroseBenefitsCaffeNero}
                  element={<MyWaitroseBenefitsCaffeNero />}
                />
                <Route
                  path={rawUrls.myWaitroseBenefitsVitality}
                  element={<MyWaitroseBenefitsVitality />}
                />
                <Route
                  path={rawUrls.myWaitroseBenefitsWaitroseMagazines}
                  element={<MyWaitroseBenefitsWaitroseMagazines />}
                />
                <Route
                  path={rawUrls.myWaitroseCardAndDetails}
                  element={<MyWaitroseCardAndDetails />}
                />
                {shouldAddLoyaltyCompetitionsRoute && (
                  <Route
                    path={rawUrls.myWaitroseCompetitions}
                    element={<MyWaitroseCompetitions />}
                  />
                )}
                <Route path={rawUrls.orderReplacementCard} element={<OrderReplacementCard />} />
                <Route
                  path={rawUrls.orderReplacementCardAddress}
                  element={<OrderReplacementCardAddress />}
                />
                <Route
                  path={rawUrls.orderReplacementCardSuccess}
                  element={<OrderReplacementCardSuccess />}
                />
                <Route
                  path={`${rawUrls.citrusAdsBuylistPage}/:lineNumbers`}
                  element={<CitrusAdsBuylistPage />}
                />
                <Route path={`${rawUrls.leaveMyWaitrose}/*`} element={<LeaveMyWaitrose />} />
                <Route path={`${rawUrls.registration}/*`} element={<Registration />} />
                <Route path={rawUrls.reschedulingProhibited} element={<ProhibitedPage />} />
                <Route path={rawUrls.logout} element={<Logout />} />
                <Route path={rawUrls.logoutError} element={<LogoutError />} />
                <Route path={rawUrls.recipe} element={<RecipesPage />} />
                <Route path="recipes">
                  <Route path="all-categories" element={<RecipesLanding isAllCategoriesPage />} />
                  <Route path="meal-plans/" key="/ecom/recipes/meal-plans" element={<CmsPage />} />
                  <Route path="meal-plans/*" element={<MealPlanner />} />
                  <Route path="" element={<CmsPage />} />
                  <Route path="*" element={<RecipesLanding />} />
                </Route>
                {
                  // ! check these URLs and pages. They used to end in `//:path*`, but this doesn't work with react-router v6 so it was changed
                }
                {rawCmsUrls.map(url => (
                  <Route key={url} path={url} element={<CmsPage />} />
                ))}
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="*" element={appScaffold}>
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </SimpleErrorBoundary>
          <CommonModal />
          <InstantCheckoutModal />
          <CommonSnackbar />
          <CampaignModals />
        </ContentComponentLibraryProvider>
      </HelmetProvider>
    );
  }
}

export default App;

App.propTypes = {
  hasSitePinBar: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  helmetContext: PropTypes.shape({}),
  readMonetateCookie: PropTypes.func.isRequired,
};

App.defaultProps = {
  helmetContext: {},
};
