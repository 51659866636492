import { getOrderProductLineNumbersByCustomerOrderId } from 'redux/modules/entities/selectors/customer-orders';
import { handleTimeoutError } from 'redux/modules/order/actions/errors/handle-timeout-error';
import { fetchOrderProducts } from 'redux/modules/orders/actions/fetch-order-products';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { separateByChunks } from 'utils/array';

export const MAX = 100; // As dictated by the Unbxd max in the BE

export const fetchCustomerOrderProducts =
  ({
    customerOrderId,
    productsChunkStart = 0,
  }: {
    customerOrderId: string;
    productsChunkStart?: number;
  }): WtrThunkAction<Promise<unknown>> =>
  (dispatch, getState) => {
    const state = getState();
    const lineNumbers = getOrderProductLineNumbersByCustomerOrderId(state, customerOrderId);
    const currentCustomerOrderId = getCustomerOrderId(state);

    if (lineNumbers.length > 0) {
      const arrays = separateByChunks(lineNumbers, MAX).splice(productsChunkStart);

      return Promise.all(
        arrays.map(arr =>
          dispatch(
            fetchOrderProducts(arr, {
              listId: undefined,
              customerOrderId: currentCustomerOrderId,
              start: undefined,
              size: undefined,
            }),
          ),
        ),
      ).catch(error => {
        if (dispatch(handleTimeoutError(error))) {
          return;
        }

        throw error;
      });
    }

    return Promise.resolve();
  };
