import { DeliveryStatusResponse } from 'api/definitions/delivery-tracking';
import { deepFreeze } from 'utils/deepFreeze';

export interface DeliveryTrackingForOrder extends DeliveryStatusResponse {
  loading?: boolean;
  loaded?: boolean;
  error?: boolean;
}
export interface DeliveryTrackingState {
  deliveryStatuses: Record<string, DeliveryTrackingForOrder>;
}
const initialState: Readonly<DeliveryTrackingState> = deepFreeze({
  deliveryStatuses: {},
});

export default initialState;
