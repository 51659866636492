import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const FAVOURITES_SET_SORT_ORDER = 'waitrose/favourites/FAVOURITES_SET_SORT_ORDER';

export const FAVOURITES_CATEGORY_FILTER_IMPRESSION =
  'waitrose/favourites/FAVOURITES_CATEGORY_FILTER_IMPRESSION';
export const FAVOURITES_OFFER_FILTER_IMPRESSION =
  'waitrose/favourites/FAVOURITES_OFFER_FILTER_IMPRESSION';
export const FAVOURITES_SORT_IMPRESSION = 'waitrose/favourites/FAVOURITES_SORT_IMPRESSION';

export const GET_FAVOURITES = apiPromiseTypes('favourites-products', 'get');
export const GET_FAVOURITES_STARTER_BASKET = apiPromiseTypes(
  'favourites-starter-basket-products',
  'get',
);
