import { createSelector } from 'reselect';
import chunk from 'lodash/chunk';
import { formatAsPounds } from 'utils/currency';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { getBlendedOptimisticTrolleyItems } from 'redux/modules/trolley/selectors/get-optimistic-items';
import { getProducts } from 'redux/modules/entities/selectors/products';
import { LegacyProductReference, BlendedTrolleyProduct } from 'constants/products';
import { OffersExperienceGroup } from 'api/definitions/offers-experience';
import { TransformedLegacySearchProduct } from 'redux/transforms/types';

import {
  MealDealState,
  MealDealSuccessState,
  MealDealErrorState,
  MealDealErrorCode,
} from '../types';
import {
  MEAL_DEAL_NOT_FOUND_ERROR_CODE,
  MEAL_DEAL_UNKNOWN_ERROR_CODE,
  MEAL_DEAL_INVALID_ID_ERROR_CODE,
  MEAL_DEAL_ERROR_FETCHING_OFFER_ERROR_CODE,
  MEAL_DEAL_ERROR_FETCHING_PRODUCTS_ERROR_CODE,
  MEAL_DEAL_MISSING_CUSTOMER_ORDER_ID_ERROR_CODE,
} from '../constants';

type BlendedTrolleyProductWithPrice = BlendedTrolleyProduct & { price: { amount: number } };

type BuilderProduct = BlendedTrolleyProductWithPrice | null;

type MealDealBuilderGroup = Omit<OffersExperienceGroup, 'lineNumbers'> & {
  groupId: string;
  items: BuilderProduct[];
};

export type MealDealBuilder = {
  groups: MealDealBuilderGroup[];
  builderId: number;
  completed: boolean;
  savings: string;
};

const responseUnrecoverableErrorCodes: MealDealErrorCode[] = [
  MEAL_DEAL_INVALID_ID_ERROR_CODE,
  MEAL_DEAL_ERROR_FETCHING_OFFER_ERROR_CODE,
  MEAL_DEAL_ERROR_FETCHING_PRODUCTS_ERROR_CODE,
  MEAL_DEAL_MISSING_CUSTOMER_ORDER_ID_ERROR_CODE,
  MEAL_DEAL_UNKNOWN_ERROR_CODE,
];

const isMealDealSuccessfullyLoaded = (mealDeal: MealDealState): mealDeal is MealDealSuccessState =>
  'groups' in mealDeal;

const isMealDealError = (mealDeal: MealDealState): mealDeal is MealDealErrorState =>
  'errorCode' in mealDeal;

const getMealDeal = (state: WtrState): MealDealState => getStateAtNamespaceKey(state, 'mealDeals');

export const getMealDealLoading = createSelector(
  getMealDeal,
  mealDeal => !('loading' in mealDeal) || mealDeal.loading,
);

export const getMealDealErrorCode = createSelector(getMealDeal, (mealDeal: MealDealState) =>
  isMealDealError(mealDeal) ? mealDeal.errorCode : undefined,
);

export const getMealDealUnrecoverableError = createSelector(getMealDealErrorCode, errorCode =>
  errorCode ? responseUnrecoverableErrorCodes.includes(errorCode) : false,
);

export const getMealDealNotFound = createSelector(
  getMealDealErrorCode,
  errorCode => errorCode === MEAL_DEAL_NOT_FOUND_ERROR_CODE,
);

export const getMealDealId = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.id : undefined,
);

export const getMealDealTitle = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.name : undefined,
);

export const getMealDealStartDate = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.startDate : undefined,
);

export const getMealDealEndDate = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.endDate : undefined,
);

const EMPTY_RECOMMENDATIONS: LegacyProductReference[] = [];

export const getMealDealRecommendedProducts = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.recommendations : EMPTY_RECOMMENDATIONS,
);

const EMPTY_GROUPS: OffersExperienceGroup[] = [];

export const getMealDealGroups = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.groups : EMPTY_GROUPS,
);

const toLegacyProductReference = (lineNumber: string): LegacyProductReference => ({
  searchProduct: lineNumber,
});

export const getMealDealProductsGrouped = createSelector(
  getMealDealGroups,
  groups =>
    new Map(
      groups.map(({ id, lineNumbers }) => [
        id.toString(),
        lineNumbers.map(toLegacyProductReference),
      ]),
    ),
);

export const getMealDealGroupByGroupId = createSelector(
  getMealDealGroups,
  (_state: WtrState, groupId: string) => Number(groupId),
  (groups, groupId) => groups.find(({ id }) => groupId === id),
);

const getMealDealSavings = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal)
    ? `All for just ${formatAsPounds(mealDeal.discount.value.amount)}`
    : '',
);

const byPriceDescending = (
  itemA: BlendedTrolleyProductWithPrice,
  itemB: BlendedTrolleyProductWithPrice,
) => itemA.price.amount - itemB.price.amount;

const createNullArray = (size: number): null[] => new Array(size).fill(null);

const isMealDealGroupComplete = (mealDealBuilderGroup: MealDealBuilderGroup) =>
  mealDealBuilderGroup.items.every(Boolean);

const isMealDealComplete = (mealDealBuilderGroups: MealDealBuilderGroup[]) =>
  mealDealBuilderGroups.map(isMealDealGroupComplete).every(Boolean);

const EMPTY_MEAL_DEAL_BUILDERS: MealDealBuilder[] = [];

export const getMealDealBuilders = createSelector(
  [getMealDealGroups, getMealDealSavings, getProducts, getBlendedOptimisticTrolleyItems],
  (
    groups: OffersExperienceGroup[],
    savings: string,
    productEntities: Record<string, TransformedLegacySearchProduct>,
    blendedTrolleyItems: BlendedTrolleyProduct[],
  ) => {
    if (!groups.length) {
      return EMPTY_MEAL_DEAL_BUILDERS;
    }

    const mealDealGroupsWithChunkedItems = groups
      .filter(group => group.threshold > 0)
      .map(group => {
        const { id: groupId, lineNumbers, name, threshold } = group;
        const itemsArray: BlendedTrolleyProductWithPrice[] = [];

        lineNumbers.forEach(lineNumber => {
          const trolleyItemInGroup = blendedTrolleyItems.find(
            item => item.lineNumber === lineNumber,
          );

          if (!trolleyItemInGroup) {
            return;
          }

          const price =
            'price' in trolleyItemInGroup
              ? trolleyItemInGroup.price
              : productEntities[lineNumber].currentSaleUnitPrice.price;

          for (let i = 0; i < trolleyItemInGroup.quantity.amount; i += 1) {
            itemsArray.push({ ...trolleyItemInGroup, price });
          }
        });

        const itemsSortedByPrice = itemsArray.sort(byPriceDescending);
        const itemsChunkedByThreshold: BuilderProduct[][] = chunk(
          (itemsSortedByPrice as BuilderProduct[]).concat(
            createNullArray(threshold - (itemsSortedByPrice.length % threshold)),
          ),
          threshold,
        );

        return {
          id: groupId,
          itemsChunkedByThreshold,
          name,
          threshold,
          groupId: String(groupId),
        };
      });

    const builderCount = Math.min(
      ...mealDealGroupsWithChunkedItems.map(
        ({ itemsChunkedByThreshold }) => itemsChunkedByThreshold.length,
      ),
    );

    const mealDealBuilders: MealDealBuilder[] = [];

    for (let i = 0; i < builderCount; i += 1) {
      const mealDealBuilderGroups = mealDealGroupsWithChunkedItems.map(
        ({ itemsChunkedByThreshold, ...rest }) => ({
          items: itemsChunkedByThreshold[i],
          ...rest,
        }),
      );

      const builderMealDeal = {
        builderId: i,
        completed: isMealDealComplete(mealDealBuilderGroups),
        groups: mealDealBuilderGroups,
        savings,
      };

      mealDealBuilders.push(builderMealDeal);
    }

    return mealDealBuilders;
  },
);

export const getTotalCompletedMealDeals = createSelector(getMealDealBuilders, builderMealDeals =>
  Math.max(0, builderMealDeals.length - 1),
);

export const getMealDealGroupCompletionStatus = createSelector(
  getMealDealBuilders,
  builderMealDeals =>
    builderMealDeals
      .at(-1)
      ?.groups.map(group => {
        const complete = isMealDealGroupComplete(group);

        return `${group.name} ${complete ? 'Complete' : 'Incomplete'} (ID: ${group.groupId})`;
      })
      .join('; '),
);

export const getMealDealBuilderLineNumbers = createSelector(
  getMealDealBuilders,
  mealDealBuilders => [
    ...new Set(
      ([] as MealDealBuilderGroup[])
        .concat(...mealDealBuilders.map(mealDeal => mealDeal.groups))
        .flatMap(group => group.items.map(item => item?.lineNumber))
        .filter(lineNumber => lineNumber),
    ),
  ],
);
