import React from 'react';

export enum DeliveryPassStatusEnum {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  AVAILABLE = 'AVAILABLE',
  ACTIVE = 'ACTIVE',
}

const DeliveryPassStatusContext = React.createContext<DeliveryPassStatusEnum>(
  DeliveryPassStatusEnum.NOT_AVAILABLE,
);
export default DeliveryPassStatusContext;
