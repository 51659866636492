import React from 'react';
import { pure } from 'recompose';

import Warrant from 'components/Warrant';

const KingWarrant = pure(() => (
  <Warrant
    armsUrl="https://waitrose-prod.scene7.com/is/image/waitroseprod/king?$Waitrose-Image-Preset-95$"
    byAppointmentTo="His Majesty The King"
  />
));

KingWarrant.displayName = 'KingWarrant';

export default KingWarrant;
