import type { Response } from 'superagent';
import { getAllDecisions } from 'redux/modules/experiments/selectors';
import {
  FAVOURITES_STARTER_BASKET,
  matchDecisionByExperiment,
} from 'constants/monetateExperiments';
import { withPersonalisationHeader } from 'api/utils/personalisation-header';
import { canShowRecommended } from 'components/RecommendedCarousel/canShowRecommended';
import { trackPersonalisation } from 'analytics/personalisation-tracking';

export const trackPersonalisationHeaderAndRecommendations = (
  res: Pick<Response, 'header' | 'body'>,
  state: WtrState,
) => {
  // @ts-expect-error experiments state is not typed
  const decisions = getAllDecisions(state);

  const starterBasketVariant = matchDecisionByExperiment(
    FAVOURITES_STARTER_BASKET.experiment,
    decisions?.[FAVOURITES_STARTER_BASKET.experiment],
  );

  if (starterBasketVariant === FAVOURITES_STARTER_BASKET.variant.showStarterBasket) {
    return;
  }

  withPersonalisationHeader(res, ({ personalisation }) => {
    const { recommendations = [], metadata: { fallbackRecommendations: fallback = false } = {} } =
      res.body ?? {};

    trackPersonalisation({
      personalisation,
      otherTrackingValues: {
        is_carousel_visible: canShowRecommended(recommendations) ? 'YES' : 'NO',
      },
      fallback,
    });
  });
};
