import React, { ReactNode, useEffect, useState } from 'react';
import DeliveryPassStatusContext, {
  DeliveryPassStatusEnum,
} from 'components/BookSlot/contexts/DeliveryPassStatus/DeliveryPassStatusContext';
import { apiCallback } from 'api';
import { useWtrStore } from 'redux/hooks';
import { getFeatureFlags } from 'utils/feature-flags';

/**
 * Wraps the delivery-pass-orchestration pass status api so it's called once with access to
 * the data in multiple places with minimal Redux ties.
 */
export const DeliveryPassStatusProvider = ({ children }: { children: ReactNode }) => {
  const store = useWtrStore();
  const [deliveryPassStatusContextValue, setDeliveryPassStatusContextValue] =
    useState<DeliveryPassStatusEnum>(DeliveryPassStatusEnum.NOT_AVAILABLE);
  const { deliveryPass_enableDeliveryPass: deliveryPassEnabled } = getFeatureFlags();

  useEffect(() => {
    if (deliveryPassEnabled) {
      const fetchData = async () => {
        const apiCall = apiCallback(
          {
            service: 'delivery-pass-orchestration',
            endpoint: 'pass/status',
            verbs: ['get'],
            defaultVersion: 1,
          },
          'get',
        )({ iHandleStatusCodes: true });

        let data;
        try {
          data = await apiCall(store);
        } catch {
          // Ignore any errors and leave status as default which is currently "NOT_AVAILABLE"
          // and the desired experience is to not show anything and allow the user to continue.
        }

        if (data && 'status' in data) {
          setDeliveryPassStatusContextValue(data.status);
        }
      };
      fetchData();
    }
  }, [deliveryPassEnabled, store]);

  return (
    <DeliveryPassStatusContext.Provider value={deliveryPassStatusContextValue}>
      {children}
    </DeliveryPassStatusContext.Provider>
  );
};
