import React, { memo } from 'react';
import loadable from '@loadable/component';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';

const LoadableExperimentsReporter = loadable(
  () => import('components/Experiment/ExperimentsReporter/ExperimentsReporter'),
);

const ExperimentsReporterLoader = memo(() => (
  <SimpleErrorBoundary logger="experiments-reported" section="App">
    <LoadableExperimentsReporter />
  </SimpleErrorBoundary>
));

export default ExperimentsReporterLoader;
