import React, { memo } from 'react';
import loadable from '@loadable/component';
import {
  Container as ContentLibraryContainer,
  ContainerProps,
} from '@johnlewispartnership/wtr-content-component-library';

const GLPNav = loadable(() => import('components/Browse/Navigation'));

const GLP_ELEMENT_ID_PREFIX = 'glp-nav-component';

const isGLPNavComponent = (elementId?: string) =>
  elementId?.startsWith(GLP_ELEMENT_ID_PREFIX) ?? false;

const withContainerReplacements = (ContainerComponent: typeof ContentLibraryContainer) => {
  const ContainerWrapper = memo((props: ContainerProps) => {
    if (isGLPNavComponent(props.elementId)) {
      return <GLPNav />;
    }

    return <ContainerComponent {...props} />;
  });

  return ContainerWrapper;
};

export default withContainerReplacements;
