import React from 'react';
import root from 'window-or-global';
import env from 'env/env';
import { suppressReact18PropTypeWarnings } from 'suppress-react-18-prop-type-warning';

if (env.development || env.apiEnv === 'qa') {
  root.React = React;
  root.waitrose = {};
  root.waitrose.env = env;

  suppressReact18PropTypeWarnings();
}
