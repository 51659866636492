import React, { memo } from 'react';
import loadable from '@loadable/component';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';

const LoadableWebVitalsReporter = loadable(
  () => import('components/WebVitalsReporter/WebVitalsReporter'),
);

const WebVitalsReporterLoader = memo(() => (
  <SimpleErrorBoundary logger="web-vitals-reported" section="App">
    <LoadableWebVitalsReporter />
  </SimpleErrorBoundary>
));

export default WebVitalsReporterLoader;
