import React, { Fragment, useMemo } from 'react';
import classNames from 'classnames';
import SkipLink from 'components/SkipLink';
import { useWtrSelector } from 'redux/hooks';

import { getIsSupportedBrowser } from 'redux/modules/browserInfo/selectors';
import { useExperiments } from 'components/Experiment/useExperiments';

import ContentLocation from 'components/ContentLocation';
import HomePageHeader from 'components/HomePage/Header';
import SiteHeader from 'components/SiteHeader';
import {
  AEM_HOME_PAGE_PRELOAD_IMAGES,
  HOME_PAGE_HEADER,
  LOYALTY_REDIRECT_HUB_TO_VOUCHERS_PAGE,
} from 'constants/monetateExperiments';
import ReturnToAdmin from 'components/ReturnToAdmin';
import SiteFooter from 'components/Footer/SiteFooter';

import { createFetcherChain } from 'route-data/common-fetch';
import { all } from 'route-data/all';
import { navigationFetcher } from 'redux/fetchers/navigation';
import { fetchHomePage } from 'redux/modules/search-and-browse/actions/fetch-homepage';
import Canonical from 'components/Canonical';

import styles from './HomePage.scss';

const HomePage = () => {
  const { getDecisionById } = useExperiments();
  const isSupportedBrowser = useWtrSelector(getIsSupportedBrowser);

  const displayGroceryHeader =
    getDecisionById(HOME_PAGE_HEADER.experiment).variant ===
    HOME_PAGE_HEADER.variant.displayGroceryHeader;

  const shouldPreloadImages =
    getDecisionById(AEM_HOME_PAGE_PRELOAD_IMAGES.experiment).variant ===
    AEM_HOME_PAGE_PRELOAD_IMAGES.variant.preload;

  const redirectToVouchers =
    getDecisionById(LOYALTY_REDIRECT_HUB_TO_VOUCHERS_PAGE.experiment).variant ===
    LOYALTY_REDIRECT_HUB_TO_VOUCHERS_PAGE.variant.loyalty_redirectHubToVouchersPage;

  const memoizedRedirectToVouchers = useMemo(() => redirectToVouchers, [redirectToVouchers]);

  return (
    <Fragment>
      <Canonical href="https://www.waitrose.com/" />
      <SkipLink destination="#main">Skip to main content</SkipLink>
      <ReturnToAdmin />
      {displayGroceryHeader ? (
        <SiteHeader />
      ) : (
        <HomePageHeader
          isSupportedBrowser={isSupportedBrowser}
          redirectToVouchers={memoizedRedirectToVouchers}
        />
      )}
      <main id="main">
        <div className={styles.contentBackgroundColour}>
          <ContentLocation name="masthead" />
        </div>
        <div
          data-testid="content-wrapper"
          className={classNames(styles.body, {
            [styles.hideShopWindows]: displayGroceryHeader,
          })}
        >
          <ContentLocation name="header" />
          <ContentLocation name="body" preloadImages={shouldPreloadImages} />
        </div>
      </main>
      <SiteFooter />
    </Fragment>
  );
};

export const homepageFetcher = createFetcherChain({
  fetchersInParallel: all(fetchHomePage, navigationFetcher),
});

export default HomePage;
