/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';
import useDeliveryPassStatus from 'components/BookSlot/contexts/DeliveryPassStatus/useDeliveryPassStatus';
import { DeliveryPassStatusEnum } from 'components/BookSlot/contexts/DeliveryPassStatus/DeliveryPassStatusContext';
import {
  AUTHENTICATED_ACCOUNT,
  DELIVERY_PASS_ACTIVE,
  DELIVERY_PASS_AVAILABLE,
  MY_DETAILS,
} from 'constants/categoryIds';

export type Menu = {
  id: string;
  name: string;
  categoryIds: string[];
  url?: string;
  hasDescendants?: boolean;
};

const useMenusWithDeliveryPass = (menus: Menu[]) => {
  if (!menus || !Array.isArray(menus)) return [];

  const deliveryPassStatus = useDeliveryPassStatus();

  const menusWithDeliveryPass = useMemo(() => {
    if (
      [DeliveryPassStatusEnum.ACTIVE, DeliveryPassStatusEnum.AVAILABLE].includes(deliveryPassStatus)
    ) {
      const DELIVERY_PASS =
        deliveryPassStatus === DeliveryPassStatusEnum.ACTIVE
          ? DELIVERY_PASS_ACTIVE
          : DELIVERY_PASS_AVAILABLE;

      const updatedMenus = menus.slice();

      const authMenuIdx = updatedMenus.findIndex(m => m.id === AUTHENTICATED_ACCOUNT);

      if (authMenuIdx > -1) {
        const { categoryIds } = updatedMenus[authMenuIdx];

        if (categoryIds.includes(DELIVERY_PASS)) {
          return updatedMenus;
        }

        const myDetailsIdx = categoryIds.findIndex(id => id === MY_DETAILS);

        if (myDetailsIdx > -1) {
          updatedMenus[authMenuIdx].categoryIds.splice(myDetailsIdx + 1, 0, DELIVERY_PASS);
        } else {
          updatedMenus[authMenuIdx].categoryIds.push(DELIVERY_PASS);
        }
      }
      return updatedMenus;
    }
    return menus;
  }, [deliveryPassStatus, menus]);

  return menusWithDeliveryPass;
};

export default useMenusWithDeliveryPass;
