import React from 'react';
import { SummaryRequestParams } from 'services/recipes-landing-service';

import Filters from '../Content/Filters/Filters';

type Filters = {
  title: string;
  values: {
    name: string;
    count: number;
  }[];
}[];

export type FilterTitle = 'Serving Size' | 'Dietary' | 'Cuisine' | 'Total Time';
export const FilterTitles: [...FilterTitle[]] = [
  'Serving Size',
  'Dietary',
  'Cuisine',
  'Total Time',
];

export const getFiltersFromSearch = (searchParams: URLSearchParams) => {
  const filters: SummaryRequestParams['filters'] = [];

  searchParams.forEach((value, title) => {
    if (value && FilterTitles.includes(title as FilterTitle)) {
      filters.push({
        title,
        values: value.split(','),
      });
    }
  });

  return filters;
};

type CheckedState = boolean[][];

export type PreferenceFilters = Record<string, string[]>;

export type SearchFilter = {
  title: string;
  values: string[];
};

export type PreferenceFiltersProps = {
  filters: Filters;
  onChange: (filters: SearchFilter[]) => void;
  filtersCheckedState: CheckedState;
  setFiltersCheckedState: React.Dispatch<React.SetStateAction<CheckedState>>;
};

const PreferenceFilters = ({
  filters,
  onChange,
  filtersCheckedState,
  setFiltersCheckedState,
}: PreferenceFiltersProps) => {
  return (
    <Filters
      filters={filters}
      externalState={filtersCheckedState}
      showApplied={false}
      enableMobile={false}
      closedItems={['Cuisine', 'Total Time']}
      onChange={(checkedState: CheckedState, filtersList: Filters) => {
        setFiltersCheckedState(checkedState);

        const searchFilters: SearchFilter[] = [];

        filtersList.forEach(({ title, values }, f) => {
          const selectedItems = values.filter((_, i) => checkedState[f][i]);
          if (selectedItems.length) {
            searchFilters.push({
              title,
              values: selectedItems.map(v => v.name),
            });
          }
        });

        onChange(searchFilters);
      }}
      showSectionItemsAsRadio={['Serving Size']}
    />
  );
};

export default PreferenceFilters;
export const { propTypes } = Filters; // eslint-disable-line react/forbid-foreign-prop-types
