import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import {
  Accordion,
  AccordionItem,
} from '@johnlewispartnership/wtr-ingredients/ingredients/Accordion';
import {
  ChevronDown,
  RemoveOutline,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { CloseButton } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal/CloseButton';
import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { Checkbox } from '@johnlewispartnership/wtr-ingredients/ingredients/Checkbox';
import { Radio } from '@johnlewispartnership/wtr-ingredients/ingredients/Radio/Radio';
import { LabeledControl } from '@johnlewispartnership/wtr-ingredients/ingredients/LabeledControl';

import { recipeFilterModal } from 'constants/modals';
import { trackModalClosed, trackModalOpened } from 'analytics/modal-tracking';

import styles from './index.module.scss';

const Filters = ({
  filters,
  externalState,
  showApplied,
  enableMobile,
  closedItems,
  showSectionItemsAsRadio,
  onChange,
  onClose,
}) => {
  const emptyState = () => Array.from({ length: filters.length }, () => []);

  const [checkedState, setChecked] = useState(emptyState());
  const [expanded, setExpanded] = useState(Array.from({ length: filters.length }, () => false));

  const [isOpen, setIsOpen] = useState(false);

  const checked = externalState || checkedState;

  const hasSelection = !!checked.reduce((acc, item) => acc || item.filter(v => v).length, false);

  const buttonRef = useRef(null);

  const { id, title: titleText, severity } = recipeFilterModal;

  const filterModal = {
    id,
    title: titleText,
    severity,
  };

  const close = () => {
    if (isOpen) {
      setIsOpen(false);
      setTimeout(() => {
        buttonRef.current?.focus();
        onClose(buttonRef);
      }, 0);
    }
  };

  const clear = () => {
    close();
    const state = emptyState();
    setChecked(state);
    onChange(state, filters);
  };

  const update = () => {
    onChange(checked, filters);
  };

  const done = () => {
    close();
    update();
    trackModalClosed(filterModal);
  };

  const inner = () => {
    const applied = filters.flatMap(({ values }, i) => values.filter((_, j) => checked[i][j]));

    return (
      <section
        aria-label="Recipe filters"
        className={classNames(styles.filters, isOpen ? styles.overlay : '')}
      >
        <header
          className={classNames(
            styles.white,
            isOpen ? '' : styles.desktop,
            hasSelection && !isOpen ? 'hidden' : '',
          )}
        >
          <Typography element="h2" styleAs={isOpen ? 'paragraphHeading' : 'sectionHeadingSmall'}>
            Filter {isOpen ? 'by' : ''}
          </Typography>
          {isOpen && <CloseButton close={done} />}
        </header>
        {enableMobile && (
          <div className={classNames(styles.mobile, styles.white)}>
            <Button
              label={`Filter by${applied.length ? ` (${applied.length})` : ''}`}
              theme="secondary"
              endIcon={<ChevronDown className={styles.chevronIcon} size="small" />}
              ref={buttonRef}
              className={classNames(styles.filterButton)}
              onClick={() => {
                setIsOpen(true);
                trackModalOpened(filterModal);
              }}
            />
          </div>
        )}
        <div
          className={classNames(
            { [styles.inner]: enableMobile },
            styles.white,
            isOpen && styles.innerOpen,
          )}
        >
          {showApplied && hasSelection && (
            <div className={classNames(styles.appliedHeader, isOpen ? 'hidden' : '')}>
              <Typography element="h3" styleAs="sectionHeadingSmall">
                Applied Filters
              </Typography>
              <ul>
                {filters.map(({ values }, i) =>
                  values.map(
                    (f, j) =>
                      checked[i][j] && (
                        <li className={styles.applied} key={f.name}>
                          <Typography styleAs="paragraph">{f.name}</Typography>
                          <button
                            type="button"
                            aria-label={`Remove ${f.name} from applied filters`}
                            onClick={() => {
                              checked[i][j] = false;
                              if (isOpen || !externalState) setChecked([...checked]);
                              update();
                            }}
                          >
                            <RemoveOutline size="xsmall" className={styles.removeIcon} />
                          </button>
                        </li>
                      ),
                  ),
                )}

                <button aria-hidden="true" className={styles.clear} type="button" onClick={clear}>
                  <Typography styleAs="paragraphHeading">Clear all</Typography>
                </button>
              </ul>
            </div>
          )}

          <Accordion theme="plusMinus" scrollToItemWhenOpened={false}>
            {filters.map(({ title, values }, i) => {
              const isExpanded = expanded[i];
              const handleFilterChange = (j, isChecked) => {
                checked[i].length = values.length;
                checked[i][j] = isChecked;
                if (isOpen || !externalState) setChecked([...checked]);
                if (!isOpen) update();
              };

              return (
                <div
                  key={title}
                  className={classNames(
                    styles.accordion,
                    i === filters.length - 1 ? '' : styles.divider,
                  )}
                >
                  <AccordionItem
                    title={title}
                    headingElement="h3"
                    id={`filters-accordion-${i}`}
                    defaultExpanded={!closedItems.includes(title)}
                  >
                    <ul>
                      {values
                        .filter((_, k) => k < (isExpanded ? 1000 : 10))
                        .map((f, j) => (
                          <li key={f.name}>
                            <LabeledControl
                              control={
                                showSectionItemsAsRadio.includes(title) ? (
                                  <Radio
                                    name={`filters-${i}`}
                                    value={f.name}
                                    checked={!!checked[i][j]}
                                    onChange={() => {
                                      checked[i] = Array(values.length).fill(false);
                                      handleFilterChange(j, true);
                                    }}
                                  />
                                ) : (
                                  <Checkbox
                                    name={`filters-${i}-${j}`}
                                    value={checked[i][j] ? 'checked' : ''}
                                    checked={!!checked[i][j]}
                                    onChange={e => handleFilterChange(j, e.target.checked)}
                                  />
                                )
                              }
                              label={
                                <>
                                  <Typography
                                    noMargins
                                    styleAs={checked[i][j] ? 'paragraphHeading' : 'paragraph'}
                                  >
                                    {f.name}
                                  </Typography>
                                  &nbsp;
                                  <Typography noMargins styleAs="paragraph">
                                    {(!showSectionItemsAsRadio.includes(title) || checked[i][j]) &&
                                      `(${f.count})`}
                                  </Typography>
                                </>
                              }
                            />
                          </li>
                        ))}
                    </ul>
                    {values.length > 10 && (
                      <button
                        className={styles.showMore}
                        type="button"
                        onClick={e => {
                          e.preventDefault();
                          expanded[i] = !isExpanded;
                          setExpanded([...expanded]);
                        }}
                      >
                        Show {isExpanded ? 'less' : `${values.length - 10} more`}
                      </button>
                    )}
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>

          {isOpen && (
            <div className={classNames(styles.hidden, { [styles.mobileBtns]: enableMobile })}>
              <Button aria-hidden="true" label="Clear all" theme="secondary" onClick={clear} />
              <Button aria-hidden="true" label="Done" theme="primary" onClick={done} />
            </div>
          )}
        </div>
      </section>
    );
  };

  return (
    <>
      {isOpen ? (
        <Modal overlayClassName={styles.modalOverlay} className={styles.content} isOpen>
          {inner()}
        </Modal>
      ) : (
        inner()
      )}
    </>
  );
};

Filters.propTypes = {
  externalState: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          count: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
  showApplied: PropTypes.bool,
  enableMobile: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  closedItems: PropTypes.arrayOf(PropTypes.string),
  showSectionItemsAsRadio: PropTypes.arrayOf(PropTypes.string),
};

Filters.defaultProps = {
  filters: [],
  externalState: null,
  showApplied: true,
  enableMobile: true,
  closedItems: [],
  showSectionItemsAsRadio: [],
  onChange: () => {},
  onClose: () => {},
};

export default Filters;
