import React from 'react';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';

import { updateShoppableIngredientPriceById } from 'redux/modules/recipes/actions';
import { getShoppableProducts } from 'redux/modules/recipes/selectors';
import { IngredientGroups, ShoppableItem } from 'redux/modules/recipes/index.d';
import { getCustomerSlotBranchId } from 'redux/modules/customer-slot/selectors';
import { updateShoppableIngredientById } from 'redux/modules/recipes/actions/update-shoppable-by-id';
import styles from './index.scss';

import ShoppablePod, { ProductTags } from './ShoppablePod';
import { SwapProductHandler } from '../ShoppableHeading';
import type { SwapProductPayload } from '../SwapProduct';
import { ToggleIngredientsRefType } from '../../../MealPlanner/AddToTrolleyModal/RecipeAccordionItem';

export const STORE_CUPBOARD = 'STORE_CUPBOARD';
export const CORE = 'CORE';

export type LineNumber = string;

export interface ProductDataType {
  id: string;
  uuid?: string;
  name: string;
  size: string;
  thumbnail: string;
  lineNumber: LineNumber;
  displayPrice: string;
  productType: string;
  promotions: number[];
  weights: {
    defaultQuantity: {
      uom: string;
    };
  };
  currentSaleUnitPrice: {
    quantity: {
      amount: number;
      uom: string;
    };
    price: {
      amount: number;
      currencyCode: string;
    };
  };
  conflicts: string[];
  productTags: ProductTags;
  pricing: {
    displayUOMPrice: string;
    displayPriceQualifier: string;
  };
  categories: { id: string; name: string }[];
  brand: string;
}

const ProductGrid = ({
  recipeId,
  showSwapProduct,
  setCurrentIngredientIndex,
  currentSwappedProduct,
  toggleIngredientsRef,
  forcedHideSwapLink,
}: {
  recipeId: string;
  showSwapProduct?: ({ payload }: SwapProductHandler) => void;
  setCurrentIngredientIndex: (i: number) => void;
  currentSwappedProduct: SwapProductPayload | null;
  toggleIngredientsRef?: ToggleIngredientsRefType;
  forcedHideSwapLink?: boolean;
}) => {
  const branchId = useWtrSelector(getCustomerSlotBranchId) ?? '651';

  // PIMS/Recipe data
  const dispatch = useWtrDispatch();
  const shoppableProducts = useWtrSelector(state => getShoppableProducts(state, recipeId));

  const onUpdateIngredientPrice = (
    shoppableProductIndex: number,
    ingredientId: string,
    updatedIngredient: ShoppableItem,
  ) => {
    dispatch(
      updateShoppableIngredientPriceById({
        branchId,
        recipeId,
        ingredientId,
        groupId: shoppableProductIndex,
        updatedIngredient,
      }),
    );
  };

  const onUpdateIngredient = (
    shoppableProductIndex: number,
    ingredientId: string,
    updatedIngredient: ShoppableItem,
  ) => {
    dispatch(
      updateShoppableIngredientById({
        recipeId,
        ingredientId,
        groupId: shoppableProductIndex,
        updatedIngredient,
      }),
    );
  };

  return (
    <ul aria-label="Products" className={styles.wrapper} data-testid="product-grid">
      {shoppableProducts?.length &&
        shoppableProducts.map(({ title, ingredients }: IngredientGroups, i: number) => {
          const { storeCupboard, nonStoreCupboard } = ingredients;
          return (
            <div key={title}>
              {title && (
                <div className={styles.ingredientGroup}>
                  <div className={styles.groupTitle}>{title}</div>
                </div>
              )}

              {nonStoreCupboard
                .filter(ingredient => !!ingredient?.lineNumber)
                .map(ingredient => (
                  <ShoppablePod
                    key={ingredient.ingredientId}
                    ingredient={ingredient}
                    onUpdate={updatedIngredient =>
                      onUpdateIngredient(i, ingredient.ingredientId, updatedIngredient)
                    }
                    recipeId={recipeId}
                    onUpdatePrice={updatedIngredient =>
                      onUpdateIngredientPrice(i, ingredient.ingredientId, updatedIngredient)
                    }
                    showSwapProduct={showSwapProduct}
                    shoppableProductIndex={i}
                    setCurrentIngredientIndex={setCurrentIngredientIndex}
                    highlight={currentSwappedProduct?.lineNumber === ingredient.lineNumber}
                    toggleIngredientsRef={toggleIngredientsRef}
                    forcedHideSwapLink={forcedHideSwapLink}
                  />
                ))}
              {storeCupboard.length > 0 && (
                <div data-testid="store-cupboard-items">
                  <div className={styles.storeCupboardGroup}>
                    <div>Make sure you have these ingredients</div>
                  </div>
                  {storeCupboard
                    .filter(ingredient => !!ingredient?.lineNumber)
                    .map(ingredient => (
                      <ShoppablePod
                        key={ingredient.ingredientId}
                        ingredient={ingredient}
                        onUpdate={updatedIngredient =>
                          onUpdateIngredient(i, ingredient.ingredientId, updatedIngredient)
                        }
                        recipeId={recipeId}
                        onUpdatePrice={updatedIngredient =>
                          onUpdateIngredientPrice(i, ingredient.ingredientId, updatedIngredient)
                        }
                        showSwapProduct={showSwapProduct}
                        shoppableProductIndex={i}
                        setCurrentIngredientIndex={setCurrentIngredientIndex}
                        toggleIngredientsRef={toggleIngredientsRef}
                        forcedHideSwapLink={forcedHideSwapLink}
                      />
                    ))}
                </div>
              )}
            </div>
          );
        })}
    </ul>
  );
};

export default ProductGrid;
