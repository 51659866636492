import type { MealDealId } from 'redux/modules/meal-deals/types';
import { getMealDealBuilders, getMealDealId } from 'redux/modules/meal-deals/selectors';

export interface CompleteMealDealEvent {
  event: 'complete_meal_deal';
  meal_deal: {
    meal_deal_id: MealDealId;
  };
}

const getCompleteMealDealEvent = (
  state: WtrState,
  lineNumber: string,
): CompleteMealDealEvent | null => {
  // Consumer includes `searchType` guard which ensures we are on a Meal Deal
  // page, and the add/remove to trolley means that the page must have
  // successfully loaded, so the ID is defined.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mealDealId = getMealDealId(state)!;
  const mealDealBuilders = getMealDealBuilders(state);
  const completedBuilders = mealDealBuilders.slice(0, -1);

  let selectedLineIsInCompletedMealDealBuilders = false;
  let selectedLineGroupIndex = null;

  for (let builderIndex = 0; builderIndex < completedBuilders.length; builderIndex += 1) {
    if (selectedLineIsInCompletedMealDealBuilders) {
      break;
    }

    const { groups } = completedBuilders[builderIndex];

    for (let groupIndex = 0; groupIndex < groups.length; groupIndex += 1) {
      const group = groups[groupIndex];
      const groupContainsLine = group.items.some(item => item?.lineNumber === lineNumber);

      if (groupContainsLine) {
        selectedLineIsInCompletedMealDealBuilders = true;
        selectedLineGroupIndex = groupIndex;

        break;
      }
    }
  }

  if (selectedLineGroupIndex === null) {
    return null;
  }

  // Consumer includes `searchType` guard which ensures we are on a Meal Deal
  // page, and the add/remove to trolley means that the page must have
  // successfully loaded. All loaded Meal Deal pages have at least one builder.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const lastBuilderGroups = mealDealBuilders.at(-1)!.groups;
  const selectedLineGroupEmptyInLastBuilder = lastBuilderGroups[selectedLineGroupIndex].items.every(
    item => item === null,
  );

  const mealDealCompleted =
    selectedLineIsInCompletedMealDealBuilders && selectedLineGroupEmptyInLastBuilder;

  if (!mealDealCompleted) {
    return null;
  }

  return {
    event: 'complete_meal_deal',
    meal_deal: {
      meal_deal_id: mealDealId,
    },
  };
};

export default getCompleteMealDealEvent;
