import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const CANCEL_ORDER_FAILED = 'waitrose/orders/CANCEL_ORDER_FAILED' as const;
export const CANCEL_ORDER_LOADED = 'waitrose/orders/CANCEL_ORDER_LOADED' as const;
export const CANCEL_ORDER_LOADING = 'waitrose/orders/CANCEL_ORDER_LOADING' as const;

export const CANCEL_ORDER = [
  CANCEL_ORDER_LOADING,
  CANCEL_ORDER_LOADED,
  CANCEL_ORDER_FAILED,
] as const;

// GET_ORDER

export const GET_ORDER_FAILED = 'waitrose/orders/GET_ORDER_FAILED' as const;
export const GET_ORDER_LOADED = 'waitrose/orders/GET_ORDER_LOADED' as const;
export const GET_ORDER_LOADING = 'waitrose/orders/GET_ORDER_LOADING' as const;

// GET_PENDING_ORDERS

export const GET_PENDING_ORDERS_FAILED = 'waitrose/orders/GET_PENDING_ORDERS_FAILED' as const;
export const GET_PENDING_ORDERS_LOADED = 'waitrose/orders/GET_PENDING_ORDERS_LOADED' as const;
export const GET_PENDING_ORDERS_LOADING = 'waitrose/orders/GET_PENDING_ORDERS_LOADING' as const;

export const GET_PENDING_ORDERS = apiPromiseTypes('pending-orders', 'get');

// GET_DELIVERY_STATUS

export const GET_DELIVERY_STATUS = apiPromiseTypes('delivery-statuses', 'get');

// GET_PREVIOUS_ORDERS

export const GET_PREVIOUS_ORDERS_FAILED = 'waitrose/orders/GET_PREVIOUS_ORDERS_FAILED' as const;
export const GET_PREVIOUS_ORDERS_LOADED = 'waitrose/orders/GET_PREVIOUS_ORDERS_LOADED' as const;
export const GET_PREVIOUS_ORDERS_LOADING = 'waitrose/orders/GET_PREVIOUS_ORDERS_LOADING' as const;

export const GET_PREVIOUS_ORDERS = [
  GET_PREVIOUS_ORDERS_LOADING,
  GET_PREVIOUS_ORDERS_LOADED,
  GET_PREVIOUS_ORDERS_FAILED,
] as const;

// GET_RECENT_ORDERS

export const GET_RECENT_ORDERS = apiPromiseTypes('order-orchestration-orders-recent', 'get');

// GET_COMPLETED_ORDERS

export const GET_COMPLETED_ORDERS_FAILED = 'waitrose/orders/GET_COMPLETED_ORDERS_FAILED' as const;
export const GET_COMPLETED_ORDERS_LOADED = 'waitrose/orders/GET_COMPLETED_ORDERS_LOADED' as const;
export const GET_COMPLETED_ORDERS_LOADING = 'waitrose/orders/GET_COMPLETED_ORDERS_LOADING' as const;

export const GET_COMPLETED_ORDERS = [
  GET_COMPLETED_ORDERS_LOADING,
  GET_COMPLETED_ORDERS_LOADED,
  GET_COMPLETED_ORDERS_FAILED,
] as const;

export const GET_ORDER_PRODUCTS_FAILED = 'waitrose/orders/GET_ORDER_PRODUCTS_FAILED' as const;
export const GET_ORDER_PRODUCTS_LOADED = 'waitrose/orders/GET_ORDER_PRODUCTS_LOADED' as const;
export const GET_ORDER_PRODUCTS_LOADING = 'waitrose/orders/GET_ORDER_PRODUCTS_LOADING' as const;

export const GET_ORDER_PRODUCTS = [
  GET_ORDER_PRODUCTS_LOADING,
  GET_ORDER_PRODUCTS_LOADED,
  GET_ORDER_PRODUCTS_FAILED,
] as const;

export const GET_ORDER_SLOT_TYPE_LOADED = 'waitrose/order/GET_ORDER_SLOT_TYPE_LOADED' as const;
export const GET_ORDER_SLOT_TYPE_FAILED = 'waitrose/order/GET_ORDER_SLOT_TYPE_FAILED' as const;

export const GET_ORDER_RECOMMENDATIONS = apiPromiseTypes('products-recommendations-order', 'get');

// FETCH_CUSTOMER_ORDER

export const FETCH_CUSTOMER_ORDER_STATS_LOADING =
  'waitrose/orders/FETCH_CUSTOMER_ORDER_STATS_LOADING' as const;
export const FETCH_CUSTOMER_ORDER_STATS_LOADED =
  'waitrose/orders/FETCH_CUSTOMER_ORDER_STATS_LOADED' as const;
export const CLEAR_CUSTOMER_ORDER_STATS = 'waitrose/orders/CLEAR_CUSTOMER_ORDER_STATS' as const;

export const FETCH_CUSTOMER_ORDER_STATS = [
  FETCH_CUSTOMER_ORDER_STATS_LOADING,
  FETCH_CUSTOMER_ORDER_STATS_LOADED,
  CLEAR_CUSTOMER_ORDER_STATS,
] as const;
