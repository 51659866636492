import React, { useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import isEqual from 'lodash/isEqual';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { getAutoSuggestActive } from 'redux/modules/auto-suggest/selectors';
import { getFunctionalCookieState } from 'redux/modules/cookies/selectors';
import { getCustomerSearchTerm } from 'redux/modules/search-and-browse/selectors/search';

import autoSuggestClear from 'redux/modules/auto-suggest/actions/auto-suggest-clear';
import autoSuggestSearch from 'redux/modules/auto-suggest/actions/auto-suggest-search';
import { updateSearchTerm } from 'redux/modules/search/actions/update-search-term';
import { setMobileOverlay } from 'redux/modules/page/actions';

import AutoComplete from 'components/Search/AutoComplete';
import { useSearchForm } from 'components/Search/SearchForm/use-search-form';
import SearchHistory from 'components/Search/SearchHistory';
import { SearchBox } from '@johnlewispartnership/wtr-ingredients/ingredients/SearchBox';
import MultiSearchModal from 'components/MultiSearch/Modal';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import styles from './MobileOverlay.scss';

type Props = {
  clearValue: boolean;
  setClearValue: (value: boolean) => void;
};

const MobileOverlay = ({ clearValue, setClearValue }: Props) => {
  const dispatch = useDispatch();

  const autoSuggestActive = useSelector(getAutoSuggestActive, isEqual);
  const functionalCookieConsent = useSelector(getFunctionalCookieState, isEqual);
  const initialValue = useSelector(getCustomerSearchTerm, isEqual);

  const {
    handleChange,
    handleClear,
    handleFocus,
    handleSelectedAutoCompleteItem,
    handleSelectedSearchHistoryItem,
    handleSubmit,
    hasFocus,
    insideRef: formRef,
    inputRef,
    setValue,
    showSearchHistory,
    value,
  } = useSearchForm({
    autoSuggestActive,
    eligibleForAutoSuggest: true,
    functionalCookieConsent,
    initialValue,
    onChange: autoSuggestSearch,
    onClear: autoSuggestClear,
    onSubmit: updateSearchTerm,
    onSearch: setMobileOverlay,
  });

  const inputProps = {
    onFocus: handleFocus,
    ref: inputRef,
    id: 'mobileSearchInput',
  };

  const insideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return handleFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newValue = clearValue ? setValue('') : setValue(initialValue);
    return newValue;
  }, [clearValue, initialValue, setValue]);

  const lockBodyScroll = useCallback(() => {
    if (insideRef.current) disableBodyScroll(insideRef.current);
  }, [insideRef]);

  useEffect(() => {
    lockBodyScroll();
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [handleClear, insideRef, lockBodyScroll]);

  const closeModal = () => {
    setClearValue(false);
    dispatch(setMobileOverlay(false));
    dispatch(autoSuggestClear());
  };

  return (
    <div
      ref={insideRef}
      role="dialog"
      aria-label="Search form"
      className={styles.dialog}
      data-test="mobile-overlay"
    >
      <div className={styles.header}>
        <div className={styles.search}>
          <SearchBox
            ariaActivedescendant={
              showSearchHistory ? 'search-history-item' : 'autocomplete-suggestion'
            }
            ariaAutocomplete="list"
            ariaOwns={showSearchHistory ? 'search-history' : 'autocomplete-listbox'}
            focus={hasFocus}
            formRef={formRef}
            id="mobileSearch"
            initialValue={initialValue}
            inputProps={inputProps}
            placeholder="Search groceries..."
            value={value}
            onChange={handleChange}
            onClearClick={handleClear}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={styles.cancel}>
          <TextLink
            underline="always"
            data-testid="close-overlay"
            onClick={closeModal}
            component="button"
          >
            Cancel
          </TextLink>
        </div>
      </div>
      <div className={styles.multisearch}>
        <MultiSearchModal
          hidden={false}
          mobile
          onSearch={() => dispatch(setMobileOverlay(false))}
        />
      </div>
      {!value.length && (
        <SearchHistory inputRef={inputRef} onSelectTerm={handleSelectedSearchHistoryItem} />
      )}
      <AutoComplete
        inline
        inputRef={inputRef}
        onClear={() => dispatch(autoSuggestClear())}
        onSelectItem={handleSelectedAutoCompleteItem}
      />
    </div>
  );
};

MobileOverlay.displayName = 'MobileOverlay';

export default MobileOverlay;
