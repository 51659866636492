import categoryIds, {
  AUTHENTICATED_ACCOUNT,
  CUSTOMER_SERVICE,
  FAVOURITES,
  GROCERIES,
  HOME,
  INSPIRATION,
  LISTS,
  LOYALTY,
  LOYALTY_VOUCHERS,
  MY_ORDERS,
  MY_DETAILS,
  MEGA_MENU,
  NEW,
  OFFERS,
  FIND_STORE,
  RECIPES,
  REGISTER,
  SIGN_IN,
  SIGN_OUT,
  SITE_LINKS,
  SLOT_BUTTON,
  UNAUTHENTICATED_ACCOUNT,
  SEASONAL,
  DELIVERY_PASS_ACTIVE,
  DELIVERY_PASS_AVAILABLE,
} from 'constants/categoryIds';
import urls from 'constants/urls';
import { deepFreeze } from 'utils/deepFreeze';

import wcsConfig from 'utils/wcs-config';

const {
  ENTERTAINING_LINK,
  FAVOURITES_LINK,
  LISTS_LINK,
  INSPIRATION_LINK,
  MY_ACCOUNT_LINK,
  OFFERS_LINK,
  RECIPES_LINK,
} = categoryIds;

export default () =>
  deepFreeze({
    data: {
      0: {
        id: '0',
        name: 'Menu',
        categoryIds: [
          GROCERIES,
          SEASONAL,
          ENTERTAINING_LINK,
          OFFERS_LINK,
          RECIPES_LINK,
          INSPIRATION_LINK,
          FAVOURITES_LINK,
          LISTS_LINK,
          MY_ACCOUNT_LINK,
        ],
      },
      [SEASONAL]: {
        id: SEASONAL,
        categoryIds: [SEASONAL],
      },
      [ENTERTAINING_LINK]: {
        id: ENTERTAINING_LINK,
        name: 'Entertaining',
        url: urls.entertainingHome,
      },
      [OFFERS_LINK]: {
        id: OFFERS_LINK,
        name: 'Offers',
        url: 'shop/browse/offers',
        additionalClasses: 'offerLink',
      },
      [RECIPES_LINK]: {
        id: RECIPES_LINK,
        name: 'Recipes',
        url: '/ecom/recipes',
      },
      [INSPIRATION_LINK]: {
        id: INSPIRATION_LINK,
        name: 'Inspiration',
        url: wcsConfig.inspirationUrl(),
      },
      [FAVOURITES_LINK]: {
        id: FAVOURITES_LINK,
        name: 'Favourites',
        shortName: 'favourites',
        iconName: 'FavouritesInactive',
        url: 'favourites',
      },
      [LISTS_LINK]: {
        id: LISTS_LINK,
        name: 'Lists',
        shortName: 'lists',
        iconName: 'DocumentAdd',
        url: 'lists',
      },
      [MY_ACCOUNT_LINK]: {
        id: MY_ACCOUNT_LINK,
        name: 'My Account',
        iconName: 'User',
        shortName: 'account',
        url: 'myaccount',
      },
      [MEGA_MENU]: {
        id: MEGA_MENU,
        name: 'Main menu',
        categoryIds: [
          GROCERIES,
          SEASONAL,
          ENTERTAINING_LINK,
          OFFERS,
          NEW,
          RECIPES,
          INSPIRATION,
          SLOT_BUTTON,
        ],
        url: urls.browse,
      },
      [NEW]: {
        id: NEW,
        name: 'New',
        offer: false,
        url: urls.new,
      },
      [OFFERS]: {
        id: OFFERS,
        name: 'Offers',
        offer: true,
        url: urls.offers,
      },
      [RECIPES]: {
        id: RECIPES,
        name: 'Recipes',
        url: '/ecom/recipes',
      },
      [INSPIRATION]: {
        id: INSPIRATION,
        name: 'Inspiration',
        url: wcsConfig.inspirationUrl(),
      },
      [SLOT_BUTTON]: {
        iconName: 'Calendar',
        id: SLOT_BUTTON,
        url: urls.serviceSelection,
      },
      [AUTHENTICATED_ACCOUNT]: {
        categoryIds: [FAVOURITES, MY_ORDERS, MY_DETAILS, LISTS, LOYALTY, SIGN_OUT],
        id: AUTHENTICATED_ACCOUNT,
        name: 'My account',
      },
      [FAVOURITES]: {
        iconName: 'HeartInactive',
        id: FAVOURITES,
        name: 'Favourites',
        url: urls.favourites,
      },
      [MY_ORDERS]: {
        iconName: 'Document',
        id: MY_ORDERS,
        name: 'My orders',
        url: urls.myOrdersPage,
      },
      [MY_DETAILS]: {
        iconName: 'User',
        id: MY_DETAILS,
        name: 'My details',
        url: urls.myDetailsPage,
      },
      [LISTS]: {
        iconName: 'DocumentAdd',
        id: LISTS,
        name: 'Lists',
        url: urls.lists,
      },
      [LOYALTY]: {
        iconName: 'MyWaitrose',
        id: LOYALTY,
        name: 'myWaitrose loyalty',
        url: urls.myWaitrosePage,
      },
      [DELIVERY_PASS_ACTIVE]: {
        iconName: 'Van',
        id: DELIVERY_PASS_ACTIVE,
        name: 'Delivery pass',
        url: urls.deliveryPassActive,
      },
      [DELIVERY_PASS_AVAILABLE]: {
        iconName: 'Van',
        id: DELIVERY_PASS_AVAILABLE,
        name: 'Delivery pass',
        url: urls.deliveryPassAvailable,
      },
      [LOYALTY_VOUCHERS]: {
        iconName: 'MyWaitrose',
        id: LOYALTY_VOUCHERS,
        name: 'myWaitrose loyalty',
        url: urls.myWaitroseVouchers,
      },
      [UNAUTHENTICATED_ACCOUNT]: {
        categoryIds: [SIGN_IN, REGISTER],
        id: UNAUTHENTICATED_ACCOUNT,
        name: 'My account',
      },
      [SIGN_IN]: {
        id: SIGN_IN,
        name: 'Sign in',
        url: urls.login,
      },
      [SIGN_OUT]: {
        id: SIGN_OUT,
        name: 'Sign out',
        url: urls.homepage,
      },
      [REGISTER]: {
        id: REGISTER,
        name: 'Register',
        url: urls.registration,
      },
      [SITE_LINKS]: {
        categoryIds: [FIND_STORE, CUSTOMER_SERVICE, HOME],
        id: SITE_LINKS,
        name: '',
      },
      [FIND_STORE]: {
        id: FIND_STORE,
        name: 'Find a store',
        url: 'https://www.waitrose.com/find-a-store',
      },
      [CUSTOMER_SERVICE]: {
        id: CUSTOMER_SERVICE,
        name: 'Customer service',
        url: urls.customerServiceHomePage,
      },
      [HOME]: {
        iconName: 'Home',
        id: HOME,
        name: 'Home',
        url: urls.homepage,
      },
    },
    depth: 0,
    errorResponse: null,
    footer: {
      data: null,
      loading: false,
    },
    loaded: false,
    loading: false,
    rootCategories: null,
  });
