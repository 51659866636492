import { GET_ORDER_PRODUCTS_LOADED } from 'redux/modules/orders/actions/types';
import {
  INTERSTITIALS_FULFILLED,
  INTERSTITIALS_UPDATED,
} from 'redux/modules/interstitials/actions/types';
import {
  NEXTLOADED,
  OFFER_PRODUCTS_LOADED,
  LOADED as SEARCHANDBROWSE_LOADED,
} from 'redux/modules/search-and-browse/actions/types';
import { APPLYFILTER, APPLY_CATEGORY_FILTER, SORTBY } from 'redux/modules/search/actions/types';
import { SHOPPING_LIST_PRODUCTS_FULFILLED } from 'redux/modules/shopping-list/actions/types';
import {
  FAVOURITES_CATEGORY_FILTER_IMPRESSION,
  FAVOURITES_OFFER_FILTER_IMPRESSION,
  FAVOURITES_SORT_IMPRESSION,
} from 'redux/modules/favourites-experience/actions/types';

import interstitialsImpressions from './interstitials';
import searchAndBrowseImpressions from './search-and-browse';
import searchImpressions from './search';
import shopFromOrderProductImpressions from './grids/shop-from-order-products';
import shoppingListProductImpressions from './grids/shopping-list-products';
import sortByImpressions from './search/sort';
import filterImpressions from './search/search-filters';
import categoryFilter from './search/category-filters';
import favouritesCategoryFilterImpressions from './favourites/category-filter';
import favouritesOfferFilterImpressions from './favourites/offer-filter';
import favouritesSortByImpressions from './favourites/sort';

export default {
  [GET_ORDER_PRODUCTS_LOADED]: shopFromOrderProductImpressions,
  [INTERSTITIALS_FULFILLED]: interstitialsImpressions,
  [INTERSTITIALS_UPDATED]: interstitialsImpressions,
  [SEARCHANDBROWSE_LOADED]: searchImpressions,
  [NEXTLOADED]: searchAndBrowseImpressions,
  [OFFER_PRODUCTS_LOADED]: searchAndBrowseImpressions,
  [SHOPPING_LIST_PRODUCTS_FULFILLED]: shoppingListProductImpressions,
  [SORTBY]: sortByImpressions,
  [APPLYFILTER]: filterImpressions,
  [APPLY_CATEGORY_FILTER]: categoryFilter,
  [FAVOURITES_CATEGORY_FILTER_IMPRESSION]: favouritesCategoryFilterImpressions,
  [FAVOURITES_OFFER_FILTER_IMPRESSION]: favouritesOfferFilterImpressions,
  [FAVOURITES_SORT_IMPRESSION]: favouritesSortByImpressions,
};
