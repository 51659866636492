import { ADD_TO_TROLLEY_DESCRIPTION } from 'redux/middleware/analytics/actions-to-track/trolley/constants';
import { SOFT_TYPE } from 'constants/restrictions';

import changeCart from './change-cart';

const transform = (state, { result, itemsToAdd }) => {
  const { trolley } = result;
  const { conflicts } = trolley;

  const trolleyItems = itemsToAdd.filter(item => {
    const hasConflict = conflicts.find(c => c.productId === item.productId);
    if (hasConflict === undefined) return item;
    if (hasConflict.type === SOFT_TYPE) return item;
    return undefined;
  });

  if (trolleyItems.length === 0) {
    return null;
  }

  const itemsAdded = trolleyItems.map(trolleyItem => ({
    analytics: {
      // unlike update cart action, here it is safe to derive these from the trolleyItem
      lineNumber: trolleyItem.lineNumber,
      uom: trolleyItem.quantity.uom,
      ingredientType: trolleyItem.ingredientType,
      searchType: trolleyItem.searchType,
      clickContext: trolleyItem.clickContext,
    },
  }));

  return changeCart(state, itemsAdded, 'add', ADD_TO_TROLLEY_DESCRIPTION);
};

export default transform;
