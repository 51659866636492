import React from 'react';
import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

import { KEY_ENTER } from 'constants/keys';

import { slowlyScrollPageVerticallyToTop } from 'utils/scroll';

import styles from 'components/MultiSearch/Button/MultiSearchButton.scss';

type MultiSearchButtonProps = {
  loading?: boolean;
  onClick: (event: React.MouseEvent | React.KeyboardEvent) => unknown;
  selected?: boolean;
  value: string;
};

const MultiSearchButton = ({
  loading = false,
  onClick,
  selected = false,
  value,
}: MultiSearchButtonProps) => {
  const handler = (event: React.KeyboardEvent | React.MouseEvent) => {
    // Prevent Chrome's crude scrolling into view before firing handler
    event.preventDefault();
    slowlyScrollPageVerticallyToTop();
    return !selected ? onClick(event) : undefined;
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.charCode === KEY_ENTER || event.keyCode === KEY_ENTER) {
      return handler(event);
    }

    return false;
  };

  return (
    <div className={styles.multiSearchButton}>
      <Button
        data-testid={`msv-${selected ? 'active' : 'label'}`}
        label={value}
        onClick={handler}
        theme={selected ? 'primary' : 'secondary'}
        onKeyDown={onKeyDown}
        width="fit"
        disabled={loading}
      />
    </div>
  );
};

export default MultiSearchButton;
