export class SSRRedirectError extends Error {
  url: string;

  status: number;

  constructor(url: string, status: number) {
    super(`Redirect to ${url} with status ${status}`);

    this.url = url;
    this.status = status;
  }
}

export const ssrRedirect = (url: string, status: number) => {
  throw new SSRRedirectError(url, status);
};
