import { createSelector } from 'reselect';
import queryString from 'query-string';

import env from 'env/env';

import {
  AUTHENTICATED_ACCOUNT,
  ENTERTAINING,
  GROCERIES,
  SITE_LINKS,
  UNAUTHENTICATED_ACCOUNT,
  OTHERS,
} from 'constants/categoryIds';
import urls from 'constants/urls';
import { TAXONOMY_FULL_DEPTH as FULL_DEPTH } from 'constants/taxonomy';

import { categoryNameToUrl } from 'utils/format';
import { pathJoin } from 'utils/general';

import { isUserLoggedIn } from 'redux/modules/sessions/selectors';

export const getData = ({ taxonomy: { data } = {} } = {}) => data;
const getDepth = state => state.taxonomy.depth;
export const getTaxonomyLevel = state => state.taxonomy.level;
const getRoots = state => state.taxonomy.rootCategories;

const getPathname = (_state, props) => props.location.pathname;
const getSearch = (_state, props) => props.location.search;

export const getIsTaxonomyLoaded = state => state.taxonomy.loaded;
export const getIsTaxonomyLoading = state => state.taxonomy.loading;

export const getFooterData = state => state.taxonomy.footer.data;
export const getIsFooterLoaded = state => !!state.taxonomy.footer.data;
export const getIsFooterLoading = state => state.taxonomy.footer.loading;

export const nameToUrl = (name = '') => categoryNameToUrl(name.toLowerCase());

const findPartCategory = (data, categories, part) =>
  categories.map(id => data[id]).find(category => nameToUrl(category.name) === part);

const parentOf = id => category =>
  category && category.categoryIds && category.categoryIds.includes(id);

const splitCategoryUrl = pathname =>
  pathname
    ? pathname // if pathname exists
        .toLowerCase() // normalise it to lowercase
        .replace(`${env.root}/shop/browse/`, '') // Remove preceding path
        .replace(/\/+$/, '') // Remove any trailing slash
        .split('/') // Break into individual category names
        .filter(segment => segment) // Remove invalid segments
    : [];

const returnId = (_state, id) => id;

const othersCategory = { id: OTHERS, name: 'Others' };

export const getCategoryById = createSelector([getData, returnId], (data = {}, id) => {
  if (OTHERS === id) {
    return othersCategory;
  }
  return { ...data[id], ...(!!data[id] && { hasDescendants: !!data[id]?.categoryIds?.length }) };
});

export const getCategoryNameById = createSelector(getCategoryById, ({ name } = {}) => name);

export const getCategoryUrlSegmentById = createSelector(
  getCategoryById,
  ({ name, url } = {}) => url ?? nameToUrl(name),
);

export const getCategoryBannerById = createSelector(getCategoryById, ({ banner } = {}) => banner);

const getEntertainingCategory = state => getCategoryById(state, ENTERTAINING);
const getGroceriesCategory = state => getCategoryById(state, GROCERIES);

export const getEntertainingCategories = createSelector(
  getEntertainingCategory,
  ({ categoryIds: categories } = {}) => categories,
);

export const getGroceriesCategories = createSelector(
  getGroceriesCategory,
  ({ categoryIds: categories } = {}) => categories,
);

export const getSubcategoriesById = createSelector(
  getCategoryById,
  ({ categoryIds: categories = [] } = {}) => categories,
);

const getLocationCategories = createSelector(
  [getData, getRoots, getPathname, getDepth],
  (data, rootCategories, pathname, depth) => {
    if (depth < FULL_DEPTH) return undefined;

    if (data && rootCategories) {
      const parts = splitCategoryUrl(pathname);
      const rootIds = rootCategories.map(rootCat => rootCat.id);

      const result = parts.reduce(
        (acc, part) => {
          const category = acc.categoryIds && findPartCategory(data, acc.categoryIds, part);

          if (category) {
            acc.categories.push(category);
            acc.categoryIds = category.categoryIds;
          } else {
            acc.categories.push(null);
            acc.categoryIds = null;
          }

          return acc;
        },
        {
          categories: [],
          categoryIds: rootIds,
        },
      );

      return result.categories.includes(null) ? null : result.categories;
    }
    return undefined;
  },
);

const getLocationCategoryId = createSelector(
  [getSearch],
  search => queryString.parse(search).categoryId,
);

const getLocationHighlights = createSelector([getPathname], pathname => {
  const re = new RegExp(`${urls.offers}/(highlights(?:/[^/]*)?)`);
  const match = pathname.match(re);
  return match ? match[1] : '';
});

export const getOffersPathByCategoryId = (categoryId, taxonomy) => {
  let path = [];
  let nextId = categoryId;
  const taxonomyValues = taxonomy ? Object.values(taxonomy) : [];

  while (nextId && nextId !== GROCERIES) {
    const category = taxonomy[nextId];
    path = category ? [`${nameToUrl(category.name)}_offers`, ...path] : path;

    const parent = taxonomyValues.find(parentOf(nextId));
    nextId = parent && parent.id;
  }

  return path.join('/');
};

const getOffersCategoryPath = createSelector(
  [getLocationCategoryId, getData],
  getOffersPathByCategoryId,
);

export const getLocationCategory = createSelector(
  [getLocationCategories],
  categories => categories && categories[categories.length - 1],
);

const getCategoryId = createSelector([getLocationCategory], category => category?.id);

const getCategoryOfferPath = createSelector([getCategoryId, getData], getOffersPathByCategoryId);

export const getCategoryOffer = createSelector(
  [getLocationCategory, getCategoryOfferPath],
  (category, categoryPath) => ({
    isOffer: true,
    name: `${category?.name} Offers`,
    url: pathJoin(urls.offers, categoryPath),
    search: category?.id && category.id !== GROCERIES ? { categoryId: category.id } : {},
  }),
);

export const getLocationSubCategories = createSelector(
  [getLocationCategory, getData, getPathname],
  (category, data, pathname) =>
    category &&
    (category.categoryIds || []).map(id => {
      const subCategory = data[id];
      const urlName = nameToUrl(subCategory.name);
      return {
        ...subCategory,
        url: pathJoin(pathname, urlName),
      };
    }),
);

export const getLocationBreadcrumbs = createSelector([getLocationCategories], categories => {
  if (categories) {
    const crumbs = categories.slice(0, -1).map(category => ({
      name: category.name,
      urlName: nameToUrl(category.name),
    }));
    return crumbs;
  }
  return undefined;
});

export const getCanonicalHref = createSelector([getLocationCategories], categories => {
  const categoryPath = (categories || []).map(category => `/${nameToUrl(category.name)}`).join('');

  return `https://${pathJoin(env.prodHost, urls.browse, categoryPath)}`.toLowerCase();
});

const getSubCategoriesPaths = (taxonomy, category, categoryIds, level) => {
  const subCategories = [];
  const { name: fatherName, id: fatherId, path } = category;

  let categoryRootPath = level === 1 ? `shop/browse/` : null;

  categoryRootPath = `${categoryRootPath || ''}${path || nameToUrl(fatherName)}`;

  categoryIds?.forEach(id => {
    const { name, shortName, url, emptyOfProducts, hiddenInNav } = taxonomy[id];
    subCategories.push({
      name,
      path: url || `${categoryRootPath}/${shortName || nameToUrl(name)}`.toLowerCase(),
      id,
      level,
      parent: level === 1 ? ['10051'] : ['10051', fatherId],
      emptyOfProducts,
      hiddenInNav,
    });
  });
  return subCategories;
};

export const getG1AndG2 = createSelector(
  [getData, getGroceriesCategory],
  (taxonomy = {}, groceries) => {
    const { categoryIds } = groceries;
    const categoriesG1 = getSubCategoriesPaths(taxonomy, groceries, categoryIds, 1);

    if (!categoriesG1) return null;

    const categories = [...categoriesG1];

    categoriesG1?.forEach(category => {
      const { id } = category;
      const groceriesLevel2 = taxonomy[id];
      categories.push(...getSubCategoriesPaths(taxonomy, category, groceriesLevel2.categoryIds, 2));
    });

    const nonEmptyCategories = categories.filter(
      category => !category.emptyOfProducts && !category.hiddenInNav,
    );

    return nonEmptyCategories;
  },
);

export const getMenuCategoryById = createSelector([getCategoryById, getData], (category, data) => {
  const childCategoryIds = category.categoryIds ?? [];

  // Filter out child categories that are empty of products or hidden
  const categoryIds = childCategoryIds.filter(
    id => data[id] && !data[id]?.emptyOfProducts && !data[id]?.hiddenInNav,
  );

  // Decorate with child category objects
  const categories = categoryIds
    .map(id => data[id])
    .reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: {
          ...item,
          hasDescendants: !!item.categoryIds?.length,
        },
      }),
      {},
    );

  return {
    ...category,
    categoryIds,
    categories,
  };
});

export const getMetaDescription = createSelector(
  [getLocationCategories, getLocationCategory],
  (categories, category) => {
    if (!category) {
      return 'Buy online from Waitrose & Partners today and explore our wide range of high quality products';
    }

    if (categories.length > 2) {
      return `See our selection of ${category.name} and buy quality ${
        categories[categories.length - 2].name
      } online at Waitrose. Picked, packed and delivered by hand in convenient 1-hour slots.`;
    }

    return (
      `Visit the ${category.name} section at Waitrose & Partners today to browse ` +
      `our range of the highest quality grocery products available in the UK.`
    );
  },
);

export const getMetaKeywords = createSelector(
  [getLocationCategories, getLocationCategory],
  (categories, category) => {
    if (category) {
      const parentNames = categories
        .slice(0, -1)
        .map(crumb => `${crumb.name}, high quality ${crumb.name}, `)
        .reverse()
        .join('');
      return `${category.name}, ${parentNames}waitrose groceries, waitrose food, waitrose & partners, waitrose and partners, waitrose, uk`;
    }
    return `Groceries, high quality Groceries, waitrose groceries, waitrose food, waitrose & partners, waitrose and partners, waitrose, uk`;
  },
);

export const getOffersCanonicalHref = createSelector(
  [getLocationCategoryId, getOffersCategoryPath, getLocationHighlights],
  (categoryId, categoryPath, highlights) =>
    `https://${pathJoin(env.prodHost, urls.offers, highlights, categoryPath)}${
      categoryId ? `?categoryId=${categoryId}` : ''
    }`,
);

export const getTitle = createSelector([getLocationCategory], category => {
  const categoryTitle = category?.pageTitle ? category?.pageTitle : category?.name;

  return {
    display: true,
    text: categoryTitle ?? 'Groceries',
  };
});

export const getParentCategoryName = createSelector([getLocationBreadcrumbs], crumbs =>
  crumbs && crumbs.length ? crumbs[crumbs.length - 1].name : null,
);

export const getParentCategoryUrl = createSelector([getLocationBreadcrumbs], crumbs =>
  crumbs && crumbs.length
    ? pathJoin('/ecom/shop/browse', ...crumbs.map(crumb => crumb.urlName))
    : null,
);

export const getAccountMenu = createSelector([getData, isUserLoggedIn], (data, authenticated) => {
  const categoryId = authenticated ? AUTHENTICATED_ACCOUNT : UNAUTHENTICATED_ACCOUNT;

  return getCategoryById({ taxonomy: { data } }, categoryId);
});

export const getSiteLinks = state => getCategoryById(state, SITE_LINKS);

export const makeGetCategoryPathFromId = () =>
  createSelector(
    getData,
    (_state, categoryId) => categoryId,
    (taxonomy, categoryId) => {
      const path = [];
      let nextId = categoryId;

      const fallbackToBrowse = !taxonomy || categoryId === GROCERIES || !categoryId;

      if (fallbackToBrowse) return urls.browse;

      const taxonomyValues = Object.values(taxonomy);

      while (nextId && nextId !== GROCERIES) {
        const category = taxonomy[nextId];

        path.unshift(`${nameToUrl(category?.name)}`);

        const parent = taxonomyValues.find(parentOf(nextId));
        nextId = parent && parent.id;
      }

      const relativePath = path.join('/');
      const relativeBrowseUrl = `${urls.browse}/${relativePath}`;

      return relativeBrowseUrl;
    },
  );
