import urls, { cmsUrls } from 'constants/urls';
import { rootPath } from 'utils/root-path';

import type { PreloadOnlyFetcher } from 'route-data/fetcher-types';
import { all } from 'route-data/all';
import { chain } from 'route-data/chain';
import { conditional } from 'route-data/conditional';
import { exceptFromProduct } from 'route-data/except-from-product';
import { lazyFetcher } from 'route-data/lazy-fetcher';
import { isNotEmbeddedInWebView } from 'utils/webview';

const commonClientFetch = lazyFetcher(
  () => import('route-data/common-client-fetch'),
  'commonClientFetch',
);

const accountsFetcher = lazyFetcher(() => import('redux/fetchers/accounts'), 'accountsFetcher');
const accountsPromptsForContactAddressFetcher = lazyFetcher(
  () => import('redux/fetchers/accounts'),
  'accountsPromptsForContactAddressFetcher',
);
const addressByIdFetcher = lazyFetcher(
  () => import('redux/fetchers/address'),
  'addressByIdFetcher',
);
const linkedMembershipFetcher = lazyFetcher(
  () => import('redux/fetchers/linked-membership'),
  'linkedMembershipFetcher',
);
const addressesFetcher = lazyFetcher(() => import('redux/fetchers/address'), 'addressesFetcher');
const allFavouritesFetcher = lazyFetcher(
  () => import('redux/fetchers/favourites-all'),
  'allFavouritesFetcher',
);
const browseFetcher = lazyFetcher(() => import('redux/fetchers/browse'), 'browseFetcher');
const christmasHubLocationsFetcher = lazyFetcher(
  () => import('redux/fetchers/browse'),
  'christmasHubLocationsFetcher',
);
const bundleFetcher = lazyFetcher(() => import('redux/fetchers/bundle'), 'bundleFetcher');
const checkoutExperienceFragmentFetcher = lazyFetcher(
  () => import('redux/fetchers/checkout-experience-fragment-fetcher'),
  'checkoutExperienceFragmentFetcher',
);
const checkoutRouteLoaded = lazyFetcher(
  () => import('redux/fetchers/checkout-route-loaded'),
  'checkoutRouteLoaded',
);
const citrusAdsProductFetcher = lazyFetcher(
  () => import('redux/fetchers/citrusads-buylist-page'),
  'citrusAdsProductFetcher',
);
const cmsExperienceFragment = (
  ...args: Parameters<typeof import('redux/fetchers/experience-fragment').default>
) =>
  lazyFetcher(
    () => import('redux/fetchers/experience-fragment'),
    'default',
    fetcher => fetcher(...args),
  );
const cmsPageFetcher = lazyFetcher(() => import('redux/fetchers/cms-page'));
const cmsRecipeLandingPageFetcher = lazyFetcher(() => import('redux/fetchers/cms-recipes-landing'));
const mealPlannerFetcher = lazyFetcher(() => import('redux/fetchers/meal-planner'));
const mealPlannerFiltersFetcher = lazyFetcher(
  () => import('redux/fetchers/meal-planner'),
  'fetchMealPlannerFilters',
);
const getProductsForRecipesFetcher = lazyFetcher(
  () => import('redux/fetchers/meal-planner'),
  'getProductsForRecipes',
);

const getRecipesForMealPlannerFetcher = lazyFetcher(
  () => import('redux/fetchers/meal-planner'),
  'getRecipesForMealPlanner',
);

const componentDataFetcher = lazyFetcher(
  () => import('redux/fetchers/component-data'),
  'componentDataFetcher',
);
const createAisleFetcher = (
  ...args: Parameters<typeof import('redux/fetchers/custom-aisle').createAisleFetcher>
) =>
  lazyFetcher(
    () => import('redux/fetchers/custom-aisle'),
    'createAisleFetcher',
    fetcher => fetcher(...args),
  );
const customerSlotFetcher = lazyFetcher(
  () => import('redux/fetchers/customer-slot-fetcher'),
  'customerSlotFetcher',
);
const editAdminAddressFetcher = lazyFetcher(
  () => import('redux/fetchers/address'),
  'editAdminAddressFetcher',
);
const favouritesSummaryFetcher = lazyFetcher(
  () => import('redux/fetchers/favourites-summary'),
  'favouritesSummaryFetcher',
);
const fetchCustomerOrderStats = lazyFetcher(
  () => import('redux/modules/orders/actions/fetch-customer-order-stats'),
  'fetchCustomerOrderStats',
);
const footerFetcher = lazyFetcher(() => import('redux/fetchers/footer'), 'footerFetcher');
const incentivesFetcher = (
  ...args: Parameters<typeof import('redux/fetchers/incentives').incentivesFetcher>
) =>
  lazyFetcher(
    () => import('redux/fetchers/incentives'),
    'incentivesFetcher',
    fetcher => fetcher(...args),
  );
const interstitialFetcher = lazyFetcher(
  () => import('redux/fetchers/interstitial'),
  'interstitialFetcher',
);
const interstitialsFetcher = lazyFetcher(
  () => import('redux/fetchers/interstitials'),
  'interstitialsFetcher',
);
const jotterFetcher = lazyFetcher(() => import('redux/fetchers/jotter'), 'jotterFetcher');
const mealDealFetcher = lazyFetcher(() => import('redux/fetchers/meal-deal'), 'mealDealFetcher');
const membershipStatusFetcher = lazyFetcher(
  () => import('redux/fetchers/membership-status'),
  'membershipStatusFetcher',
);
const menuFetcher = lazyFetcher(() => import('redux/fetchers/menu'), 'menuFetcher');
const multiSearchFetcher = lazyFetcher(
  () => import('redux/fetchers/multi-search'),
  'multiSearchFetcher',
);
const multiSearchFetcherLoading = lazyFetcher(
  () => import('redux/fetchers/multi-search-loading'),
  'multiSearchFetcherLoading',
);
const myWaitroseFetcher = lazyFetcher(
  () => import('redux/fetchers/my-waitrose'),
  'myWaitroseFetcher',
);
const navigationFetcher = lazyFetcher(
  () => import('redux/fetchers/navigation'),
  'navigationFetcher',
);
const newJoinerAddressesFetcher = lazyFetcher(
  () => import('redux/fetchers/address'),
  'newJoinerAddressesFetcher',
);
const offersFetcher = lazyFetcher(() => import('redux/fetchers/offers'), 'offersFetcher');
const orderInitialiser = lazyFetcher(() => import('redux/fetchers/order'), 'orderInitialiser');
const orderByURLFetcher = lazyFetcher(() => import('redux/fetchers/order'), 'orderByURLFetcher');
const paymentCardsFetcher = lazyFetcher(
  () => import('redux/fetchers/payment-cards'),
  'paymentCardsFetcher',
);

const paymentConfirmationFetcher = lazyFetcher(
  () => import('redux/fetchers/payment-confirmation'),
  'paymentConfirmationFetcher',
);

const productFetcher = lazyFetcher(() => import('redux/fetchers/product'), 'productFetcher');
const recipeFetcher = lazyFetcher(() => import('redux/fetchers/recipes'), 'recipeFetcher');
const recipeProductFetcher = lazyFetcher(
  () => import('redux/fetchers/recipes'),
  'recipeProductFetcher',
);
const reviewsTokenFetcher = lazyFetcher(
  () => import('redux/fetchers/reviews-token'),
  'reviewsTokenFetcher',
);
const searchFetcher = lazyFetcher(() => import('redux/fetchers/search'), 'searchFetcher');
const shoppingListsFetcher = lazyFetcher(
  () => import('redux/fetchers/shopping-lists'),
  'shoppingListsFetcher',
);
const singleOfferFetcher = lazyFetcher(
  () => import('redux/fetchers/single-offer'),
  'singleOfferFetcher',
);
const trolleyFetcher = lazyFetcher(() => import('redux/fetchers/trolley'), 'trolleyFetcher');

const loggedInCustomerDataFetcher = lazyFetcher(
  () => import('redux/fetchers/logged-in-customer-data'),
);

const cmsPaths = cmsUrls.map(path => ({
  path,
  exact: !path.includes('/:path/*'),
  fetch: lazyFetcher(() => import('components/CmsPage'), 'cmsPageParallelFetcher'),
  noCommonFetch: true,
  noCommonClientFetch: false,
  clientFetch: undefined, // TS seems to shout if this isn't here...
}));

// This is a switch-like table; only the first matching route will be used.
// Each route object can have props 'path', 'exact' and 'strict', as per <Route/>.
//
// If a path matches, the 'fetch' function will be invoked, passing { location, match },
// and the action returned will be dispatched. If a 'clientFetch' fetcher is set, it too will
// be invoked, but only on the client-side.
//
// On the client-side, used by <DataProvider/> and pre-render-actions via oneTimeFetch to fetch data based on location
// On the server-side, used by oneTimeFetch() to fetch data, based on express request object
const paths = [
  ...cmsPaths,
  {
    path: rootPath('/shop/browse/offers/highlights/:offerType/*'),
    fetch: exceptFromProduct(all(offersFetcher, allFavouritesFetcher, navigationFetcher)),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/shop/browse/offers/*'),
    fetch: exceptFromProduct(all(offersFetcher, allFavouritesFetcher, navigationFetcher)),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/shop/browse/groceries/christmas'),
    fetch: exceptFromProduct(
      all(
        chain(menuFetcher, browseFetcher, christmasHubLocationsFetcher, allFavouritesFetcher),
        footerFetcher,
      ),
    ),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/shop/browse/*'),
    fetch: exceptFromProduct(
      all(chain(menuFetcher, browseFetcher, allFavouritesFetcher), footerFetcher),
    ),
    clientFetch: componentDataFetcher,
  },
  { path: rootPath('/shop/featured/entertaining'), exact: true }, // Redirects on render
  { path: rootPath('/shop/featured/groceries'), exact: true }, // Redirects on render
  { path: rootPath('/shop/featured/offers'), exact: true }, // Redirects on render
  {
    path: rootPath('/shop/featured/entertaining/*'),
    fetch: exceptFromProduct(
      all(createAisleFetcher('entertaining'), allFavouritesFetcher, navigationFetcher),
    ),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/shop/featured/groceries/*'),
    fetch: exceptFromProduct(
      all(createAisleFetcher('groceries'), allFavouritesFetcher, navigationFetcher),
    ),
    clientFetch: componentDataFetcher,
  },
  {
    path: `${urls.mealDealPromoPath}/:mealDealId`,
    fetch: exceptFromProduct(all(mealDealFetcher, allFavouritesFetcher, navigationFetcher)),
  },
  {
    path: rootPath('/shop/featured/offers/*'),
    fetch: exceptFromProduct(
      all(createAisleFetcher('offers'), allFavouritesFetcher, navigationFetcher),
    ),
    clientFetch: componentDataFetcher,
  },
  {
    path: `${urls.bundles}/:bundleName`,
    fetch: exceptFromProduct(all(bundleFetcher, allFavouritesFetcher, navigationFetcher)),
  },
  {
    path: rootPath('/shop/multi-search'),
    fetch: all(allFavouritesFetcher, multiSearchFetcherLoading, navigationFetcher),
    clientFetch: multiSearchFetcher,
  },
  {
    path: rootPath('/shop/offers/:offerId'),
    fetch: exceptFromProduct(all(singleOfferFetcher, allFavouritesFetcher, navigationFetcher)),
  },
  {
    path: rootPath('/shopping-lists/:shoppingListName?/:shoppingListId'),
    noCommonFetch: true,
    fetch: lazyFetcher(
      () => import('redux/fetchers/shopping-list-page'),
      'shoppingListPageFetcher',
    ),
  },
  {
    path: rootPath('/shop/search'),
    fetch: exceptFromProduct(all(searchFetcher, allFavouritesFetcher, navigationFetcher)),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/products/:productName/:productId'),
    fetch: all(
      productFetcher,
      shoppingListsFetcher,
      reviewsTokenFetcher,
      allFavouritesFetcher,
      navigationFetcher,
    ),
  },
  {
    path: urls.favourites,
    noCommonFetch: true,
    fetch: lazyFetcher(() => import('redux/fetchers/favourites'), 'favouritesFetcher'),
    // Current experiments on favourites require the client-side Monetate API
    // call to resolve before calling Favourites Experience, so we disable
    // common client-side fetching and manually chain it to the
    // `favouritesProductsFetcher` inside of the `favouritesFetcher` itself.
    noCommonClientFetch: true,
  },
  {
    path: urls.forYou,
    noCommonFetch: true,
    fetch: lazyFetcher(() => import('redux/fetchers/for-you'), 'forYouFetcher'),
  },
  {
    path: urls.lists,
    noCommonFetch: true,
    fetch: lazyFetcher(
      () => import('redux/fetchers/shopping-lists-page'),
      'shoppingListsPageFetcher',
    ),
  },
  {
    path: rootPath('/myaccount/marketingpreferences'),
    fetch: all(membershipStatusFetcher, navigationFetcher),
  },
  {
    path: rootPath('/myaccount'),
    fetch: all(
      accountsPromptsForContactAddressFetcher,
      addressesFetcher,
      membershipStatusFetcher,
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/myaccount/paymentcards'),
    fetch: all(paymentCardsFetcher, navigationFetcher),
  },
  {
    path: rootPath('/my-details'),
    fetch: all(
      accountsFetcher,
      accountsPromptsForContactAddressFetcher,
      addressesFetcher,
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/link-card'),
    fetch: all(newJoinerAddressesFetcher, navigationFetcher, linkedMembershipFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/join'),
    fetch: all(newJoinerAddressesFetcher, membershipStatusFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/customer/address'),
    fetch: all(editAdminAddressFetcher),
  },
  {
    path: rootPath('/beforeYouGo/:interstitialId'),
    fetch: all(interstitialFetcher, navigationFetcher),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/beforeYouGo'),
    fetch: all(interstitialsFetcher, allFavouritesFetcher, navigationFetcher),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/my-waitrose'),
    fetch: exceptFromProduct(
      all(
        addressesFetcher,
        linkedMembershipFetcher,
        myWaitroseFetcher,
        allFavouritesFetcher,
        favouritesSummaryFetcher,
        cmsExperienceFragment('mywaitrose/my-waitrose-benefits/master', {
          applyColumnTransformation: true,
        }),
        cmsExperienceFragment('mywaitrose/my-waitrose-benefits/benefits-summary', {
          applyColumnTransformation: true,
        }),
        cmsExperienceFragment('mywaitrose/competitions/master', {
          applyColumnTransformation: true,
        }),
        cmsExperienceFragment('mywaitrose/vouchers/vouchers-alert/master'),
        navigationFetcher,
      ),
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/become-a-member'),
    fetch: all(
      cmsExperienceFragment('mywaitrose/become-a-member/master', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/card-and-details'),
    fetch: all(
      accountsPromptsForContactAddressFetcher,
      myWaitroseFetcher,
      addressesFetcher,
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/competitions'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/Competitions/competitions/master', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/central-area-frame', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/dry-cleaning'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/dry-cleaning', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/free-tea-or-coffee'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/free-hot-drink', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/caffe-nero'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/caffe-nero', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/vitality'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/vitality', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/waitrose-magazines'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/waitrose-magazines', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/leave-my-waitrose'),
    fetch: all(addressesFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/order-replacement-card/address'),
    fetch: all(addressesFetcher, myWaitroseFetcher, navigationFetcher, linkedMembershipFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/join/welcome'),
    fetch: all(addressesFetcher, membershipStatusFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/link-card/welcome'),
    fetch: all(
      addressesFetcher,
      membershipStatusFetcher,
      navigationFetcher,
      linkedMembershipFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-partnership/card-and-details'),
    fetch: all(accountsFetcher, myWaitroseFetcher, navigationFetcher, linkedMembershipFetcher),
    exact: true,
  },
  {
    path: rootPath('/*/addresses/edit/:addressId'),
    fetch: all(addressByIdFetcher),
  },
  {
    path: rootPath('/checkout'),
    fetch: chain(
      all(
        trolleyFetcher,
        accountsFetcher,
        fetchCustomerOrderStats,
        addressesFetcher,
        orderInitialiser,
        paymentCardsFetcher,
        incentivesFetcher({
          ignoreErrors: true,
          timeout: { handleTimeout: true, deadline: 8000, response: 8000 },
        }),
      ),
      checkoutRouteLoaded,
      checkoutExperienceFragmentFetcher,
      customerSlotFetcher,
      allFavouritesFetcher,
    ),
    exact: true,
    noCommonFetch: true,
    noCommonClientFetch: true,
  },
  {
    path: rootPath('/resolve-order-payment/:customerOrderId'),
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: chain(
      all(
        lazyFetcher(() => import('components/ResolveOrderPayment'), 'resolveOrderPaymentFetcher'),
        addressesFetcher,
        cmsExperienceFragment('buy/checkout/payment-alert/master', {
          numberOfRetries: 1,
          cache: true,
        }),
      ),
      checkoutRouteLoaded,
    ),
  },
  {
    path: rootPath('/payment-confirmation/:customerOrderId'),
    fetch: all(paymentConfirmationFetcher, navigationFetcher),
  },
  {
    path: rootPath('/order-confirmation/:customerOrderId/*'),
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: chain(
      loggedInCustomerDataFetcher,
      all(
        orderByURLFetcher,
        conditional(
          isNotEmbeddedInWebView,
          all(
            // Addresses are not necessary in apps as we don't have any way to get to them directly in the webview
            addressesFetcher,
            // Used for multi-search. Not necessary in apps as they don't have the header
            jotterFetcher,
            cmsExperienceFragment('buy/checkout/charity/master', {
              applyColumnTransformation: true,
            }),
            cmsExperienceFragment('buy/checkout/order-confirmation-ads/master', {
              applyColumnTransformation: true,
            }),
            navigationFetcher,
          ),
        ),
      ),
    ),
  },
  {
    path: urls.trolleyPage,
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: lazyFetcher(() => import('components/Trolley'), 'trolleyWithRecommendationsFetcher'),
    clientFetch: lazyFetcher(() => import('components/Trolley'), 'trolleyClientFetcher'),
  },
  {
    path: rootPath('/recipe/:recipeId'),
    fetch: all(recipeFetcher, recipeProductFetcher, navigationFetcher),
  },
  {
    path: rootPath('/recipes/meal-plans/serves'),
    exact: false,
    fetch: all(mealPlannerFiltersFetcher, navigationFetcher),
  },
  {
    path: rootPath('/recipes/meal-plans/how-to'),
    exact: false,
    fetch: all(mealPlannerFiltersFetcher, navigationFetcher),
  },
  {
    path: rootPath('/recipes/meal-plans/dietary'),
    exact: false,
    fetch: all(mealPlannerFiltersFetcher, navigationFetcher),
  },
  {
    path: rootPath('/recipes/meal-plans/*'),
    exact: false,
    fetch: all(navigationFetcher, chain(mealPlannerFetcher, componentDataFetcher)),
    clientFetch: all(chain(getRecipesForMealPlannerFetcher, getProductsForRecipesFetcher)),
  },
  {
    path: rootPath('/recipes/:recipecollection/*'),
    exact: false,
    fetch: all(chain(cmsRecipeLandingPageFetcher, componentDataFetcher), navigationFetcher),
  },
  {
    path: rootPath('/recipes'),
    exact: true,
    fetch: all(chain(cmsPageFetcher, componentDataFetcher), navigationFetcher),
  },
  {
    path: `${urls.citrusAdsBuylistPage}/:lineNumbers`,
    fetch: all(citrusAdsProductFetcher, allFavouritesFetcher, navigationFetcher),
  },
  {
    path: rootPath('/registration/*'),
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: footerFetcher,
  },
  {
    path: rootPath('/bookslot/collection/entertaining'),
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: lazyFetcher(() => import('components/BookSlot/SlotGrid'), 'fetch'),
    clientFetch: lazyFetcher(() => import('components/BookSlot/SlotGrid'), 'clientFetch'),
  },
  {
    path: rootPath('/bookslot/collection/groceries/*'), // groceries === groceriesandentertaining
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: lazyFetcher(() => import('components/BookSlot/SlotGrid'), 'fetch'),
    clientFetch: lazyFetcher(() => import('components/BookSlot/SlotGrid'), 'clientFetch'),
  },
  {
    path: rootPath('/bookslot/delivery'),
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: lazyFetcher(() => import('components/BookSlot/SlotGrid'), 'fetch'),
    clientFetch: lazyFetcher(() => import('components/BookSlot/SlotGrid'), 'clientFetch'),
  },
  {
    path: rootPath('/bookslot/collection/selectcollectionservice'),
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: lazyFetcher(() => import('components/BookSlot/ServiceSelectionCollection'), 'fetch'),
    clientFetch: lazyFetcher(
      () => import('components/BookSlot/ServiceSelectionCollection'),
      'clientFetch',
    ),
  },
  {
    path: urls.serviceSelection,
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: lazyFetcher(() => import('components/BookSlot/ServiceSelection'), 'fetch'),
    clientFetch: lazyFetcher(() => import('components/BookSlot/ServiceSelection'), 'clientFetch'),
  },
  {
    path: `${urls.forgotPassword}/*`,
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: footerFetcher,
  },
  {
    path: rootPath('/log-out'),
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: footerFetcher,
  },
  {
    path: urls.myOrdersPage,
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: lazyFetcher(() => import('components/MyOrders'), 'ordersFetcher'),
    clientFetch: lazyFetcher(() => import('components/MyOrders'), 'ordersClientFetcher'),
    exact: true,
  },
  {
    path: urls.shopFromPrevious,
    noCommonFetch: true,
    fetch: lazyFetcher(
      () => import('redux/fetchers/shop-from-previous'),
      'shopFromPreviousFetcher',
    ),
  },
  {
    path: urls.shopFromPreviousInterstitial,
    noCommonFetch: true,
    fetch: lazyFetcher(
      () => import('redux/fetchers/shop-from-previous-interstitial'),
      'shopFromPreviousInterstitialFetcher',
    ),
  },
  {
    path: urls.viewOrder,
    noCommonFetch: true,
    noCommonClientFetch: true,
    fetch: lazyFetcher(() => import('components/ViewOrder'), 'viewOrderFetcher'),
    clientFetch: lazyFetcher(() => import('components/ViewOrder'), 'viewOrderClientFetcher'),
  },
  {
    path: '/',
    exact: true,
    noCommonFetch: true,
    fetch: lazyFetcher(() => import('components/HomePage'), 'homepageFetcher'),
  },
  { path: '/*', fetch: navigationFetcher }, // Catch-all
];

// Fetchers here are invoked for every route
const common = {
  createFetcherChain: (fetchers: unknown) =>
    lazyFetcher(
      () => import('route-data/common-fetch'),
      'createFetcherChain',
      createFetcherChain => createFetcherChain({ fetchersAfterLoggedInCustomerData: fetchers }),
    ),
  clientFetch: commonClientFetch,
};

export const routes = paths.map(path => ({
  ...path,
  ...((path.fetch || path.clientFetch) && {
    fetch: path.noCommonFetch ? path.fetch : common.createFetcherChain(path.fetch),
    clientFetch: path.noCommonClientFetch
      ? path.clientFetch
      : all(common.clientFetch, path.clientFetch),
  }),
  preload() {
    if (this.fetch) {
      (this.fetch as PreloadOnlyFetcher)({ preloadOnly: true });
    }
    if (this.clientFetch) {
      (this.clientFetch as PreloadOnlyFetcher)({ preloadOnly: true });
    }
  },
}));
