export const SHOPPING_LISTS_CREATE_FULFILLED =
  'waitrose/shopping-list/SHOPPING_LISTS_CREATE_FULFILLED';
export const SHOPPING_LISTS_CREATE_PENDING = 'waitrose/shopping-list/SHOPPING_LISTS_CREATE_PENDING';
export const SHOPPING_LISTS_CREATE_REJECTED =
  'waitrose/shopping-list/SHOPPING_LISTS_CREATE_REJECTED';
export const SHOPPING_LISTS_FULFILLED = 'waitrose/shopping-list/SHOPPING_LISTS_FULFILLED';
export const SHOPPING_LISTS_HIDE_MODAL = 'waitrose/shopping-lists/SHOPPING_LISTS_HIDE_MODAL';
export const SHOPPING_LISTS_PENDING = 'waitrose/shopping-list/SHOPPING_LISTS_PENDING';
export const SHOPPING_LISTS_REJECTED = 'waitrose/shopping-list/SHOPPING_LISTS_REJECTED';
export const SHOPPING_LISTS_SHOW_MODAL = 'waitrose/shopping-lists/SHOPPING_LISTS_SHOW_MODAL';
export const SHOPPING_LISTS_ACTIONS = [
  SHOPPING_LISTS_PENDING,
  SHOPPING_LISTS_FULFILLED,
  SHOPPING_LISTS_REJECTED,
];
export const SHOPPING_LISTS_CREATE_ACTIONS = [
  SHOPPING_LISTS_CREATE_PENDING,
  SHOPPING_LISTS_CREATE_FULFILLED,
  SHOPPING_LISTS_CREATE_REJECTED,
];
