import { Order } from 'api/definitions/orders';
import { deepFreeze } from 'utils/deepFreeze';

export interface Orders {
  loading: boolean;
  loaded: boolean;
  orders: Order[];
  orderIds: string[];
  start?: number;
  lastFetchedFor?: 'GLP' | 'MY_ORDERS';
}

export type OrdersUserStats =
  | {
      customerHasACompletedOrder?: never;
      customerHasAnOrderRequiringPaymentAttention?: never;
      daysSinceLastCompletedOrder?: never;
      initialLoadComplete: boolean;
    }
  | {
      customerHasACompletedOrder: boolean;
      customerHasAnOrderRequiringPaymentAttention: boolean;
      daysSinceLastCompletedOrder: number;
      initialLoadComplete: boolean;
    };

export interface OrdersState {
  cancellationsInProgress: string[];
  completedOrders: Orders;
  pendingOrders: Orders;
  previousOrders: Orders;
  recentOrders: Orders;
  userStats: OrdersUserStats;
}

export const initialState: Readonly<OrdersState> = deepFreeze({
  cancellationsInProgress: [],
  completedOrders: {
    loading: false,
    loaded: false,
    orderIds: [],
    orders: [],
  },
  pendingOrders: {
    loading: false,
    loaded: false,
    orderIds: [],
    orders: [],
  },
  previousOrders: {
    loading: false,
    loaded: false,
    orderIds: [],
    orders: [],
  },
  recentOrders: {
    loading: false,
    loaded: false,
    orderIds: [],
    orders: [],
  },
  userStats: {
    initialLoadComplete: false,
  },
});
