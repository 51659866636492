import update from 'immutability-helper';
import { deepFreeze } from 'utils/deepFreeze';

import { ADD_ALL_ITEMS_FULFILLED } from 'redux/modules/add-all-items/actions/types';
import { FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED } from 'redux/modules/favourites/actions/types';
import { GET_FAVOURITES } from 'redux/modules/favourites-experience/actions/types';
import { GET_ORDER_PRODUCTS_LOADED } from 'redux/modules/orders/actions/types';
import {
  INVALIDATEPRODUCTS,
  LOADED as SEARCHANDBROWSE_PRODUCTS_LOADED,
  MEAL_DEAL_PRODUCTS_LOADED,
  NEXTLOADED as SEARCHANDBROWSE_PRODUCTS_NEXTLOADED,
  OFFER_PRODUCTS_LOADED,
} from 'redux/modules/search-and-browse/actions/types';
import { GET_MEAL_DEAL } from 'redux/modules/meal-deals/actions/types';
import {
  INTERSTITIALS_FULFILLED,
  INTERSTITIALS_UPDATED,
} from 'redux/modules/interstitials/actions/types';
import {
  ORDER_LOADED,
  REMOVE_ITEM_FROM_TROLLEY,
  REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY,
} from 'redux/modules/trolley/actions/types';
import { PRODUCT_LOADED } from 'redux/modules/product-details/actions/types';
import { SHOPPING_LIST_PRODUCTS_FULFILLED } from 'redux/modules/shopping-list/actions/types';
import { EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS } from 'redux/modules/experience-fragments/actions/types';
import { GET_BUNDLE } from 'redux/modules/bundle/actions/types';

import {
  componentsAndProductsSchema,
  experienceFragmentSchema,
  productsSchema,
} from 'redux/schemas';

import getNormalisedProductConflicts from './get-normalised-product-conflicts';
import getNormalisedProductConflictsFromBundle from './get-normalised-bundle-conflicts';
import getNormalisedTrolleyConflicts from './get-normalised-trolley-conflicts';

const initialState = () =>
  deepFreeze({
    products: {},
    trolley: {},
  });

// TODO: Favourites Experience conflicts case.

export default function reducer(state = initialState(), action = {}) {
  const { result, type } = action;

  switch (type) {
    case FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED:
    case GET_ORDER_PRODUCTS_LOADED:
    case MEAL_DEAL_PRODUCTS_LOADED:
    case OFFER_PRODUCTS_LOADED:
    case PRODUCT_LOADED:
    case SHOPPING_LIST_PRODUCTS_FULFILLED:
    case INTERSTITIALS_FULFILLED:
    case INTERSTITIALS_UPDATED:
    case GET_MEAL_DEAL.success: {
      return {
        ...state,
        products: update(state.products, {
          $merge: getNormalisedProductConflicts(result, productsSchema),
        }),
      };
    }
    case ADD_ALL_ITEMS_FULFILLED: {
      return result
        ? {
            ...state,
            products: update(state.products, {
              $merge: getNormalisedProductConflicts(result.trolley, productsSchema),
            }),
          }
        : state;
    }
    case ORDER_LOADED: {
      const { trolley } = result ?? {};
      return trolley
        ? {
            ...state,
            trolley: getNormalisedTrolleyConflicts(trolley),
          }
        : state;
    }
    case SEARCHANDBROWSE_PRODUCTS_LOADED:
    case SEARCHANDBROWSE_PRODUCTS_NEXTLOADED:
    case GET_FAVOURITES.success: {
      return {
        ...state,
        products: update(state.products, {
          $merge: getNormalisedProductConflicts(result, componentsAndProductsSchema),
        }),
      };
    }
    case INVALIDATEPRODUCTS: {
      return {
        ...state,
        products: {},
      };
    }
    case REMOVE_ITEM_FROM_TROLLEY: {
      const { trolleyItem: { trolleyItemId } = {}, hasHardConflict } = action;
      if (!hasHardConflict) return state;
      return {
        ...state,
        trolley: update(state.trolley, {
          $unset: [trolleyItemId],
        }),
      };
    }
    case REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY: {
      const { trolleyItems = [] } = action;
      const itemIds = trolleyItems.map(({ trolleyItemId }) => trolleyItemId);

      return {
        ...state,
        trolley: update(state.trolley, {
          $unset: itemIds,
        }),
      };
    }
    case GET_BUNDLE.success: {
      return result
        ? {
            ...state,
            products: update(state.products, {
              $merge: getNormalisedProductConflictsFromBundle(result),
            }),
          }
        : state;
    }
    case EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS: {
      return {
        ...state,
        products: update(state.products, {
          $merge: getNormalisedProductConflicts(result, experienceFragmentSchema),
        }),
      };
    }
    default:
      return state;
  }
}
