import React, { memo } from 'react';
import loadable from '@loadable/component';
import { IconProps } from '@johnlewispartnership/wtr-ingredients/foundations/icons/svg-icon';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';

const LoadableIcon = loadable(
  (props: IconProps) =>
    import(`@johnlewispartnership/wtr-ingredients/dist/foundations/icons/icons/${props.name}.js`),
  {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    resolveComponent: (components, props) => components[props.name!],
    cacheKey: props => props.name,
    ssr: false,
  },
);

export const LazyIcon = memo(({ className, name, size }: IconProps) => (
  <SimpleErrorBoundary section={`LazyIcon ${name}`}>
    <LoadableIcon className={className} name={name} size={size} />
  </SimpleErrorBoundary>
));
