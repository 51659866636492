import React, { ReactNode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Modal as IngredientsModal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { Provider as ReduxProvider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import root from 'window-or-global';
import { loadableReady } from '@loadable/component';
import { History } from 'history';
import { WtrStore } from 'redux/create-store';
import { log } from 'utils/error-logging';

const reactRender = (
  element: ReactNode,
  history: History,
  store: WtrStore,
  container: HTMLElement,
) => {
  const isSsr = root.isSSR;

  const reduxWrapper = (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <HistoryRouter history={history}>{element}</HistoryRouter>
      </ReduxProvider>
    </React.StrictMode>
  );

  Modal.setAppElement(container);
  IngredientsModal.setAppElement(container);

  if (isSsr) {
    loadableReady(() =>
      hydrateRoot(container, reduxWrapper, {
        onRecoverableError: (error, errorInfo) => {
          log(error, { extra: errorInfo });
        },
      }),
    );
  } else {
    createRoot(container, {
      onRecoverableError: (error, errorInfo) => {
        log(error, { extra: errorInfo });
      },
    }).render(reduxWrapper);
  }
};

export default reactRender;
