import env from 'env/env';
import isEqual from 'lodash/isEqual';
import { createSelector, lruMemoize } from 'reselect';
import { getProducts, getProductById } from 'redux/modules/entities/selectors/products';
import { getBlendedOptimisticTrolleyItems } from 'redux/modules/trolley/selectors/get-optimistic-items';
import { getCustomerSlotDate } from 'redux/modules/customer-slot/selectors';
import {
  hasMealDealPromotion,
  isMealDeal,
} from 'redux/modules/entities/selectors/promotions/meal-deal';
import { getPromotionPath } from './get-promotion-path';

const EMPTY_ARRAY = [];

export const getPromotions = ({ entities: { promotions } = {} } = {}) => promotions;
export const getPromotionId = (_state, promotionId) => promotionId;
const getPromotionIds = (_state, promotionIds) => promotionIds;

export const getPromotionWithPath = (promotions, id) => {
  if (typeof id === 'object') {
    return undefined;
  }

  const promotion = promotions[id];

  if (promotion) {
    return {
      ...promotion,
      promoPath: getPromotionPath(promotion),
    };
  }

  return undefined;
};

export const makeGetPromotionById = () =>
  createSelector(
    [getPromotions, getPromotionId],
    (promotions = {}, promotionId) => getPromotionWithPath(promotions, promotionId),
    {
      memoize: lruMemoize,
      memoizeOptions: {
        maxSize: 2,
        resultEqualityCheck: isEqual,
      },
    },
  );

export const getPromotionById = makeGetPromotionById();

export const makeGetPromotionsByIds = () =>
  createSelector(
    [getPromotions, getPromotionIds],
    (promotions = {}, promotionIds) =>
      promotionIds
        ?.map(promotionId => getPromotionWithPath(promotions, promotionId))
        .filter(Boolean) ?? EMPTY_ARRAY,
  );
export const getPromotionsByIds = makeGetPromotionsByIds();

export const getPromotionDescription = createSelector(
  [getPromotionById],
  ({ promotionDescription } = {}) => promotionDescription,
);

const getPromotionThreshold = ({ groups = [] } = {}) =>
  Array.isArray(groups) ? groups[0]?.threshold : undefined;

export const getPromotionThresholdById = createSelector([getPromotionById], getPromotionThreshold);

export const getMultibuyPromotionPath = createSelector([getPromotionsByIds], promotions => {
  const multibuyPromotion = promotions?.find(promotion => getPromotionThreshold(promotion) > 1);

  return multibuyPromotion ? `${env.root}${multibuyPromotion.promoPath}` : undefined;
});

export const getPromotionsByProductId = createSelector(
  [getPromotions, getProductById],
  (promotionEntities, productData) => {
    const { promotions = [] } = productData || {};

    return getPromotionsByIds({ entities: { promotions: promotionEntities } }, promotions);
  },
);

const twoDays = 2 * 24 * 60 * 60 * 1000;

export const getIsMealDeal = createSelector([getPromotionById], ({ groups = [] } = {}) => {
  return groups.length > 1;
});

export const getIsTimeOrSlotOutsideOfferPeriod = createSelector(
  [getCustomerSlotDate, getPromotionById],
  (customerSlotDate, promotion) => {
    if (!promotion) {
      return true;
    }

    const { promotionExpiryDate } = promotion;

    if (!promotionExpiryDate) {
      return false;
    }

    const promotionEndDate = new Date(promotionExpiryDate);

    if (customerSlotDate) {
      const slotDate = new Date(customerSlotDate);

      return slotDate > promotionEndDate;
    }

    const todaysDate = new Date();

    return todaysDate > promotionEndDate - twoDays;
  },
);

export const getIsRedPriceProduct = createSelector([getPromotionsByProductId], promotions => {
  const isNotInMultibuyOffer = !!promotions?.find(
    promotion => getPromotionThreshold(promotion) === 1,
  );

  const isNotInMealDeal = !hasMealDealPromotion(promotions);

  return isNotInMultibuyOffer && isNotInMealDeal;
});

const getSortedPromotionProducts = createSelector(
  [getProducts, getPromotionId],
  (products = {}, promotionId) => {
    const filteredProducts = Object.values(products).filter(({ promotions = [] }) =>
      promotions.some(promoId => promoId.toString() === promotionId),
    );

    return filteredProducts.sort(
      (a, b) => a.currentSaleUnitPrice?.price.amount - b.currentSaleUnitPrice?.price.amount,
    );
  },
);

export const getOfferProductsInTrolley = createSelector(
  [getBlendedOptimisticTrolleyItems, getSortedPromotionProducts],
  (blendedTrolleyItems = [], products = []) => {
    const linesMap = [];

    products.forEach(prod => {
      const trolleyItem = blendedTrolleyItems.find(item => item.lineNumber === prod.lineNumber);

      for (let i = 0; i < trolleyItem?.quantity.amount; i += 1) {
        linesMap.push(trolleyItem);
      }
    });

    return linesMap;
  },
);

export const getOfferTrolleyItemsGrouped = createSelector(
  getOfferProductsInTrolley,
  getPromotionThresholdById,
  (trolleyItems = [], threshold) => {
    if (!threshold || threshold < 1) {
      const noThresholdGroups = trolleyItems.map(el => [el]);
      noThresholdGroups.push([null]);

      return noThresholdGroups;
    }

    const groupedTrolleyItems = [];

    while (trolleyItems.length) {
      const group = [];

      for (let i = 0; i < threshold; i += 1) {
        group.push(trolleyItems.shift() || null);
      }

      groupedTrolleyItems.push(group);
    }

    if (
      groupedTrolleyItems.length === 0 ||
      groupedTrolleyItems[groupedTrolleyItems.length - 1].filter(el => !!el).length === threshold
    ) {
      groupedTrolleyItems.push(new Array(threshold).fill(null));
    }

    return groupedTrolleyItems;
  },
);

export const getOfferBuilderItems = createSelector(
  getPromotionThresholdById,
  getIsTimeOrSlotOutsideOfferPeriod,
  getOfferTrolleyItemsGrouped,
  getPromotionById,
  getPromotionId,
  getPromotionDescription,
  (
    threshold,
    isTimeOrSlotOutsideOfferPeriod,
    groupedTrolleyItems,
    promotion,
    groupId,
    promotionDescription,
  ) => {
    if (!groupedTrolleyItems || !promotion) {
      return EMPTY_ARRAY;
    }

    if (!threshold || threshold <= 1) {
      return EMPTY_ARRAY;
    }

    if (isTimeOrSlotOutsideOfferPeriod) {
      return EMPTY_ARRAY;
    }

    return groupedTrolleyItems.map((items, index) => ({
      ...promotion,
      completed: items.filter(el => !!el).length === threshold,
      groups: [
        {
          groupId,
          threshold,
          items,
        },
      ],
      builderId: index,
      savings: promotionDescription,
    }));
  },
);

export const getProductsByPromotionId = createSelector(
  [getProducts, getPromotionId],
  (products = {}, promotionId) => {
    const filteredProducts = Object.values(products).filter(({ promotions = [] }) =>
      promotions?.some(promoId => promoId.toString() === promotionId),
    );

    return filteredProducts;
  },
);

export const getMealDealPromotion = createSelector([getPromotionsByIds], promotions =>
  promotions.find(({ discount, groups }) => isMealDeal(discount, groups)),
);
