import type { PromotionDiscount, PromotionGroup } from 'constants/products';
import { getMealDealPath, isMealDeal } from './meal-deal';
import { isFivePercentWMXOffer } from './is-five-percent-wmx-offer';

export const getPromotionPath = ({
  discount,
  groups,
  hidden,
  promotionId,
  promotionTypeCode,
}: {
  discount: PromotionDiscount;
  groups: PromotionGroup[];
  hidden?: boolean | null;
  promotionId: number;
  promotionTypeCode: string;
}) => {
  if (isMealDeal(discount, groups)) {
    return getMealDealPath(promotionId);
  }

  if (isFivePercentWMXOffer(promotionTypeCode, hidden)) {
    return '/shop/browse/groceries/beer_wine_and_spirits/wine';
  }

  return `/shop/offers/${promotionId}`;
};
