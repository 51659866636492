import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { dataLayer } from 'analytics/data-layer';
import { Filter } from 'services/recipes-landing-service';
import Filters, { FilterTitles, getFiltersFromSearch } from './Filters';
import type { SearchFilter } from './Filters';
import mealPlannerStyles from './MealPlannerGrid/index.scss';
import styles from './Preferences.scss';

interface PreferencesModal {
  isOpen: boolean;
  onClose: () => void;
  filters: Filter[];
  onFilterChange: (filters: SearchFilter[]) => void;
}

type CheckedState = boolean[][];

const PreferencesModal = ({ isOpen, onClose, filters, onFilterChange }: PreferencesModal) => {
  const location = useLocation();
  const [filtersCheckedState, setFiltersCheckedState] = React.useState<CheckedState>(
    Array.from({ length: filters.length }, (_, i) =>
      new Array(filters[i].values.length).fill(false),
    ),
  );

  useEffect(() => {
    const activeFilters = getFiltersFromSearch(new URLSearchParams(location.search)).reduce<
      Record<string, string[]>
    >((acc, v) => {
      acc[v.title] = v.values;
      return acc;
    }, {});

    const initialChecked = filters.reduce<CheckedState>(
      (acc, filter, i) => {
        acc[i] = new Array(filter.values.length).fill(false);
        if (activeFilters[filter.title]) {
          filter.values.forEach((v, j) => {
            if (activeFilters[filter.title].includes(v.name)) {
              acc[i][j] = true;
            }
          });
        }
        return acc;
      },
      Array.from({ length: filters.length }, () => []),
    );

    setFiltersCheckedState(initialChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (searchFilters: SearchFilter[]) => {
    onFilterChange(searchFilters);

    const filterSelections = FilterTitles.reduce<Record<string, string>>(
      (acc, title) => {
        const filterGroup = searchFilters.find(f => f.title === title);
        acc[title.replace(/ /g, '_').toLowerCase()] = filterGroup
          ? filterGroup.values.join('|')
          : '';
        return acc;
      },
      { event: 'meal_planner_filter' },
    );

    dataLayer.push(filterSelections);
  };

  return (
    <Modal
      titleText="Filters"
      isOpen={isOpen}
      handleModalClose={onClose}
      className={mealPlannerStyles.modal}
      contentClassName={styles.content}
      buttons={[
        /*
        {
          buttonText: 'Clear all',
          theme: 'secondary',
          onClick: () => {
            navigate({ pathname: location.pathname, search: '' }, { replace: true });
            onChange(new URLSearchParams(''));
          },
        },
        */
        { buttonText: 'Apply filters', onClick: onClose },
      ]}
    >
      <Filters
        filters={filters}
        onChange={onChange}
        filtersCheckedState={filtersCheckedState}
        setFiltersCheckedState={setFiltersCheckedState}
      />
    </Modal>
  );
};

export default PreferencesModal;
