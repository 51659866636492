// This module exposes a very thin proxy object to be used in place of the 'window.location'
// property, primarily so that navigation may stubbed in unit tests, since window.location
// itself cannot be redefined / stubbed in real browsers.
//
// The locator object exported here currently supports a minimal subset of
// window.location properties.  In particular you cannot assign or .assign directly
// to locator as you would to location, but should instead assign to the locator.href
// property.
import root from 'window-or-global';
import { consoleInfo } from 'utils/logging';
import { asyncLocalStorage } from 'server/utils/async-local-storage';
import env from 'env/env';

const server = {};

if (__SERVER__) {
  server.getRequestUrl = () => {
    const requestContext = asyncLocalStorage.getStore();
    return env.clientHost + requestContext.get('originalUrl');
  };
  server.getRequestPathname = () => {
    const requestContext = asyncLocalStorage.getStore();
    const originalUrl = requestContext.get('originalUrl');
    return originalUrl ? originalUrl.split('?')[0] : ``;
  };
  server.getRequestProtocol = () => {
    const requestContext = asyncLocalStorage.getStore();
    return requestContext.get('protocol');
  };
  server.getRequestNavigator = () => {
    const requestContext = asyncLocalStorage.getStore();
    return requestContext.get('window');
  };
  server.getRequestSearch = () => {
    const requestContext = asyncLocalStorage.getStore();
    const originalUrl = requestContext.get('originalUrl');
    if (originalUrl && originalUrl.indexOf('?') > -1) {
      return `?${originalUrl.split('?')[1]}`;
    }
    return '';
  };
  server.setHref = href => {
    const requestContext = asyncLocalStorage.getStore();
    return requestContext.set('locatorHref', href);
  };
}

const locatorClient = {
  get href() {
    return root.location.href;
  },
  set href(url) {
    root.location.href = url;
  },
  get origin() {
    return root.location.origin;
  },
  get pathname() {
    return root.location.pathname;
  },
  get hostname() {
    return root.location.hostname;
  },
  get protocol() {
    return root.location.protocol;
  },
  get navigator() {
    return root.window.navigator;
  },
  reload: () => root.location.reload(),
  replace: url => root.location.replace(url),
  get search() {
    return root.location.search;
  },
  originValid() {
    return root && root.location && root.location.origin;
  },
  get hash() {
    return root.location.hash;
  },
};

const locatorServer = {
  get href() {
    return server.getRequestUrl();
  },
  set href(url) {
    server.setHref(url);
  },
  get origin() {
    throw new Error('locator.origin not implemented on server!');
  },
  get pathname() {
    return server.getRequestPathname();
  },
  get hostname() {
    return '';
  },
  get protocol() {
    return server.getRequestProtocol();
  },
  get navigator() {
    return server.getRequestNavigator();
  },
  reload: () => {
    consoleInfo('ignoring locator.reload on server');
  },
  replace: url => {
    server.setHref(url);
  },
  get search() {
    return server.getRequestSearch();
  },
  originValid() {
    return false;
  },
  get hash() {
    return '';
  },
};

export default __SERVER__ ? locatorServer : locatorClient;
