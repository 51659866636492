export const suppressReact18PropTypeWarnings = () => {
  // eslint-disable-next-line no-console
  const originalConsoleError = console.error;

  // eslint-disable-next-line no-console
  console.error = (...args) => {
    if (
      typeof args[0] === 'string' &&
      /Warning: .*: Support for defaultProps will be removed from .* components in a future major release. Use JavaScript default parameters instead./.test(
        args[0],
      )
    ) {
      return;
    }

    originalConsoleError(...args);
  };
};
