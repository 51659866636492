import React, { memo } from 'react';
import { useWtrSelector } from 'redux/hooks';
import { getProductPromotions } from 'redux/modules/product-details/selectors/product-pricing';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { isEntertainingOrSimilar } from 'constants/productTypes';

import PromotionElement from 'components/Product/Promotion';

import styles from './index.scss';

const ProductPodPromotions = ({
  productId,
  productType,
  qualifier,
  reportAnalyticsClick,
  searchType,
}) => {
  const promotions = useWtrSelector(state => getProductPromotions(state, productId), isEqual);

  if (promotions.length < 1) return null;

  return promotions.map(promotionId => (
    <div
      data-testid="product-pod-promotion"
      className={classNames(styles.promotion, {
        [styles.entertaining]: isEntertainingOrSimilar(productType),
      })}
      key={`${productId}_${promotionId}`}
      onClickCapture={reportAnalyticsClick}
    >
      <PromotionElement
        {...{
          productId,
          promotionId,
          searchType,
        }}
        qualifier={qualifier}
        showIcon={false}
        showMyWaitrose
        miniPromo
      />
    </div>
  ));
};

ProductPodPromotions.displayName = 'ProductPodPromotions';

ProductPodPromotions.propTypes = {
  productId: PropTypes.string.isRequired,
  productType: PropTypes.string,
  qualifier: PropTypes.string,
  reportAnalyticsClick: PropTypes.func.isRequired,
  searchType: PropTypes.string,
};

ProductPodPromotions.defaultProps = {
  productType: null,
  qualifier: '',
  searchType: '',
};

export default memo(ProductPodPromotions);
