import urls from 'constants/urls';
import { Promotion, PromotionDiscount, PromotionGroup } from 'constants/products';
import { strategicIdToPreservedTacticalIdRedTextMap } from 'redux/modules/meal-deals/selectors/meal-deal-data';

export const isMealDeal = (discount: PromotionDiscount, groups?: PromotionGroup[] | null) =>
  discount?.type === 'TOTAL_PRICE' && (groups ?? []).length > 1;

export const hasMealDealPromotion = (promotions: Promotion[]) =>
  promotions.some(promotion => {
    const { discount, groups } = promotion;

    return isMealDeal(discount, groups);
  });

export const getMealDealPath = (promotionId: number) =>
  urls.mealDealPromoPath
    .concat(`/${strategicIdToPreservedTacticalIdRedTextMap[promotionId.toString()] ?? promotionId}`)
    .slice(5);
