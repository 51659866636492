import { isUserLoggedIn } from 'redux/modules/sessions/selectors';
import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import currentUrlSkipped from 'utils/currentUrlSkipped';

import { getTrolley } from 'redux/modules/trolley/actions/get-trolley';
import getOrderSlotType from 'redux/modules/order/actions/get-order-slot-type';
import { getCustomerSlot } from 'redux/modules/customer-slot/actions/get-customer-slot';
import { getMembershipStatus } from 'redux/modules/marketing-preferences/actions/get-membership-status';
import { getCustomerAccount } from 'redux/modules/accounts/actions/get-customer-account';
import { fetchCustomerOrderStats } from 'redux/modules/orders/actions/fetch-customer-order-stats';

/**
 *************************************************************************************
 * IMPORTANT - READ ME
 *************************************************************************************
 *
 * Comments have been applied against each api request here in an attempt to establish the "common"
 * areas the data is used and what should be considered in the least if changes are made. Further analysis
 * should be performed before changing any logic here for any page in case any other uses have been missed.
 * While edits to the file should be treated with caution, these all currently block initial client rendering
 * of the app, so there are good potential performance gains to be had from the activity.
 *
 * Page specific use cases are not called out.
 */
export const getCustomerDataIfLoggedIn = (dispatch, getState) => {
  if (isUserLoggedIn(getState()) && !currentUrlSkipped()) {
    return Promise.all([
      // Updates the trolley totals for the trolley button/summary in the header. Also sets the trolley
      // state for any product pods displayed on the page. If this request no longer
      // blocks initial rendering, then any implications on CLS and user experience in general should be
      // considered as the site header button will show a "logged out" view initially, and product pods
      // will be rendered without the correct trolley quantities.
      dispatch(getTrolley({ initialLoad: true })).then(() => {
        return amendingOrder(getState()) ? dispatch(getOrderSlotType()) : Promise.resolve();
      }),

      // Gets the slot data for the slot booking button in the header. If a slot is not booked, the header
      // does not change. If one is booked, it updates with the details of the slot booking. Similarly to the
      // trolley api call, if changing the non blocking status of this api request, consider impact on
      // CLS and user experience.
      dispatch(getCustomerSlot()),

      // Used to ascertain my waitrose membership for a dataLayer event in src/redux/modules/login/customer-datalayer-event.js
      // Not known to power any general "critical" pieces of UI currently, although in the least, moving this would
      // need to be done in conjunction with the aforementioned dataLayer push.
      dispatch(getMembershipStatus()),

      // No deep analysis performed on use of customer account data from here, although addition to this file is tracked back to
      // addition for Impact affiliate analytics
      // https://gitlab.com/JohnLewisPartnership/WTReCom/frontend/waitrose-website-monolith-logical-service/wtr-website/-/commit/3ef09c0e8901567599cccdcfd5d959ca732d0bd6
      dispatch(getCustomerAccount()),

      // Initial analysis links this api call to the same dataLayer push call as the my waitrose data
      // https://gitlab.com/JohnLewisPartnership/WTReCom/frontend/waitrose-website-monolith-logical-service/wtr-website/-/commit/565b7c6fd847db7e092d96ea45e138ae952b056d
      dispatch(fetchCustomerOrderStats()),
    ]);
  }
  return Promise.resolve(true);
};
