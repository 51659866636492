import React from 'react';
import loadable from '@loadable/component';

// These components are used almost everywhere so it makes sense to load in the main bundle
import {
  ColumnLayout,
  Container,
  ContentFragment,
  Image,
  ResponsiveGrid,
  RichText,
  resourceTypes,
  analyticsNames,
} from '@johnlewispartnership/wtr-content-component-library';

import Skeleton from 'components/Skeleton';
import TradingCell from './components/TradingCellWrapper';

import withContainerReplacements from './components/withContainerReplacements';

const {
  ACCORDION,
  AMEND_ORDER_WIDGET,
  CALLTOACTION_BUTTON,
  CALLTOACTION_SLIDE,
  CLICK_TO_BUY,
  COLUMN_LAYOUT,
  COMPETITIONS_FORM,
  CONTACT_US_CONTENT_FRAGMENT,
  CONTAINER,
  CONTENT_FRAGMENT,
  CUSTOMER_FEEDBACK,
  DIVIDER,
  EXTENDED_NAVIGATION_CARD,
  EXTENDED_NAVIGATION_ROW,
  FORGOTTEN_FAVOURITES,
  FORM,
  FULLWIDTH_NAVIGATION,
  GENERIC_NAVIGATION,
  HORIZONTAL_LINE,
  IMAGE,
  MISSED_OFFERS,
  NAV_LINKS,
  PAGE_TITLE,
  PRODUCT_CAROUSEL,
  RECIPE_CARDS_GRID,
  RECIPE_SEARCHBAR,
  RESPONSIVE_GRID,
  RICH_TEXT,
  SECTION_TITLE,
  SHOP_FROM_RECENT_ORDER,
  SHOP_WINDOWS,
  TRADING_CELL,
  VIDEO,
  VISUAL_NAVIGATION,
  VISUAL_NAVIGATION_ROW,
  CARD_CONTAINER,
  EXPERIENCE_FRAGMENT_REFERENCE,
  EXPERIENCE_FRAGMENT_EDITABLE_RESPONSIVE_GRID,
  EDITABLE_RESPONSIVE_GRID,
} = resourceTypes;

const {
  CALLTOACTION_BUTTON_NAME,
  RICH_TEXT_NAME,
  CLICK_TO_BUY_NAME,
  CONTACT_US_NAME,
  CONTAINER_NAME,
  CONTENT_FRAGMENT_NAME,
  EXTENDED_NAVIGATION_ROW_NAME,
  EXTENDED_NAVIGATION_CARD_NAME,
  FORM_NAME,
  GENERIC_NAVIGATION_NAME,
  FULLWIDTH_NAVIGATION_NAME,
  HORIZONTAL_LINE_NAME,
  IMAGE_NAME,
  SHOP_WINDOWS_NAME,
  TRADING_CELL_NAME,
  VIDEO_NAME,
  VISUAL_NAVIGATION_NAME,
} = analyticsNames;

const Accordion = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/Accordion'
    ),
);
const CallToActionButton = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/CallToActionButton'
    ),
);
const Carousel = loadable(() => import('components/RecommendedCarousel/Wrapper'));
const CarouselSlide = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/CarouselSlide'
    ),
);
const CategoryLinks = loadable(() => import('content/components/CategoryLinks'));
const ClickToBuy = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/ClickToBuy'
    ),
);
const CompetitionsForms = loadable(() => import('./components/CompetitionsFormWrapper'));
const ContactUsCard = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/ContactUsCard'
    ),
);
const CustomerFeedback = loadable(() => import('./components/CustomerFeedbackWrapper'));
const CustomerServiceForms = loadable(
  () => import('components/CustomerServiceForms/CustomerServiceForms'),
);
const Divider = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/Divider'
    ),
);
const ExtendedNavigationCard = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/ExtendedNavigationCard'
    ),
);
const ForgottenFavourites = loadable(() => import('components/ForgottenFavourites'));
const FullWidthNavigation = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/FullWidthNavigation'
    ),
);
const FullWidthRow = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/FullWidthRow'
    ),
);
const HorizontalLine = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/HorizontalLine'
    ),
);
const MissedOffers = loadable(() => import('components/MissedOffers'));
const NextOrder = loadable(() => import('components/NextOrder'));
const NavLinks = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/NavLinks'
    ),
  {
    fallback: <Skeleton height="56px" />,
  },
);
const PageTitle = loadable(() => import('content/components/PageTitle'));
const RecipeCardsGrid = loadable(() => import('content/components/RecipeCardsGrid'), {
  fallback: <Skeleton height="100vh" />,
});
const RecipeSearchBar = loadable(() => import('components/Recipes/Content/RecipeSearchBar/aem'), {
  fallback: <Skeleton height="105px" />,
});
const SectionTitle = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/SectionTitle'
    ),
);
const ShopFromRecentOrder = loadable(() => import('components/ShopFromRecentOrder'));
const ShopWindows = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/ShopWindows'
    ),
);
const Video = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/Video'
    ),
);
const VisualNavigation = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/VisualNavigation'
    ),
);

const CardContainer = loadable(
  () =>
    import(
      '@johnlewispartnership/wtr-content-component-library/dist/component-library/components/CardContainer'
    ),
);

export type CmsComponentMapProps = {
  component: any;
  // A name used for when analytics data-attributes should be added to the component
  analyticsName?: string;
};

const cmsComponentMap = new Map<resourceTypes.CmsResourceTypes, CmsComponentMapProps>([
  [ACCORDION, { component: Accordion }],
  [AMEND_ORDER_WIDGET, { component: NextOrder }],
  [COLUMN_LAYOUT, { component: ColumnLayout }],
  [CALLTOACTION_BUTTON, { component: CallToActionButton, analyticsName: CALLTOACTION_BUTTON_NAME }],
  [CALLTOACTION_SLIDE, { component: CarouselSlide }],
  [CLICK_TO_BUY, { component: ClickToBuy, analyticsName: CLICK_TO_BUY_NAME }],
  [CONTACT_US_CONTENT_FRAGMENT, { component: ContactUsCard, analyticsName: CONTACT_US_NAME }],
  [CONTAINER, { component: withContainerReplacements(Container), analyticsName: CONTAINER_NAME }],
  [CONTENT_FRAGMENT, { component: ContentFragment, analyticsName: CONTENT_FRAGMENT_NAME }],
  [COMPETITIONS_FORM, { component: CompetitionsForms }],
  [CUSTOMER_FEEDBACK, { component: CustomerFeedback }],
  [DIVIDER, { component: Divider }],
  [
    EXTENDED_NAVIGATION_CARD,
    { component: ExtendedNavigationCard, analyticsName: EXTENDED_NAVIGATION_CARD_NAME },
  ],
  [
    EXTENDED_NAVIGATION_ROW,
    { component: FullWidthRow, analyticsName: EXTENDED_NAVIGATION_ROW_NAME },
  ],
  [FORGOTTEN_FAVOURITES, { component: ForgottenFavourites }],
  [FORM, { component: CustomerServiceForms, analyticsName: FORM_NAME }],
  [
    FULLWIDTH_NAVIGATION,
    { component: FullWidthNavigation, analyticsName: FULLWIDTH_NAVIGATION_NAME },
  ],
  [GENERIC_NAVIGATION, { component: CategoryLinks, analyticsName: GENERIC_NAVIGATION_NAME }],
  [HORIZONTAL_LINE, { component: HorizontalLine, analyticsName: HORIZONTAL_LINE_NAME }],
  [IMAGE, { component: Image, analyticsName: IMAGE_NAME }],
  [MISSED_OFFERS, { component: MissedOffers }],
  [NAV_LINKS, { component: NavLinks }],
  [PAGE_TITLE, { component: PageTitle }],
  [PRODUCT_CAROUSEL, { component: Carousel }],
  [RECIPE_CARDS_GRID, { component: RecipeCardsGrid }],
  [RECIPE_SEARCHBAR, { component: RecipeSearchBar }],
  [RESPONSIVE_GRID, { component: ResponsiveGrid }],
  [RICH_TEXT, { component: RichText, analyticsName: RICH_TEXT_NAME }],
  [SECTION_TITLE, { component: SectionTitle }],
  [SHOP_FROM_RECENT_ORDER, { component: ShopFromRecentOrder }],
  [SHOP_WINDOWS, { component: ShopWindows, analyticsName: SHOP_WINDOWS_NAME }],
  [TRADING_CELL, { component: TradingCell, analyticsName: TRADING_CELL_NAME }],
  [VIDEO, { component: Video, analyticsName: VIDEO_NAME }],
  [VISUAL_NAVIGATION, { component: VisualNavigation, analyticsName: VISUAL_NAVIGATION_NAME }],
  [VISUAL_NAVIGATION_ROW, { component: FullWidthRow, analyticsName: EXTENDED_NAVIGATION_ROW_NAME }],
  [CARD_CONTAINER, { component: CardContainer }],
  [EXPERIENCE_FRAGMENT_REFERENCE, { component: ResponsiveGrid }],
  [EXPERIENCE_FRAGMENT_EDITABLE_RESPONSIVE_GRID, { component: ResponsiveGrid }],
  [EDITABLE_RESPONSIVE_GRID, { component: ResponsiveGrid }],
]);

export default cmsComponentMap;
