export const FAVOURITES_SUMMARY_PENDING = 'waitrose/favourites/FAVOURITES_SUMMARY_PENDING';
export const FAVOURITES_SUMMARY_FULFILLED = 'waitrose/favourites/FAVOURITES_SUMMARY_FULFILLED';
export const FAVOURITES_SUMMARY_REJECTED = 'waitrose/favourites/FAVOURITES_SUMMARY_REJECTED';

export const FORGOTTEN_FAVOURITES_PRODUCTS_PENDING =
  'waitrose/favourites/FORGOTTEN_FAVOURITES_PRODUCTS_PENDING';
export const FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED =
  'waitrose/favourites/FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED';
export const FORGOTTEN_FAVOURITES_PRODUCTS_REJECTED =
  'waitrose/favourites/FORGOTTEN_FAVOURITES_PRODUCTS_REJECTED';

export const FAVOURITES_SUMMARY_ACTIONS = [
  FAVOURITES_SUMMARY_PENDING,
  FAVOURITES_SUMMARY_FULFILLED,
  FAVOURITES_SUMMARY_REJECTED,
];

export const FORGOTTEN_FAVOURITES_PRODUCTS_ACTIONS = [
  FORGOTTEN_FAVOURITES_PRODUCTS_PENDING,
  FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED,
  FORGOTTEN_FAVOURITES_PRODUCTS_REJECTED,
];
