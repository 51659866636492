import createReducer from 'redux/createReducer';
import { GET_DELIVERY_STATUS } from 'redux/modules/orders/actions/types';
import { DeliveryStatusResponse } from 'api/definitions/delivery-tracking';
import { ApiError } from 'constants/errors';
import initialState, { DeliveryTrackingState } from './initial-state';

interface Action {
  orderId: string;
}

export const getDeliveryStatusLoading: ApiRequestReducer<DeliveryTrackingState, Action> = (
  state,
  action,
) => {
  return {
    ...state,
    [action.orderId]: {
      loading: true,
      loaded: false,
      error: false,
    },
  };
};

export const getDeliveryStatusLoaded: ApiSuccessReducer<
  DeliveryTrackingState,
  Action,
  DeliveryStatusResponse
> = (state, action) => {
  const { deliveryStatuses, ...rest } = state;

  return {
    ...rest,
    deliveryStatuses: {
      ...deliveryStatuses,
      [action.payload.customerOrderId]: {
        ...action.payload,
        loaded: true,
        loading: false,
        error: false,
      },
    },
  };
};

export const getDeliveryStatusFailed: ApiErrorReducer<DeliveryTrackingState, Action, ApiError> = (
  state,
  action,
) => {
  const { deliveryStatuses, ...rest } = state;

  return {
    ...rest,
    deliveryStatuses: {
      ...deliveryStatuses,
      [action.orderId]: {
        ...deliveryStatuses[action.orderId],
        loaded: true,
        loading: false,
        error: true,
      },
    },
  };
};

const actionTypeReducerMap = [
  [GET_DELIVERY_STATUS.request, getDeliveryStatusLoading],
  [GET_DELIVERY_STATUS.success, getDeliveryStatusLoaded],
  [GET_DELIVERY_STATUS.failure, getDeliveryStatusFailed],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;

export default createReducer(initialState, actionTypeReducerMap);
