import round from 'lodash/round';
import { C62 } from 'constants/weightOptions';
import { isDefined } from 'utils/validation';
import concatenatePromotionValues from 'redux/middleware/analytics/actions-to-track/utilities/concatenate-promotion-values';
import { getProductByLineNumber } from 'redux/modules/entities/selectors/products';
import { getIsFavourite } from 'redux/modules/favourites-products/selectors';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import type { RecipeProductsType } from 'api/definitions/recipes';
import type { RecipesRoot } from 'redux/modules/recipes/reducers';
import { getRecipe } from 'redux/modules/recipes/selectors';
import { getAnalyticsTrolleyItems } from 'redux/modules/trolley/selectors/get-analytics-trolley-items';
import { ADD_TO_TROLLEY_DESCRIPTION } from 'redux/middleware/analytics/actions-to-track/trolley/constants';
import { isSponsoredProduct } from 'redux/modules/search-and-browse/selectors';
import { getOfferBuilderItems } from 'redux/modules/entities/selectors/promotions';
import { SEARCH_TYPE as MEAL_DEALS_SEARCH_TYPE } from 'components/MealDealPage/constants';
import { SEARCH_TYPE as OFFER_DETAILS_SEARCH_TYPE } from 'components/Offers/OfferDetails/constants';
import { offerBuilderDetails } from 'redux/middleware/analytics/actions-to-track/trolley/utils/offer-builder-details';
import {
  getMealDealGroupCompletionStatus,
  getMealDealGroups,
  getMealDealId,
  getTotalCompletedMealDeals,
} from 'redux/modules/meal-deals/selectors';
import getCompleteMealDealEvent from 'redux/middleware/analytics/actions-to-track/trolley/utils/complete-meal-deal';
import type {
  ChangeCartGTMEvent,
  Payload,
} from 'redux/middleware/analytics/actions-to-track/trolley/types';

const yesOrNo = (expression: boolean) => (expression ? 'YES' : 'NO');

const noPromo = { type: undefined, name: undefined };
const noPersonalisation = { type: undefined, name: undefined };

const undefinedAttributes = () => ({
  brand: undefined,
  category: undefined,
  favourite: undefined,
  id: undefined,
  name: undefined,
  offer: undefined,
  price: undefined,
  quantity: undefined,
  variant: undefined,
});

const getMealDealAnalytics = (state: WtrState, payload: Payload, method: string) => {
  const { lineNumber, searchType } = payload[0].analytics;

  if (searchType !== MEAL_DEALS_SEARCH_TYPE) {
    return {};
  }

  const gtmEventCompleteMealDealBuilder =
    method === 'add' ? getCompleteMealDealEvent(state, lineNumber) : undefined;

  const id = getMealDealId(state);
  const groups = getMealDealGroups(state);

  // `searchType` check guarantees we're on a meal deal page, and groups are
  // assured given a product has been added or removed.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const group = groups.find(({ lineNumbers }) => lineNumbers.includes(lineNumber))!;

  const mealDeal = {
    id,
    line_id: `${group.name} (ID: ${group.id})`,
    total_complete: getTotalCompletedMealDeals(state),
    incomplete_status: getMealDealGroupCompletionStatus(state),
  };

  return { mealDeal, gtmEventCompleteMealDealBuilder };
};

const getOfferBuilderAnalytics = (state: WtrState, payload: Payload, method: string) => {
  const { searchType, lineNumber } = payload[0].analytics;

  if (searchType !== OFFER_DETAILS_SEARCH_TYPE) {
    return {};
  }

  // @ts-expect-error entities state is not typed
  const product = getProductByLineNumber(state, lineNumber);
  const promotionId = product?.promotion?.promotionId;

  if (!promotionId) {
    return {};
  }

  // @ts-expect-error entities state is not typed
  const builders = getOfferBuilderItems(state, `${promotionId}`);

  const { offerId, totalComplete, completedStatus, completionStatus, offerType } =
    offerBuilderDetails({ builders }) || {};

  if (!offerId) {
    return {};
  }

  const buildYourOffer = {
    id: offerId,
    total_complete: totalComplete,
    completion_status: completedStatus ? 'complete' : 'incomplete',
    offer_type: offerType,
  };

  const gtmEventCompleteOfferBuilder = completionStatus
    ? {
        event: 'complete_build_your_offer',
        build_your_offer: {
          id: offerId,
          offer_type: offerType,
        },
        method:
          method === 'add' ? 'offer completed by adding item' : 'offer completed by removing item',
      }
    : null;

  return { buildYourOffer, gtmEventCompleteOfferBuilder };
};

/**
 * Used by all trolley transforms so that the output event data shares the same schema,
 * differing only in the event type and the ecommerce method key (`add` or `remove`),
 * with `click_source: "add_all"` being included at the root of the object for the
 * "Add all X items to trolley" feature.
 *
 * Example object to be pushed to GTM data layer with the `add` method:
 *
 * ```json
 * {
 *   event: 'add_to_cart',
 *   ecommerce: {
 *     currencyCode: 'GBP',
 *    add: {
 *      products: [{
 *        'name': 'product name',
 *        'id': '174535',
 *        'price': '1.23',
 *        'quantity': 123
 *        'brand': undefined,
 *        'category': undefined,
 *        'position': undefined,
 *         'favourite': undefined,
 *         'offer': undefined,
 *       }]
 *     }
 *   }
 * }
 * ```
 *
 * Any property with `undefined` value represents information that (at the moment)
 * cannot be extracted from the store as not being provided by the back-end.
 */
const changeCartTransform = (state: WtrState, payload: Payload, method: string, event: string) => {
  let currencyCode: string | undefined;

  const orderId = getCustomerOrderId(state);

  const productInfos = payload.map(({ analytics }) => {
    const unavailableAttributes = undefinedAttributes();
    const { lineNumber, uom, productPosition, searchType, ingredientType } = analytics;

    const sponsored = isSponsoredProduct(state, lineNumber);

    const isRecipePage = searchType === 'recipe';
    const recipe = isRecipePage
      ? (getRecipe(state as RecipesRoot) as unknown as RecipeProductsType)
      : null;

    const isWeighted = uom !== C62;

    const {
      prevPrice: priceBefore = 0,
      prevQuantity: quantityBefore = 0,
      price: priceAfter = 0,
      quantity: quantityAfter = 0,
    } = getAnalyticsTrolleyItems(state, lineNumber) ?? {};

    const priceDelta = Math.abs(priceAfter - priceBefore);
    const quantityDelta = Math.abs(quantityAfter - quantityBefore);

    const quantity = isWeighted ? 1 : quantityDelta;
    const price = quantity && round(priceDelta / quantity, 2);
    // @ts-expect-error entities state is not typed
    const product = getProductByLineNumber(state, lineNumber);

    let availableAttributes: Record<string, unknown> = {
      price,
      quantity,
    };

    if (product) {
      const {
        sponsorshipId,
        brand,
        brandName,
        categories = [],
        currentSaleUnitPrice: { price: { currencyCode: productCurrencyCode = 'GBP' } = {} } = {},
        name,
        productType,
        promotions,
      } = product;

      let sponsoredSource = 'unknown';

      if (sponsored) {
        sponsoredSource = sponsorshipId ? 'citrus_ad' : 'aem';
      }

      currencyCode = productCurrencyCode;
      // @ts-expect-error favourites-products state is not typed
      const customerFavourite = getIsFavourite(state, lineNumber);
      const favourite = isDefined(customerFavourite) ? yesOrNo(customerFavourite) : undefined;

      availableAttributes = {
        ...availableAttributes,
        brand: brandName || brand,
        category:
          categories?.map(({ name: categoryName }: { name: string }) => categoryName) || undefined,
        favourite,
        id: lineNumber,
        name,
        offer: promotions ? concatenatePromotionValues(state, promotions) : '',
        position: productPosition,
        variant: productType,
        matchStrategy: isRecipePage
          ? // `productPosition` is provided when on a Recipe page.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            recipe?.products?.[productPosition! - 1]?.products?.[0]?.matchStrategy || null
          : undefined,
        sponsored: sponsored ? 'YES' : 'NO',
        ingredient_type: ingredientType,
        ingredient_swapped: isRecipePage ? 'NO' : undefined,
        sponsored_source: sponsoredSource,
      };
    }

    return {
      ...unavailableAttributes,
      ...availableAttributes,
    };
  });

  const { mealDeal, gtmEventCompleteMealDealBuilder } = getMealDealAnalytics(
    state,
    payload,
    method,
  );

  const { buildYourOffer = {}, gtmEventCompleteOfferBuilder } = getOfferBuilderAnalytics(
    state,
    payload,
    method,
  );

  const gtmEvent: ChangeCartGTMEvent = {
    _clear: true,
    event,
    ecommerce: {
      currencyCode,
      [method]: {
        products: productInfos,
        build_your_offer: buildYourOffer,
      },
    },
    meal_deal: mealDeal,
  };

  if (event === ADD_TO_TROLLEY_DESCRIPTION) {
    const product = payload[0]?.analytics;

    const aemPromotion = product?.clickContext?.aemPromotion;
    const personalisation = product?.clickContext?.personalisation;
    const sponsored = isSponsoredProduct(state, product?.lineNumber)
      ? { type: 'Sponsored Product', name: undefined }
      : undefined;

    gtmEvent.promotion = aemPromotion || sponsored || noPromo;
    gtmEvent.personalisation = personalisation || noPersonalisation;
    gtmEvent.order_id = orderId;
  }

  const events: ChangeCartGTMEvent[] = [gtmEvent];

  if (gtmEventCompleteMealDealBuilder) {
    events.push(gtmEventCompleteMealDealBuilder);
  } else if (gtmEventCompleteOfferBuilder) {
    events.push(gtmEventCompleteOfferBuilder);
  }

  return events;
};

export default changeCartTransform;
