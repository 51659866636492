import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import classNames from 'classnames';

import { Accordion } from '@johnlewispartnership/wtr-ingredients/ingredients/Accordion';
import BackToTop from 'components/BackToTop';
import FooterBadges from 'components/Footer/FooterBadges';
import FooterLinkList from 'components/Footer/FooterLinkList';
import SiteLinks from 'components/Footer/SiteLinks';
import SocialLinks from 'components/Footer/SocialLinks';
import AppLinks from 'components/Footer/AppLinks';
import { useExperiments } from 'components/Experiment/useExperiments';
import { isWebKit } from 'utils/is-webkit';
import { CONTENT_VISIBILITY_FOOTER } from 'constants/monetateExperiments';

import styles from './SiteFooter.scss';

const SiteFooter = memo(({ data }) => {
  const { getDecisionById } = useExperiments();

  const { variant, trackExperimentView } = getDecisionById(CONTENT_VISIBILITY_FOOTER.experiment, {
    manualTracking: true,
  });

  const isContentVisibilityVariant = variant === CONTENT_VISIBILITY_FOOTER.variant.lazyLoadFooter;
  const isInScopeForContentVisibilityExperiment = !isWebKit();
  const applyContentVisibility =
    isContentVisibilityVariant && isInScopeForContentVisibilityExperiment;

  useEffect(() => {
    if (isInScopeForContentVisibilityExperiment) {
      trackExperimentView();
    }
  }, [isInScopeForContentVisibilityExperiment, trackExperimentView]);

  if (!data) {
    return null;
  }

  return (
    <footer
      data-testid="footer"
      className={classNames(styles.footer, { [styles.contentVisibility]: applyContentVisibility })}
    >
      <Accordion>
        <h2 className="sr-only">Footer</h2>
        <div
          data-testid="small-layout"
          className={`visible-xs-block visible-sm-block visible-md-block ${styles.linksLists} no-print`}
        >
          {data.support && <FooterLinkList section={data.support} accordion />}
          {data.about && <FooterLinkList section={data.about} accordion />}
          {data.shops && <FooterLinkList section={data.shops} accordion />}
        </div>
        <div
          data-testid="large-layout"
          className={`container-fluid visible-lg-flex visible-xl-flex ${styles.linksLists} no-print`}
        >
          {data.support && <FooterLinkList section={data.support} />}
          {data.about && <FooterLinkList section={data.about} />}
          {data.shops && <FooterLinkList section={data.shops} />}
          <div>
            {data.social && <SocialLinks data={data.social} />}
            {data.apps && <AppLinks data={data.apps} />}
          </div>
        </div>
        {data.site && <SiteLinks className="no-print" data={data.site} />}
        {data.social && (
          <SocialLinks
            className="visible-xs-flex visible-sm-flex visible-md-flex no-print"
            data={data.social}
          />
        )}
        {data.apps && (
          <AppLinks
            className="visible-xs-flex visible-sm-flex visible-md-flex no-print"
            data={data.apps}
          />
        )}
        <FooterBadges data={data} />
        <BackToTop />
      </Accordion>
    </footer>
  );
});

SiteFooter.propTypes = {
  data: PropTypes.shape({
    about: PropTypes.shape({}).isRequired,
    apps: PropTypes.shape({}).isRequired,
    shops: PropTypes.shape({}).isRequired,
    site: PropTypes.shape({}).isRequired,
    social: PropTypes.shape({}).isRequired,
    support: PropTypes.shape({}).isRequired,
  }),
};

SiteFooter.defaultProps = {
  data: undefined,
};

SiteFooter.displayName = 'SiteFooter';

export default SiteFooter;
