import { apiCallback } from 'api';

const definition = {
  service: 'content',
  endpoint: 'content/experience-fragments/waitrosegroceriescms/${experienceFragmentKey}',
  verbs: ['put'],
  defaultVersion: 2,
  query: {
    clientType: 'WEB_APP',
  },
};

type CachedDefinition = typeof definition & { memoryCacheKey: string; memoryCacheMaxAge: number };

const cachedDefinitionsStore: Record<string, CachedDefinition> = {};

const getApiCallbackWithCachedDefinition = (experienceFragmentKey: string) => {
  if (!cachedDefinitionsStore[experienceFragmentKey]) {
    cachedDefinitionsStore[experienceFragmentKey] = {
      ...definition,
      endpoint: `content/experience-fragments/waitrosegroceriescms/${experienceFragmentKey}`,
      memoryCacheKey: `experienceFragment[${experienceFragmentKey}]`,
      memoryCacheMaxAge: 10000,
    };
  }

  return apiCallback(cachedDefinitionsStore[experienceFragmentKey], 'put');
};

export default {
  put: apiCallback(definition, 'put'),
  cached: getApiCallbackWithCachedDefinition,
};
