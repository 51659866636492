import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useWtrSelector, useWtrDispatch } from 'redux/hooks';
import { trackModalClosed, trackModalOpened } from 'analytics/modal-tracking';
import { navigateToMultiSearch } from 'redux/modules/multi-search/actions/navigate-to-multi-search';
import { useJotter } from 'redux/modules/multi-search/actions/use-jotter';
import { ModalCloseOptions } from 'constants/modalCloseOptions';
import isEqual from 'lodash/isEqual';

import { getJotterValues } from 'redux/modules/multi-search/selectors';
import { getCustomerId } from 'redux/modules/sessions/selectors';
import { clearJotter } from 'redux/modules/multi-search/actions/clear-jotter';

import { setJotter } from 'redux/modules/multi-search/actions/set-jotter';
import { modalSeverityValues } from 'constants/modals';

import { removeScrollStateFromSessionStorage } from 'utils/scroll-session-storage';
import cleanValues from '../Modal/clean';

type UseModalProps = {
  onToggleModal: (x: boolean) => unknown;
  onSearch: () => unknown;
};

export const useModal = ({ onToggleModal, onSearch }: UseModalProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const customerId = useWtrSelector(getCustomerId, isEqual);
  const values = useWtrSelector(getJotterValues, isEqual);
  const [text, setText] = useState<string>();
  const { search, pathname } = useLocation();

  useEffect(() => {
    if (!text) setText(values.join('\n'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const dispatch = useWtrDispatch();

  const toggleModal = useCallback(
    (event?: { preventDefault: () => void; stopPropagation: () => void }) => {
      const multiSearch = {
        id: 'multiSearchModal',
        severity: modalSeverityValues.INFO,
        title: 'Multi-search',
        customerId,
      };

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (onToggleModal) {
        onToggleModal(!modalOpen);
      }

      const trackModal = modalOpen ? trackModalOpened : trackModalClosed;
      trackModal(multiSearch);

      setModalOpen(!modalOpen);
    },
    [customerId, modalOpen, onToggleModal],
  );

  const onTextChange = useCallback(
    (event: { target: { value: React.SetStateAction<string | undefined> } }) =>
      setText(event.target.value),
    [],
  );

  const goToMultiSearchPage = useCallback(() => {
    const cleanText = cleanValues(text);
    const currentValue = search?.split('=')[1];
    dispatch(setJotter(cleanText));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    dispatch(useJotter());
    if (onSearch) onSearch();
    if (!pathname?.includes('multi-search') || currentValue !== cleanText[0]) {
      dispatch(navigateToMultiSearch(cleanText[0]));
    }
  }, [dispatch, onSearch, text, search, pathname]);

  const onClose = useCallback(
    (type: string) => {
      switch (type) {
        case ModalCloseOptions.CLEAR_LIST:
          setText('');
          dispatch(clearJotter());
          return;
        case ModalCloseOptions.SAVE:
          removeScrollStateFromSessionStorage();
          goToMultiSearchPage();
          break;
        default:
          dispatch(setJotter(cleanValues(text)));
      }

      toggleModal();
    },
    [text, dispatch, goToMultiSearchPage, toggleModal],
  );

  return {
    toggleModal,
    text,
    onTextChange,
    onClose,
    modalOpen,
  };
};
