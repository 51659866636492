import React, { PropsWithChildren, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS, BreakpointWidth } from 'constants/grid';
import styles from './SSRMediaQuery.scss';

interface SSRMediaQueryProps {
  className?: string;
  maxWidth?: BreakpointWidth;
  minWidth?: BreakpointWidth;
}

interface DisplayValueProps extends Pick<SSRMediaQueryProps, 'maxWidth' | 'minWidth'> {
  breakpoint: BreakpointWidth;
}

const getDisplayValue = ({ breakpoint, maxWidth, minWidth }: DisplayValueProps) => {
  if (typeof maxWidth === 'undefined' && typeof minWidth === 'undefined') {
    return undefined;
  }

  if (typeof minWidth === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return breakpoint >= maxWidth! ? 'none' : undefined;
  }

  if (typeof maxWidth === 'undefined') {
    return breakpoint < minWidth ? 'none' : undefined;
  }

  return breakpoint < minWidth || breakpoint >= maxWidth ? 'none' : undefined;
};

const useIsFirstRender = (): boolean => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return false;
};

/**
 * This component serves to allow viewport specific components be server-side
 * rendered without causing a CLS.
 *
 * ---
 *
 * 🚨 Before using this component you should first consider:
 *
 * - Can you use CSS media queries in your component directly to handle
 * viewport specific requirements? If so, use them over this component.
 *
 * Only if the layout of the page is sufficiently different between
 * viewports (such that CSS grid etc. would cause a11y issues) should we then
 * consider:
 *
 * - If the component requires user interaction to load, is lazy loaded, or
 * "deferred" in some other way, then using the useClientSideMediaQuery hook is
 * fine.
 * - If the component is part of the critical render path then it should use
 * this wrapper SSRMediaQuery component to ensure no CLS.
 *
 * ---
 *
 * **Description**
 *
 * It first assumes we are always in a server-side / pre-hydration world until
 * it is told otherwise (using `__SERVER__`) to ensure that when the page
 * first loads all possible markup is rendered to cater to any viewport. This
 * is necessary as we currently have no way to hint to our servers what
 * viewport the customer is using, so we must cater to all scenarios.
 *
 * As we want to ensure that there is no flash of unstyled content (FOUC) when
 * we server-side render, this component wraps the server rendered content in a
 * div which is assigned styles to ensure that it is hidden from display (and
 * assistive technology) through CSS media queries.
 *
 * Once we know that we are definitely client-side we determine if this
 * container is appropriate for the viewport, and if not we prune its contents
 * prior to React's hydration. This allows us to remove the contents without
 * the need for a `useEffect()` and `useState()` combo to work out if we're
 * client-side.
 *
 * **Note:** the wrapper div used to control the display / hiding of the
 * content at different viewports inherits the CSS `display` of it's parent by
 * default. Sometimes this isn't sufficient to ensure correct rendering and you
 * can get a CLS. E.g. if you are using flex and the intermediary div means the
 * children aren't flex-wrapped. To avoid this you can pass a `className` prop
 * that will be placed on the wrapper `div`. When passing a custom class, avoid
 * setting a `display` value otherwise it break the display logic.
 */
export const SSRMediaQuery: React.FC<PropsWithChildren<SSRMediaQueryProps>> = ({
  children,
  className,
  maxWidth,
  minWidth,
}) => {
  const queryMaxWidth = maxWidth ? maxWidth - 1 : maxWidth;
  const isMatch = useMediaQuery({ maxWidth: queryMaxWidth, minWidth });
  const isFirstRender = useIsFirstRender();
  // Note: this is one of the few, if only, places we should be detecting
  // whether we are client or server-side during a React component render.
  const isClient = !__SERVER__;
  const id = React.useId();
  const containerId = `smq-${id}`;

  /**
   * If we're on the client, this is the first render, and we are not going
   * to render the children, we need to cleanup the the server-rendered HTML
   * to avoid a hydration mismatch.
   *
   * We do this by grabbing the already-existing element(s) directly from the
   * DOM using the unique id and clearing its contents. This solution
   * follows one of the suggestions from Dan Abromov here:
   *
   * https://github.com/facebook/react/issues/23381#issuecomment-1096899474
   *
   * This will not have a negative impact on client-only rendering because
   * either 1) isFirstRender will be false OR 2) the element won't exist yet
   * so there will be nothing to clean up. It will only apply on SSR'd HTML
   * on initial hydration.
   */
  if (isClient && isFirstRender && !isMatch) {
    const ssrMediaQueryContainerElements = document.getElementsByClassName(containerId);

    Array.from(ssrMediaQueryContainerElements).forEach(ssrMediaQueryContainerElement => {
      // eslint-disable-next-line no-param-reassign
      ssrMediaQueryContainerElement.innerHTML = '';
    });
  }

  const style = useMemo(
    () =>
      ({
        '--xs': getDisplayValue({ breakpoint: BREAKPOINTS.xs, maxWidth, minWidth }),
        '--sm': getDisplayValue({ breakpoint: BREAKPOINTS.sm, maxWidth, minWidth }),
        '--md': getDisplayValue({ breakpoint: BREAKPOINTS.md, maxWidth, minWidth }),
        '--lg': getDisplayValue({ breakpoint: BREAKPOINTS.lg, maxWidth, minWidth }),
        '--xl': getDisplayValue({ breakpoint: BREAKPOINTS.xl, maxWidth, minWidth }),
      }) as React.CSSProperties,
    [maxWidth, minWidth],
  );

  return (
    <div
      className={classNames(styles.smq, containerId, className)}
      style={style}
      suppressHydrationWarning
    >
      {isMatch || !isClient ? children : null}
    </div>
  );
};
