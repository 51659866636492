import { axios } from 'utils/request';
import { MONETATE_DECISIONS_URL } from 'constants/monetate';
import { log } from 'utils/error-logging';
import includeExternal from 'utils/include-external';
import { dataLayer } from './data-layer';

const SGTM_DOMAIN = 'https://wtr-digital-analytics.ew.r.appspot.com';

export const EventType = {
  IMPRESSION: 'impression',
  CLICK: 'click',
} as const;

type AdTechEvent = {
  /**
   * Urls to be invoked on SGTM
   */
  trackingUrls?: string[];
  /**
   * The suppliers' product sponsorshipId
   */
  sponsorshipId?: string;
  /**
   * The type of tracking event invoked
   */
  eventType: (typeof EventType)[keyof typeof EventType];
};

export const sendAdTechEvent = async ({ eventType, sponsorshipId, trackingUrls }: AdTechEvent) => {
  if (includeExternal() && (sponsorshipId || trackingUrls?.length)) {
    dataLayer.push({
      event: eventType,
      sponsorshipId,
      trackingUrls,
    });
  }
};

export const citrusAdTransactionCompleted = async (transactionCompleteData: unknown) => {
  if (includeExternal()) {
    try {
      await axios.post(`${SGTM_DOMAIN}/citrusad/order`, transactionCompleteData, {
        headers: {
          'content-type': 'application/json',
        },
      });
    } catch (err) {
      log(err, { logger: 'gtmServerFunctions', section: 'citrusAdTransactionCompleted' });
    }
  }
};

export const MONETATE_WAITROSE_CHANNEL = 'a-18da6672/p/waitrose.com';

const postMonetateEvents = async (monetateId: unknown, events: unknown) => {
  try {
    await axios.post(MONETATE_DECISIONS_URL, {
      channel: MONETATE_WAITROSE_CHANNEL,
      monetateId,
      events,
    });
  } catch (err) {
    log(err, { logger: 'postMonetateEvents', section: 'monetate' });
  }
};

interface AdMetadata {
  monetateId?: unknown;
  recToken?: unknown;
}

export const metadataAdImpressions = (metadata: AdMetadata) => {
  if (includeExternal() && metadata.monetateId && metadata.recToken) {
    return postMonetateEvents(metadata.monetateId, [
      { eventType: 'monetate:record:RecImpressions', recImpressions: [metadata.recToken] },
    ]);
  }

  return Promise.resolve();
};

export const metadataAdClick = (metadata: AdMetadata) => {
  if (includeExternal() && metadata.monetateId && metadata.recToken) {
    return postMonetateEvents(metadata.monetateId, [
      { eventType: 'monetate:record:RecClicks', recClicks: [metadata.recToken] },
    ]);
  }

  return Promise.resolve();
};
