import { LOCATION_CHANGE } from 'redux-first-history';
import { deepFreeze } from 'utils/deepFreeze';

import {
  SHOPPING_LISTS_CREATE_FULFILLED,
  SHOPPING_LISTS_CREATE_PENDING,
  SHOPPING_LISTS_CREATE_REJECTED,
  SHOPPING_LISTS_FULFILLED,
  SHOPPING_LISTS_HIDE_MODAL,
  SHOPPING_LISTS_SHOW_MODAL,
} from 'redux/modules/shopping-lists/actions/types';

const initialState = deepFreeze({
  createListActive: false,
  creating: false,
  fetched: false,
  modalLineNumber: null,
  modalVisible: false,
});

export default function shoppingLists(state = initialState, action = {}) {
  switch (action.type) {
    case SHOPPING_LISTS_CREATE_PENDING:
      return {
        ...state,
        creating: true,
      };
    case SHOPPING_LISTS_CREATE_FULFILLED:
    case SHOPPING_LISTS_CREATE_REJECTED:
      return {
        ...state,
        creating: false,
      };
    case SHOPPING_LISTS_FULFILLED:
      return {
        ...state,
        fetched: true,
      };
    case LOCATION_CHANGE:
    case SHOPPING_LISTS_HIDE_MODAL:
      return {
        ...state,
        createListActive: false,
        modalVisible: false,
        modalLineNumber: null,
      };
    case SHOPPING_LISTS_SHOW_MODAL:
      return {
        ...state,
        modalVisible: true,
        modalLineNumber: action.payload,
      };
    default:
      return state;
  }
}
